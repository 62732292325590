import {
  BlobServiceClient,
  BlobUploadCommonResponse,
} from '@azure/storage-blob';

interface FaceCoordinates {
  readonly xMin: number;
  readonly xMax: number;
  readonly yMin: number;
  readonly yMax: number;
}

export function isFaceCloseToBorder(
  faceCoordinates: FaceCoordinates,
  imgWidth: number,
  imgHeight: number,
) {
  const proximityThreshold = 0.32;
  const { xMin, xMax, yMin, yMax } = faceCoordinates;
  const minXDistance = Math.min(xMin, imgWidth - xMax);
  const minYDistanceUpper = yMin;
  const minYDistanceLower = imgHeight - yMax;
  let proximityX, proximityYUpper, proximityYLower;

  switch (`${imgWidth}x${imgHeight}`) {
    case '1280x720':
      proximityX = proximityThreshold * imgWidth;
      proximityYUpper = 0.6 * proximityThreshold * imgHeight;
      proximityYLower = 0.4 * proximityThreshold * imgHeight;
      break;
    case '720x1280':
      proximityX = 0.5 * proximityThreshold * imgWidth;
      proximityYUpper = proximityThreshold * imgHeight;
      proximityYLower = 0.8 * proximityThreshold * imgHeight;
      break;
    case '1920x1080':
      proximityX = proximityThreshold * imgWidth;
      proximityYUpper = 0.75 * proximityThreshold * imgHeight;
      proximityYLower = 0.65 * proximityThreshold * imgHeight;
      break;
    case '1080x1920':
      proximityX = 0.5 * proximityThreshold * imgWidth;
      proximityYUpper = proximityThreshold * imgHeight;
      proximityYLower = 0.8 * proximityThreshold * imgHeight;
      break;
    default:
      proximityX = 0.5 * proximityThreshold * imgWidth;
      proximityYUpper = 0.5 * proximityThreshold * imgHeight;
      proximityYLower = 0.75 * proximityThreshold * imgHeight;
  }

  return (
    minXDistance < proximityX ||
    minYDistanceUpper < proximityYUpper ||
    minYDistanceLower < proximityYLower
  );
}

export function calculateDistance(objectWidthInImage: number) {
  const focalLength = 500;
  const realObjectWidth = 0.5;

  return (focalLength * realObjectWidth) / objectWidthInImage;
}

export interface UploadOptions {
  account: string;
  sasToken: string;
  containerName: string;
  blobName?: string;
}

export async function uploadImageToAzure(
  imageBlob: Blob,
  options: UploadOptions,
): Promise<BlobUploadCommonResponse> {
  const {
    account,
    sasToken,
    containerName,
    blobName = `image-${Date.now()}.jpg`,
  } = options;

  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net?${sasToken}`,
  );
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);

  return blockBlobClient.upload(imageBlob, imageBlob.size, {
    blobHTTPHeaders: {
      blobContentType: imageBlob.type,
    },
  });
}
