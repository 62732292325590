import React from 'react';

import { Grid, Typography } from '@mui/material';

import { useAppSelector } from 'hooks/useAppSelector';

import { MobileContainer, StyledButton } from './styles';
import { ComponentProps } from './types';

export const TipsMobileComponent: React.FC<ComponentProps> = ({
  handleContinue,
  animationClass,
  setShowInstructions,
  livenessError,
  width = '89vw',
}) => {
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const isMerchantProps = useAppSelector((state) => state.app.isMerchantProps);
  const isSessionTimeOut = useAppSelector(
    (state) => state.app.isSessionTimeOut,
  );

  return (
    <MobileContainer
      width={width}
      className={animationClass}
      merchantConfiguration={merchantConfiguration}
      isMerchantProps={isMerchantProps}
      isSessionTimeOut={isSessionTimeOut}
    >
      <Grid container rowSpacing={2}>
        <Grid
          container
          justifyContent="center"
          marginTop={1}
          overflow="hidden"
          alignItems="center"
          gap={1}
          direction="row"
        >
          <Grid xs={1} item>
            <img
              src="../../../assets/wink_white_logo.png"
              width="15"
              height="auto"
            />
          </Grid>
          <Grid xs={8} item>
            <Typography
              variant="body1"
              fontSize="1.3rem"
              fontWeight={600}
              color="white"
              textAlign="center"
              lineHeight="20px"
            >
              {livenessError
                ? `Let's try that again`
                : `Get ready for your video verification`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container textAlign="center" style={{ paddingTop: 0 }}>
          <Grid item xs={12}>
            <img
              src="../../../assets/wink-phototips-good.png"
              width="150"
              height="auto"
            ></img>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              fontSize="1rem"
              color="white"
              textAlign="center"
              lineHeight="20px"
              component="span"
            >
              Get securely verified with a{' '}
              <Typography
                variant="body1"
                fontSize="1.1rem"
                color="#21E2C0"
                textAlign="center"
                fontWeight={700}
                display="inline-block"
                lineHeight="20px"
              >
                well-lit
              </Typography>{' '}
              smile,{' '}
              <Typography
                variant="body1"
                fontSize="1.1rem"
                color="#FED005"
                textAlign="center"
                fontWeight={700}
                display="inline-block"
                lineHeight="20px"
              >
                facing forward
              </Typography>{' '}
              and{' '}
              <Typography
                variant="body1"
                fontSize="1.1rem"
                color="#21E2C0"
                textAlign="center"
                fontWeight={700}
                display="inline-block"
                lineHeight="20px"
              >
                centered
              </Typography>{' '}
              with your{' '}
              <Typography
                variant="body1"
                fontSize="1.1rem"
                color="#FED005"
                textAlign="center"
                fontWeight={700}
                display="inline-block"
                lineHeight="20px"
              >
                ears visible.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} columnSpacing={2}>
          <Grid item xs={7} textAlign="right">
            <Typography
              fontSize="1.1rem"
              fontWeight={600}
              color="white"
              marginTop={3}
              lineHeight="20px"
              component="span"
            >
              Avoid Poor Lighting
              <Typography
                fontSize="1rem"
                fontWeight={400}
                color="white"
                lineHeight="20px"
                paddingTop="10px"
              >
                Back-lit and low-light settings are less ideal
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            style={{ position: 'relative' }}
            right={{ sm: -15 }}
          >
            <img
              src="../../../assets/cloud.png"
              height={120}
              style={{
                position: 'relative',
              }}
            />
            <img
              src="../../../assets/wink-phototips-dark.png"
              width={177}
              height={188}
              style={{ position: 'absolute', top: -10, left: 0 }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Grid container item xs={5} style={{ position: 'relative' }}>
            <img
              src="../../../assets/sun.png"
              width={163}
              height={120}
              style={{
                position: 'relative',
                left: -48,
              }}
            />
            <img
              src="../../../assets/wink-phototips_covered.png"
              width={177}
              height={188}
              style={{ position: 'absolute', top: -14, left: -40 }}
            />
          </Grid>
          <Grid item xs={7} textAlign="center">
            <Typography
              fontSize="1.1rem"
              fontWeight={600}
              color="white"
              marginTop={3}
              lineHeight="20px"
              component="span"
            >
              Remove Face Coverings
              <Typography
                fontSize="1rem"
                fontWeight={400}
                color="white"
                lineHeight="20px"
                paddingTop="10px"
              >
                Take off sunglasses, masks, and move any hair covering your face
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <StyledButton
            onClick={(e: any) => {
              if (setShowInstructions) {
                setShowInstructions(false);
                setTimeout(() => {
                  // wait until the life tips view disappears.
                  handleContinue && handleContinue(e);
                }, 600);
              } else {
                handleContinue && handleContinue(e);
              }
            }}
            sx={{
              backgroundColor: `${merchantConfiguration.backgroundColor} !important`,
              color: `${merchantConfiguration.color} !important`,
            }}
          >
            <Typography textTransform="none" fontWeight={600}>
              {livenessError ? `Try Again` : `Let's Do This`}
            </Typography>
          </StyledButton>
        </Grid>
      </Grid>
    </MobileContainer>
  );
};
