import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ErrorContainer } from 'pages/error/ErrorContainer';

import AppContextProvider from 'context/AppContext';
import MediaContextProvider from 'context/MediaContext';

import { GlobalLoader } from 'components/loader/GlobalLoader';
import { Permissions } from 'components/Permissions';

import 'css/general.css';

import './App.css';
import { AppRoutes } from './AppRoutes';
import { useApp } from './hooks/useApp';
import { theme } from './Theme';

export const App: React.FC = () => {
  const { isLandscape, isLoading, handleError } = useApp();

  return (
    <Router>
      <ErrorBoundary FallbackComponent={ErrorContainer} onError={handleError}>
        <div className={isLandscape ? 'app--landscape-mode' : ''}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
              <GlobalLoader isLoading={isLoading} />
              <AppContextProvider>
                <Permissions />
                <MediaContextProvider>
                  <AppRoutes />
                </MediaContextProvider>
              </AppContextProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </div>
      </ErrorBoundary>
    </Router>
  );
};
