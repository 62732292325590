import { useState, useCallback } from 'react';

import {
  useSpeechToText,
  SpeechRecognitionResult,
  SpeechToTextLang,
} from 'wink-lib';

import configData from 'config/config.json';

const subscriptionKey: string =
  configData['MICROSOFT-COGNITIVE-SERVICES-SPEECH-KEY'];

interface SpeechProps {
  speechRecognitionResult: SpeechRecognitionResult;
  speechToText(listen: boolean): void;
  addAudioElement(blob: Blob): void;
  continuousListening: boolean;
  handleContinuousListeningChange(status: boolean): void;
  language: string;
}

export const useSpeech = (callback?: () => void): SpeechProps => {
  const [continuousListening, setContinuousListening] = useState<boolean>(true);
  const [language] = useState<SpeechToTextLang>('en-US');

  const polyfillData = {
    region: 'centralus',
    subscriptionKey,
  };

  const isAndroid = /(android)/i.test(navigator.userAgent);

  const commands = [
    {
      command: [
        'hello wink',
        'hello win',
        'hello wing',
        'hello wayne',
        'hello when',
        'halloween',
        'Hello week',
      ],
      callback: () => callback?.(),
      matchInterim: false,
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: isAndroid ? 0.7 : 0.8,
    },
  ];

  const { speechRecognitionResult, speechToText, addAudioElement } =
    useSpeechToText({
      language,
      continuousListening,
      polyfillData,
      commands,
    });

  const handleContinuousListeningChange = useCallback((status: boolean) => {
    setContinuousListening(status);
  }, []);

  return {
    speechToText,
    addAudioElement,
    speechRecognitionResult,
    continuousListening,
    handleContinuousListeningChange,
    language,
  };
};
