import { apm } from '@elastic/apm-rum';

import { setAxiosError, setIsSessionTimeOut } from 'app/slices/appSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';

type DispatchType = ReturnType<typeof useAppDispatch>;

const handleErrorStatus = (error: any, dispatch: DispatchType): void => {
  apm.captureError(error);
  // for timeout error
  if (error.code === 'ECONNABORTED') {
    dispatch(
      setAxiosError(
        'Looks like the server is taking too long to respond, please try again in sometime.',
      ),
    );
  } else {
    switch (error?.response?.status) {
      case 400:
        dispatch(
          setAxiosError(
            'Your request cannot be processed. Please check your input and try again.',
          ),
        );
        break;
      case 404:
        dispatch(
          setAxiosError(
            'The resource you are looking for was not found. Please try again.',
          ),
        );
        break;
      case 500:
        dispatch(
          setAxiosError(
            'Your request cannot be processed. Please try again later.',
          ),
        );
        break;
      case 401:
        // only when session time out
        if (error?.response?.data?.errorType === 'InvalidSession') {
          console.debug('Session expired');
          dispatch(setIsSessionTimeOut(true));
        }
        break;
      default:
        dispatch(setAxiosError('Some error occurred, please try again.'));
        break;
    }
  }
};

export { handleErrorStatus };
