import React, { useEffect, useState } from 'react';

import { isMobile } from 'wink-lib';

import { TipsDesktopComponent } from './TipsDesktopComponent';
import { TipsMobileComponent } from './TipsMobileComponent';
import { ComponentProps } from './types';

export const LivenessTipsContainer: React.FC<ComponentProps> = ({
  handleContinue,
  showInstructions,
  setShowInstructions,
  livenessError,
  width,
}) => {
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    if (isMobile) {
      if (showInstructions) {
        setAnimationClass('entering');
        setTimeout(() => setAnimationClass('entered'), 300);
      } else if (showInstructions === false) {
        setAnimationClass('exiting');
        setTimeout(() => setAnimationClass('exited'), 300);
      }
    }
  }, [showInstructions]);

  return (
    <>
      {isMobile ? (
        <TipsMobileComponent
          handleContinue={handleContinue}
          animationClass={animationClass}
          setShowInstructions={setShowInstructions}
          livenessError={livenessError}
          width={width}
        />
      ) : (
        <TipsDesktopComponent width={width} />
      )}
    </>
  );
};
