import React, { useEffect } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';

import { ErrorContainer } from 'pages/error/ErrorContainer';
import { FraudScore } from 'pages/fraud/FraudScore';
import { MerchantContainer } from 'pages/merchant/MerchantContainer';
import { SecurityQuestions } from 'pages/security-questions/SecurityQuestions';
import { WrapperContainer } from 'pages/wrapper';

import {
  setAxiosError,
  setIsMerchantProps,
  setMerchantConfiguration,
} from 'app/slices/appSlice';

import { useLocalStorage } from 'context/AppContext';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import { getMerchantConfiguration } from 'api/merchant-configuration';

import PrivateRoute from 'components/PrivateRoute';
import { SessionTimeOut } from 'components/sessionTimeOut/SessionTimeOut';

import { defaultTheme } from 'const/appDefaultTheme';

export const AppRoutes: React.FC = () => {
  const axiosError = useAppSelector((state) => state.app.axiosError);
  const isSessionTimeOut = useAppSelector(
    (state) => state?.app?.isSessionTimeOut,
  );

  const merchantConfig = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const sessionToken = useAppSelector((state) => state.app.sessionToken);
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('client_id') || '';
  const localStorage = useLocalStorage();
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (
        localStorage &&
        clientId !== '' &&
        merchantConfig.logoFileUrl === ''
      ) {
        const response = await getMerchantConfiguration({
          clientId,
          localStorage,
          dispatch,
          sessionToken,
        });
        response?.backgroundColor && dispatch(setIsMerchantProps(true));
        dispatch(
          setMerchantConfiguration({
            font: response?.font ?? defaultTheme.font,
            backgroundColor:
              response?.backgroundColor ?? defaultTheme.backgroundColor,
            color: response?.color ?? defaultTheme.color,
            buttonColor: response?.buttonColor ?? defaultTheme.buttonColor,
            buttonTextColor:
              response?.buttonTextColor ?? defaultTheme.buttonTextColor,
            logoFileUrl: response?.logoFileUrl ?? '',
          }),
        );
      }
    })();
  }, [localStorage]);

  const resetError = () => {
    dispatch(setAxiosError(''));
  };

  if (axiosError) {
    return (
      <ErrorContainer
        message={axiosError}
        error={new Error(axiosError)}
        resetErrorBoundary={resetError}
      />
    );
  }
  if (isSessionTimeOut) {
    return <SessionTimeOut />;
  }

  return (
    <Routes>
      <Route path="/*" element={<WrapperContainer />} />
      <Route
        path="/successPage"
        element={
          <PrivateRoute>
            <MerchantContainer />
          </PrivateRoute>
        }
      />
      <Route
        path="/service-unavailable"
        element={
          <PrivateRoute>
            <FraudScore />
          </PrivateRoute>
        }
      />
      <Route
        path="/security-questions"
        element={
          <PrivateRoute>
            <SecurityQuestions />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
