import React from 'react';

import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const GlobalLoader: React.FC<{ isLoading?: boolean }> = ({
  isLoading = false,
}) => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={isLoading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);
