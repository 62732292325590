/* eslint-disable no-throw-literal */
import { apm } from '@elastic/apm-rum';

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
} from 'axios';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { getToken } from 'const/localStorage';

import config from 'config/config.json';

import { handleErrorStatus } from './handleErrorStatus';

type DispatchType = ReturnType<typeof useAppDispatch>;

type HeadersConfig = {
  Handle404Locally?: string;
  Handle401Locally?: string;
  Handle400Locally?: string;
};

const shouldHandleLocally = (
  error: AxiosError,
  statusCode: number,
  headerKey: keyof HeadersConfig,
): boolean => {
  const handleHeader = error?.config?.headers?.[headerKey];
  return handleHeader === 'true' && error.response?.status === statusCode;
};

const axiosInstance = (
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken?: string,
): AxiosInstance => {
  const instance = axios.create({
    baseURL: `https://${config.WS_BACKEND_URL}/api/`,
    timeout: 20000,
  });

  const handleError = (error: AxiosError): Promise<never> => {
    apm.captureError(error);
    handleErrorStatus(error, dispatch);
    return Promise.reject(error);
  };

  instance.interceptors.request.use(
    (config: AxiosRequestConfig): AxiosRequestConfig => {
      if (config.url && config.url.includes('/getDocumentData')) {
        config.timeout = 40000; // Morte timeout for this endpoint
      }
      const token = getToken(localStorage);
      if (token) {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
            SessionId: sessionToken ?? '',
          },
        };
      }
      return config;
    },
    handleError,
  );

  instance.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    (error: AxiosError) => {
      if (
        shouldHandleLocally(error, 404, 'Handle404Locally') ||
        shouldHandleLocally(error, 401, 'Handle401Locally') ||
        shouldHandleLocally(error, 400, 'Handle400Locally')
      ) {
        // If it's a Yellow Case or a 404 and has the Handle404Locally header, just reject without handling
        return Promise.reject(error);
      }
      return handleError(error);
    },
  );
  return instance;
};

export default axiosInstance;
