import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { useLocalStorage } from 'context/AppContext';

import { useAppSelector } from 'hooks/useAppSelector';

import { restartSynthesizer } from 'api/speech';

import configData from 'config/config.json';

import { IosSwitch } from '../IosSwitch';

const styles = {
  winkLogo: {
    height: 'auto',
    maxWidth: '57px',
    margin: 'auto',
  },
  greenCircle: {
    backgroundColor: '#1AA7B0',
  },
  redCircle: {
    backgroundColor: '#eb3838',
  },
  circle: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5px',
  },
  logoMicrophoneContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
};

interface Props {
  headerText?: string;
}

export const WinkToggle: React.FC<Props> = ({ headerText }) => {
  const [winkSwitch, setWinkSwitch] = useState<boolean | undefined>(undefined);
  const [winkStatus, setWinkStatus] = useState<string>('Online');
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  // LocalStorage
  const localStorage = useLocalStorage();

  useEffect(() => {
    if (localStorage) {
      const botVoiceEnabledItem = localStorage.getItem('botVoiceEnabled');
      const botIsEnabled = botVoiceEnabledItem
        ? JSON.parse(botVoiceEnabledItem)
        : configData?.BOT_VOICE_ENABLED || false;
      setWinkSwitch(botIsEnabled);
    }
  }, [localStorage]);

  useEffect(() => {
    if (winkSwitch !== undefined) {
      localStorage?.setItem('botVoiceEnabled', winkSwitch.toString());
      if (winkSwitch === true) setWinkStatus('Online');
      else {
        setWinkStatus('Offline');
        restartSynthesizer();
      }
    }
  }, [winkSwitch]);
  return (
    <>
      <Box sx={styles.logoMicrophoneContainer}>
        <img
          style={styles.winkLogo}
          src={
            merchantConfiguration?.logoFileUrl !== ''
              ? merchantConfiguration?.logoFileUrl
              : 'assets/WinkAppLogoHD.png'
          }
          alt="Wink Logo"
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" mb={1}>
            <Box
              style={
                winkSwitch
                  ? { ...styles.greenCircle, ...styles.circle }
                  : { ...styles.redCircle, ...styles.circle }
              }
            />

            <Typography
              variant="body1"
              fontFamily={merchantConfiguration?.font}
            >
              {winkStatus}
            </Typography>
          </Box>
          <IosSwitch
            checked={winkSwitch !== undefined ? winkSwitch : true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setWinkSwitch(e.target.checked)
            }
            onClick={(e) => {
              e.stopPropagation();
            }}
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            merchantConfiguration={merchantConfiguration}
          />
        </Box>
      </Box>
      <Typography mx={1} fontSize={16}>
        {headerText}
      </Typography>
    </>
  );
};
