import React, { useContext } from 'react';

import { setMicActive, setCameraActive } from 'app/slices/appSlice';

import { useLocalStorage } from 'context/AppContext';
import { MediaContext } from 'context/MediaContext';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { getGrantedPermissions } from 'const/localStorage';

export const useMediaDevices = () => {
  const localStorage = useLocalStorage();
  const dispatch = useAppDispatch();
  const { deactivateMedia, activateMedia } = useContext(MediaContext);

  const toggleOffCamera = async (e?: React.MouseEvent) => {
    if (e) e.stopPropagation();
    dispatch(setCameraActive(false));
    await deactivateMedia('video');
  };

  const toggleOnCamera = async (e?: React.MouseEvent) => {
    if (e) e.stopPropagation();
    const permissionsGranted = getGrantedPermissions(localStorage);
    if (!permissionsGranted || !JSON.parse(permissionsGranted)) return;
    dispatch(setCameraActive(true));
    await activateMedia('video');
  };

  const toggleOffMic = async (e?: React.MouseEvent) => {
    if (e) e.stopPropagation();
    dispatch(setMicActive(false));
    await deactivateMedia('audio');
  };

  const toggleOnMic = async (e?: React.MouseEvent) => {
    if (e) e.stopPropagation();
    const permissionsGranted = getGrantedPermissions(localStorage);
    if (!permissionsGranted || !JSON.parse(permissionsGranted)) return;
    dispatch(setMicActive(true));
    await activateMedia('audio');
  };

  const toggleOffCameraAndMic = async () => {
    dispatch(setCameraActive(false));
    dispatch(setMicActive(false));
    await deactivateMedia();
  };

  return {
    toggleOffCamera,
    toggleOnCamera,
    toggleOffMic,
    toggleOnMic,
    toggleOffCameraAndMic,
  };
};
