import React, { useState, useEffect } from 'react';

import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DesktopDatePicker } from '@mui/x-date-pickers';

interface Props {
  disabled: boolean;
  title?: string;
  birthDate: Date | null;
  handleInput: (date: Date | null) => void;
}

export const DateQuestion: React.FC<Props> = ({
  disabled,
  title = 'What is your date of birth?',
  birthDate,
  handleInput,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [lastFocusedElement, setLastFocusedElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    // TODO correct typing
    const handleClickOutside = (event: any) => {
      const datePickerPopper = document.querySelector('.MuiPickersPopper-root');
      if (datePickerPopper && !datePickerPopper.contains(event.target)) {
        setLastFocusedElement(event.target);
        setOpenDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // When DatePicker is closed, we return focus to the last focused item
    if (!openDatePicker && lastFocusedElement) {
      setTimeout(() => {
        lastFocusedElement.focus();
      }, 50);
      setLastFocusedElement(null);
    }
  }, [openDatePicker]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      mb={3}
      pl={1}
      width="100%"
      boxSizing="border-box"
    >
      <Typography alignSelf="flex-start" sx={{ marginBottom: '5px' }}>
        {title}
      </Typography>
      <Box onClick={(e) => e.stopPropagation()} mt="5px" width="100%">
        <DesktopDatePicker
          disabled={disabled}
          value={birthDate}
          open={openDatePicker}
          onOpen={() => {
            setOpenDatePicker(true);
          }}
          onClose={() => {
            setOpenDatePicker(false);
          }}
          onChange={handleInput}
          disableFuture
          renderInput={(params) => (
            <TextField
              name="birthDate"
              disabled={disabled}
              fullWidth
              size="small"
              required
              autoComplete="off"
              error={false}
              {...params}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};
