import React, { useEffect, useRef, useState } from 'react';

import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import { setIsDrawerOpen, setIsSubDrawerOpen } from 'app/slices/drawerSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import { isMobile } from 'const/breakpoints';

const drawerWidth = isMobile ? '100%' : '420px';
const subDrawerHeight = !isMobile && window.innerHeight < 600 ? 400 : '';
const styles: Record<string, React.CSSProperties> = {
  subDrawerContainerOpen: {
    marginLeft: 1,
    overflowY: 'scroll',
    right: 30,
    borderRadius: '0px 0px 20px 20px',
    border: '1px solid rgba(255, 255, 255, 1)',
    borderColor: '#FFFFFF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    paddingTop: 2,
    width: '98%',
    margin: '-7px auto',
    maxHeight: subDrawerHeight,
  },
  subDrawerContent: {
    whiteSpace: 'normal',
  },
  winkLogo: {
    height: 'auto',
    maxWidth: '57px',
    margin: 'auto',
  },
  whiteLogo: {
    float: 'left',
    zIndex: '-1',
  },
  whiteLogoClosed: {
    width: 40,
    float: 'none',
  },
  whiteLogoClosedContainer: {
    display: 'none',
    //  transition: 'display 3s ease-in-out',
  },
  whiteLogoOpenContainer: { display: 'inline-block' },
  greenCircle: {
    backgroundColor: '#1AA7B0',
  },
  redCircle: {
    backgroundColor: '#eb3838',
  },
  circle: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5px',
  },
  logoMicrophoneContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  rectangle: {
    background: 'rgba(108, 102, 201, 0.25)',
    borderRadius: '45px',
    width: '115px',
    height: '6px',
    margin: '5px auto',
  },
  poweredBy: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 0,
    paddingRight: 1,
    marginBottom: '5px',
    lineHeight: '21px',
  },
};

const openedMixin: React.CSSProperties = {
  backgroundColor: 'transparent',
  position: 'absolute',
  top: 0,
  height: '100%',
  border: 'none',
  boxShadow: 'rgba(0, 0, 0, 0.25)',
  overflowX: 'hidden',
  minWidth: 300,
  minHeight: 500,
};

const closedMixin: React.CSSProperties = {
  backgroundColor: 'transparent',
  height: '100%',
  border: 'none',
  overflowX: 'hidden',
  minWidth: 300,
  visibility: 'hidden',
  position: 'relative',
};

const DrawerHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'drawerOpen',
})<{ drawerOpen: boolean }>(({ theme, drawerOpen }) => ({
  boxSizing: 'border-box',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '15px',
  width: drawerWidth,
  border: '1px solid rgba(255, 255, 255, 1)',
  padding: '0px 10px',
  zIndex: 3,
  whiteSpace: 'normal',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  transition: 'max-height 0.3s ease-in-out',
  minHeight: 'min-content !important',
  maxHeight: !drawerOpen ? '0px' : '500px',
  boxShadow: !drawerOpen ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)',
}));

const DrawerOpen = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ open }) => ({
  flexShrink: 0,
  boxShadow: 'rgba(0, 0, 0, 0.25)',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  width: isMobile ? '95%' : 420,
  ...(open && {
    ...openedMixin,
    '& .MuiDrawer-paper': openedMixin,
  }),
  ...(!open && {
    ...closedMixin,
    '& .MuiDrawer-paper': closedMixin,
  }),
}));

interface Props {
  children?: React.ReactNode;
  headerComponent?: React.ReactNode;
  disableSubDrawer?: boolean;
}

export const WinkDrawer: React.FC<Props> = ({
  children,
  headerComponent = <>''</>,
  disableSubDrawer = false,
}) => {
  const [subDrawerWasOpen, setSubDrawerWasOpen] = useState(false);
  // if logo load has a delay we prevent the drawer to have an incorrect style
  const [wingLogoLoaded, setWingLogoLoaded] = useState(false);

  const dispatch = useAppDispatch();

  const drawerOpen = useAppSelector((state) => state.drawer.isDrawerOpen);
  const subDrawerOpen = useAppSelector((state) => state.drawer.isSubDrawerOpen);
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );

  const drawerRef = useRef(null);

  const handleDrawerOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (subDrawerOpen && drawerOpen) {
      setSubDrawerWasOpen(true);
      dispatch(setIsSubDrawerOpen(false));
      setTimeout(() => {
        dispatch(setIsDrawerOpen(!drawerOpen));
      }, 300);
    } else if (subDrawerWasOpen && !drawerOpen) {
      setSubDrawerWasOpen(false);
      dispatch(setIsSubDrawerOpen(true));
      dispatch(setIsDrawerOpen(!drawerOpen));
    } else {
      dispatch(setIsDrawerOpen(!drawerOpen));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        borderColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        fontFamily: merchantConfiguration?.font,
      }}
      onClick={handleDrawerOpen}
    >
      <DrawerOpen variant="permanent" anchor="top" open={drawerOpen}>
        <DrawerHeader
          ref={drawerRef}
          drawerOpen={drawerOpen}
          id="drawer"
          sx={{
            backgroundColor: merchantConfiguration?.backgroundColor,
            color: merchantConfiguration?.color,
            fontFamily: merchantConfiguration?.font,
          }}
        >
          {wingLogoLoaded && headerComponent}
          <Box
            style={
              drawerOpen && wingLogoLoaded
                ? styles.whiteLogoOpenContainer
                : styles.whiteLogoClosedContainer
            }
          >
            <img
              onLoad={() => setWingLogoLoaded(true)}
              style={drawerOpen ? styles.whiteLogo : styles.whiteLogoClosed}
              src="assets/wink_logo_white.png"
              alt="Wink Logo"
            />
          </Box>
        </DrawerHeader>

        <div
          id="subdrawer"
          style={
            drawerOpen
              ? undefined
              : { visibility: 'visible', height: !drawerOpen && '40px' }
          }
        >
          <SubDrawer
            disableSubDrawer={disableSubDrawer}
            drawerOpen={drawerOpen}
            handleDrawerOpen={handleDrawerOpen}
          >
            {children}
          </SubDrawer>
        </div>
      </DrawerOpen>
    </Box>
  );
};

interface SubDrawerProps {
  disableSubDrawer: boolean;
  drawerOpen: boolean;
  handleDrawerOpen: (e: React.MouseEvent) => void;
  children: React.ReactNode;
}

const SubDrawer: React.FC<SubDrawerProps> = ({
  children,
  disableSubDrawer,
  drawerOpen,
  handleDrawerOpen,
}) => {
  const subDrawerOpen = useAppSelector((state) => state.drawer.isSubDrawerOpen);

  const dispatch = useAppDispatch();
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const isMerchantProps = useAppSelector((state) => state.app.isMerchantProps);
  useEffect(() => {
    if (disableSubDrawer || !children) {
      // Hide subDrawer if no children
      dispatch(setIsSubDrawerOpen(false));
    } else {
      dispatch(setIsSubDrawerOpen(true));
    }
  }, []);

  const handleSubDrawerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!children || disableSubDrawer) return; // Disable functionality if no children or disableSubDrawer === true
    dispatch(setIsSubDrawerOpen(!subDrawerOpen));
  };

  return (
    <div>
      <Box
        onClick={drawerOpen ? handleSubDrawerClick : handleDrawerOpen}
        className="no-style-scrollbar"
        sx={styles.subDrawerContainerOpen}
        style={{
          backgroundColor: isMerchantProps
            ? merchantConfiguration?.backgroundColor
            : 'rgb(244, 243, 254)',
          color: merchantConfiguration?.color,
        }}
        maxHeight={!isMobile ? '90vh' : undefined}
      >
        <Box sx={styles.subDrawerContent}>
          <Collapse
            in={subDrawerOpen}
            sx={{ fontFamily: merchantConfiguration?.font }}
          >
            {children}
          </Collapse>
          {!subDrawerOpen && <div style={styles.rectangle}></div>}
          {drawerOpen && (
            <Box sx={styles.poweredBy}>
              Powered by <img width={70} src="assets/wink_logo_footer.png" />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};
