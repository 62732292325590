import React, { useState } from 'react';

import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import { useAppSelector } from 'hooks/useAppSelector';

import { StyledButton } from 'components/button';

import '../../style.css';

interface Props {
  handleEnable: (e: React.MouseEvent) => void;
}

export const NotReturningUserContainer: React.FC<Props> = ({
  handleEnable,
}) => {
  const [buttonEnabled, setButtonEnabled] = useState(true);

  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );

  return (
    <Card
      sx={{
        maxWidth: 400,
        background: merchantConfiguration?.backgroundColor,
        border: '1px solid #FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '25px',
        margin: 'auto',
        '@media (max-width: 400px)': {
          width: '90vw',
        },
        color: merchantConfiguration?.color,
      }}
    >
      <img
        src="assets/wink_logo_white.png"
        style={{ width: '70px', float: 'right', marginTop: 65 }}
        alt="Wink Logo"
      />

      <CardContent sx={{ padding: 4, paddingBottom: 1 }}>
        {merchantConfiguration?.logoFileUrl !== '' && (
          <Box textAlign="center">
            <img
              src={merchantConfiguration?.logoFileUrl}
              style={{ maxWidth: 100, maxHeight: 100 }}
              alt="Merchant Logo"
            />
          </Box>
        )}

        <Typography
          textAlign="center"
          mb={1}
          fontWeight={600}
          fontFamily={merchantConfiguration?.font}
        >
          Login with Wink
        </Typography>
        <Typography textAlign="center" fontFamily={merchantConfiguration?.font}>
          Please click <b>“Enable”</b> below to grant Wink access to your camera
          and microphone so you can login using your face or voice.
        </Typography>

        <CardActions
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <a
            href="https://wink.cloud/privacy-policy.php"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
            style={{
              textDecoration: 'none',
              marginBottom: 5,
              marginTop: 25,
              fontFamily: merchantConfiguration?.font,
            }}
          >
            Learn More
          </a>
          <Typography
            fontSize={10}
            fontWeight={600}
            mb={2}
            textAlign="center"
            fontFamily={merchantConfiguration?.font}
          >
            Your face & voice data will not be saved.
          </Typography>
          <StyledButton
            disabled={!buttonEnabled}
            sx={{ width: '70%', fontFamily: merchantConfiguration?.font }}
            onClick={handleEnable}
          >
            Enable
          </StyledButton>

          <Box mt={1} flexDirection="row" alignContent="center">
            <FormGroup>
              <FormControlLabel
                checked={buttonEnabled}
                control={
                  <Checkbox
                    sx={{
                      verticalAlign: 'center',
                      '&.Mui-checked': {
                        color: merchantConfiguration?.buttonColor,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: 9,
                      lineHeight: '15px',
                      textAlign: 'center',
                      verticalAlign: 'center',
                      fontFamily: merchantConfiguration?.font,
                    }}
                  >
                    Allow Wink to retrieve your phone number and contact details
                    from your mobile service provider
                  </Typography>
                }
                onChange={() => setButtonEnabled(!buttonEnabled)}
              />
            </FormGroup>
          </Box>
        </CardActions>
      </CardContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: 1,
          fontFamily: merchantConfiguration?.font,
        }}
      >
        Powered by <img width={70} src="assets/wink_logo_footer.png" />
      </Box>
    </Card>
  );
};
