export const ExistingAccountCode = 'existing-account';
export const NewAccount = 'new-account';
export const ErrorCode = 'error';
export const EnrollMaxErrors = 'max-errors';
export const LivenessErrorCode = 'liveness-fail';
export const oAuthRequestIdCode = 'oAuthRequestId';
export const FraudCode = 'fraud';
export const TokenCode = 'token';
export const YellowCode = 'yellow';
export const YellowCodeToken = 'yellow-token';
export const SuccessCode = 'success';
export const OnWorking = 'sync-message';
export const IncompleteEnrollment = 'incomplete-enroll';
export const VoiceLivenessError = 'voice-liveness-error';
export const WsErrorMessageCode = 'errorMessage';
export const errorInvitation = 'error-invitation';
