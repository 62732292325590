import { configureStore } from '@reduxjs/toolkit';

import appSlice from './slices/appSlice';
import drawerSlice from './slices/drawerSlice';
import userSlice from './slices/userSlice';

const store = configureStore({
  reducer: {
    drawer: drawerSlice,
    user: userSlice,
    app: appSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
