import { createSlice } from '@reduxjs/toolkit';

import { instructions } from 'const/instructions';

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    editingProfile: false,
    isSubDrawerOpen: true,
    isDrawerOpen: true,
    showMediaDeviceIcons: false,
    mediaRecording: false,
    drawerMessage: instructions.login.normalLoginDefault,
  },
  reducers: {
    toggleEditingProfile: (state) => {
      state.editingProfile = !state.editingProfile;
    },
    setIsSubDrawerOpen: (state, action) => {
      state.isSubDrawerOpen = action.payload;
    },
    setIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload;
    },
    setShowMediaDeviceIcons: (state, action) => {
      state.showMediaDeviceIcons = action.payload;
    },
    setMediaRecording: (state, action) => {
      state.mediaRecording = action.payload;
    },
    setDrawerMessage: (state, action) => {
      state.drawerMessage = action.payload;
    },
  },
});

export const {
  toggleEditingProfile,
  setIsSubDrawerOpen,
  setIsDrawerOpen,
  setShowMediaDeviceIcons,
  setDrawerMessage,
  setMediaRecording,
} = drawerSlice.actions;
export default drawerSlice.reducer;
