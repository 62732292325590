import axiosInstance from '../axiosInstance';

import { useAppDispatch } from 'hooks/useAppDispatch';

import {
  getClientConfigurationUrl,
  getClientSecretUrl,
} from './ClientApiService';

type DispatchType = ReturnType<typeof useAppDispatch>;
interface Options {
  clientSecret?: string;
  clientId: string;
  localStorage: Storage;
  dispatch: DispatchType;
  sessionToken: string;
}

export const getClientSecret = async ({
  clientId,
  localStorage,
  dispatch,
  sessionToken,
}: Options) => {
  try {
    const url = getClientSecretUrl();
    const instance = axiosInstance(localStorage, dispatch, sessionToken);
    const response = await instance.post(
      url,
      {},
      {
        params: {
          clientId,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getClientConfiguration = async ({
  clientSecret,
  clientId,
  localStorage,
  dispatch,
  sessionToken,
}: Options) => {
  try {
    const url = getClientConfigurationUrl();
    const instance = axiosInstance(localStorage, dispatch, sessionToken);
    const response = await instance.post(url, {
      clientId,
      clientSecret,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
