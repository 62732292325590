export interface IEnrollmentInstructions {
  title: string;
  tapSave: string;
  title3: string;
  title4: string;
  startProfile: string;
  successOnIdCaptured: string;
  speakSuccessOnIdCaptured: string;
  errorOnIdCaptured: string;
  speakErrorOnIdCaptured: string;
  errorOnIdQuantityPages: string;
  speakErrorOnIdQuantityPages: string;
  phrase: string;
  phrase2: string;
  errorMessage: string;
  enrollMaxErrors: string;
  retakeFaceScan: string;
  changeInstruction: string;
  completeProfile: string;
  micDisabledTxt: string;
  userExist: string;
  repeatText: string;
  mediaDeviceError: string;
  livenessError: string;
  firstLivenessTips: string;
  livenessTips: string;
  incompleteEnrollment: string;
  voiceLivenessError: string;
}

interface ILoginInstructions {
  fastLoginDefault: string;
  normalLoginDefault: string;
  success: string;
  welcome: string;
  welcomeWithName: (name: string) => string;
  errorMessage: string;
  loginAfterSaveDataProfile: string;
  yellowCaseDrawer: string;
  yellowCase: string;
  yellowCaseFailedText: string;
  yellowCaseFinalText: string;
}

interface Instructions {
  enrollment: IEnrollmentInstructions;
  login: ILoginInstructions;
}
export const instructions: Instructions = {
  enrollment: {
    title:
      'Hello, to setup your Wink ID ensure your face is visible in the window below and repeat the sample text until the timer ends.',
    tapSave: 'Tap Save below to finish your WinkID profile.',
    title3: 'Please place your face at center of view box and try again!',
    title4:
      'hi    ,add voice verification to your account to checkout faster with any Wink powered retailer.',
    startProfile:
      'Success! Now let’s add your profile information to complete your Wink ID.',
    successOnIdCaptured:
      '<strong>Success!! Your identity is verified.</strong> Please enter your contact information (phone and email) to complete enrollment',
    speakSuccessOnIdCaptured:
      'Success!! Your identity is verified. Please enter your contact information (phone and email) to complete enrollment',
    errorOnIdCaptured:
      "<strong>We are sorry we can't validate your identity.</strong> Please click Retry or complete your enrollment manually.",
    speakErrorOnIdCaptured:
      "We are sorry we can't validate your identity. Please click Retry or complete your enrollment manually.",
    errorOnIdQuantityPages:
      '<strong>More ID pages are required.</strong> Please upload the total of pages in a new attempt.',
    speakErrorOnIdQuantityPages:
      'More ID pages are required. Please upload the total of pages in a new attempt.',
    phrase: '"Being unique is better than',
    phrase2: 'being perfect"',
    errorMessage: 'Unsuccessful Enrollment. Please try again.',
    enrollMaxErrors:
      'For the new attempt, ensure improved lighting, a clear face without accessories, and speak for at least 3 seconds.',
    retakeFaceScan:
      "Please repeat the next below or share anything you'd like with me until the timer ends.",
    changeInstruction:
      "Please give us a moment while we register your face and voice to your Wink ID. This shouldn't take long.",
    completeProfile:
      'Wink needs your contact information to complete setting up your account',
    micDisabledTxt:
      'Please ensure your face is visible in the window below and repeat the words below until the timer ends',
    userExist:
      'Existing Account Found your face and voice are associated with an existing Wink ID. Tap below to login.',
    repeatText:
      "Roses are red, violets are blue I'm just making sure, that you are you. On second hand, it should probably be The person that's talking, is actually me",
    mediaDeviceError:
      'Media device error: camera and microphone should be enabled. Please try again.',
    livenessError:
      'To help us get a good quality face for your profile, ensure you are in a well lit place and please turn brightness of your screen to maximum',
    firstLivenessTips:
      'Get ready to scan your face. Follow these tips for a smooth scan and when prompted, place your face in the circular window to begin.',
    livenessTips:
      'You could not be verified. Please make sure face is visible and well lit for verification.',
    incompleteEnrollment: 'Existing account found with incomplete enrollment.',
    voiceLivenessError:
      'Please read the voice text below with numbers to pass voice liveness.',
  },
  login: {
    fastLoginDefault:
      'Please ensure your face is visible in the window below. Your mic has been muted.',
    normalLoginDefault:
      'To login please ensure your face is visible in the window below and repeat the words below until the timer ends.',
    success:
      'Verification was successful. One moment while we load your account.',
    welcome: "Hi, say Hello Wink when you're ready to login.",
    welcomeWithName: (name: string) =>
      `Hi ${name}, say Hello Wink when you're ready to login.`,
    errorMessage: 'Unsuccessful Login. Please try again.',
    loginAfterSaveDataProfile:
      'Welcome to WINK, Your Wink ID has been created. Now you can use your face and voice to login. ',
    yellowCaseDrawer:
      'There was a problem recognizing you. Please tap scan again to retry or tap skip to answer your wink security question.',
    yellowCase:
      'To finish verifing your WinkID please answer the following security questions or turn on your camera for face verification.',
    yellowCaseFailedText:
      'An incorrect date of birth was provided. Please try again. After three failed attempts you will be prompted to create a new WinkID.',
    yellowCaseFinalText:
      'Attempt 3 of 3 An incorrect date of birth was provided. Please create a new WinkID or hit cancel to return home.',
  },
};
