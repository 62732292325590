import axiosInstance from '../axiosInstance';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { getRecognitionMessageUrl } from './RecognitionMessageApiService';

type DispatchType = ReturnType<typeof useAppDispatch>;

export async function getRecognitionMessage(
  recognitionMessageId: string,
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) {
  const url = getRecognitionMessageUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const response = await instance.get(url, {
    headers: {
      RequestId: requestId,
      recognitionMessageId,
      SessionId: sessionToken,
    },
  });
  return response;
}
