import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';

// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

import {
  setMicActive,
  setRoute,
  setWinkEnabled,
  setIsiOS,
} from 'app/slices/appSlice';
import { setShowMediaDeviceIcons } from 'app/slices/drawerSlice';
import { resetUserInfo } from 'app/slices/userSlice';

import { useLocalStorage } from 'context/AppContext';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import { initSynthesizer, restartSynthesizer } from 'api/speech';

import { DrawerContainerStyled } from 'components/drawer/DrawerContainerStyled';
import MediaDeviceIcons from 'components/drawer/MediaDeviceIcons';
import { WinkToggle } from 'components/drawer/WinkToggle';
import { VerificationContentSubDrawer } from 'components/VerificationContentSubDrawer';
import { WinkDrawer } from 'components/WinkDrawer';

import { getAnyClientToken, getToken } from 'const/localStorage';

import '../../style.css';

interface WinkToken {
  WinkTag: string;
  FirstName: string;
}

export const ReturningUserContainer: React.FC = () => {
  // TODO check typing, if needed extend
  const [tokenDecoded, setTokenDecoded] = useState<WinkToken | null>();
  const [allowedRetrieveData, setAllowedRetrieveData] = useState<
    string | null
  >();

  const localStorage = useLocalStorage();

  const dispatch = useAppDispatch();
  const micEnabled = useAppSelector((state) => state.app.micEnabled);
  const requestId = useAppSelector((state) => state.app.requestId);
  const drawerOpen = useAppSelector((state) => state.drawer.isDrawerOpen);
  const isiOS = useAppSelector((state) => state.app.isiOS);
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );

  const actionWord = !micEnabled ? 'Continue ' : 'Hello Wink';

  const [searchParams] = useSearchParams();

  const clientId = searchParams.get('client_id') || '';

  useEffect(() => {
    if (localStorage) {
      const allowedData = localStorage.getItem('allowedRetrieveData');
      setAllowedRetrieveData(allowedData);
      let token = getToken(localStorage);
      if (!token) {
        token = getAnyClientToken(localStorage);
      }
      const winkSeedDecoded = token
        ? jwt_decode<WinkToken>(token, { header: true })
        : null;
      setTokenDecoded(winkSeedDecoded);
    }
  }, [localStorage]);

  useEffect(() => {
    if (allowedRetrieveData) {
      dispatch(setWinkEnabled(true));
    }
  }, [dispatch, allowedRetrieveData]);

  useEffect(() => {
    const { userAgent, maxTouchPoints } = navigator;
    const isiOS =
      /iPad|iPhone|iPod/.test(userAgent) ||
      (maxTouchPoints && maxTouchPoints > 1 && /Macintosh/.test(userAgent));

    if (isiOS) {
      dispatch(setIsiOS(true));
    } else {
      dispatch(setIsiOS(false));
      dispatch(setMicActive(true));
    }
    dispatch(setShowMediaDeviceIcons(true));
  }, [requestId]);

  const triggerRecognition = () => {
    // Init bot synthetizer because IOS need the user interaction to speak
    restartSynthesizer();
    initSynthesizer();
    dispatch(setRoute('/enroll'));
  };

  const resetUser = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(resetUserInfo());
    // Init bot synthetizer because IOS need the user interaction to speak
    restartSynthesizer();
    initSynthesizer();
    localStorage?.removeItem(`wssToken-${clientId}`);
    dispatch(setRoute('/enroll'));
  };

  return (
    <>
      <WinkDrawer
        disableSubDrawer={true}
        headerComponent={
          <DrawerContainerStyled drawerOpen={drawerOpen}>
            <WinkToggle />
            <Box
              mt={1}
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={1}
              alignItems="center"
              minWidth="45%"
              fontFamily={merchantConfiguration.font}
            >
              <Typography
                component="span"
                textAlign="center"
                variant="body1"
                fontWeight="bold"
                fontFamily={merchantConfiguration.font}
              >
                Login with Wink ID
              </Typography>
              <>
                <Typography
                  component="span"
                  sx={{ paddingRight: 0.5 }}
                  textAlign="center"
                  variant="body2"
                  fontFamily={merchantConfiguration.font}
                >
                  <span>
                    {`Hi ${
                      tokenDecoded?.FirstName || 'Guest'
                    }, to get started ${!micEnabled ? 'click ' : 'say '}`}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        triggerRecognition();
                      }}
                      style={{
                        color: '#7364AC',
                        cursor: 'pointer',
                      }}
                    >
                      {actionWord}
                    </span>
                    {" when you're ready to login."}
                  </span>
                </Typography>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button sx={{ textTransform: 'none' }} onClick={resetUser}>
                    <Typography
                      component="span"
                      variant="body2"
                      fontWeight="bold"
                      color="#6C66C9"
                      fontFamily={merchantConfiguration.font}
                    >
                      {tokenDecoded?.WinkTag && tokenDecoded?.FirstName
                        ? `Not ${tokenDecoded?.FirstName}?`
                        : 'Start Over'}
                    </Typography>
                  </Button>
                </Box>
              </>
            </Box>
            {isiOS !== undefined && <MediaDeviceIcons isiOS={isiOS} />}
          </DrawerContainerStyled>
        }
      >
        <VerificationContentSubDrawer />
      </WinkDrawer>
    </>
  );
};
