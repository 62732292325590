import React from 'react';
import { useLocation } from 'react-router-dom';

import NavigateWithQuery from './NavigateWithQuery';

type Props = {
  redirectPath?: string;
  children: React.ReactElement;
};

const PrivateRoute: React.FC<Props> = ({ redirectPath = '/', children }) => {
  const location = useLocation();
  const isAllowed = location?.state?.isAllowed;

  if (!isAllowed) {
    return <NavigateWithQuery to={redirectPath} replace />;
  }
  return children;
};

export default PrivateRoute;
