export function getUserUrl(): string {
  return 'User/user-info';
}

export function getUserInfoUrl(): string {
  return 'User';
}

export function getSecretQuestionsUrl(): string {
  return 'UserSecurity/SecurityQuestionList';
}

export function getSecretQuestionsPostUrl(): string {
  return 'UserSecurity/InsertAnswerByQuesId';
}

export function getSecretQuestionsUpdateUrl(): string {
  return 'UserSecurity/UpdateAnswerByQuesId';
}

export function getProfileCompletionUrl(): string {
  return 'User/user-profile-completion';
}

export function getFakeEmailUrl(): string {
  return 'User/EmailVerification';
}

export function getContactNoCheck(): string {
  return 'User/ContactNoCheck';
}

export function getEmailCheck(): string {
  return 'User/EmailCheck';
}

export function getDocumentDataUrl(): string {
  return 'User/getDocumentData';
}
