import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContentText,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';

import {
  // setInitKeycloak,
  setIsClientPermissionOpen,
  setIsSessionTimeOut,
} from 'app/slices/appSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import { isSessionActive } from 'api/device/device';
import { createUpdateClientPermission } from 'api/user-client-permission/userClientPermission';

import {
  StyledDialogContent,
  /* CancelButton, */
  TableCellLeft,
  AgreeButton,
} from './StyledComponent';

const styles = {
  link: {
    color: '#706bca',
    fontWeight: 700,
    textDecoration: 'none',
  },
};

export const AppPermission: React.FC = () => {
  const [searchParams] = useSearchParams();
  const merchantName = searchParams.get('client_id') || '';
  const requestId = useAppSelector((state) => state.app.requestId);
  const isClientPermissionOpen = useAppSelector(
    (state) => state.app.isClientPermissionOpen,
  );
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const isMerchantProps = useAppSelector((state) => state.app.isMerchantProps);
  const sessionToken = useAppSelector((state) => state.app.sessionToken);

  const dispatch = useAppDispatch();

  const handleAgreeAndShare = async () => {
    if (localStorage && requestId) {
      const isSessionValid = await isSessionActive({
        localStorage,
        dispatch,
        sessionToken,
      });
      if (isSessionValid) {
        const data = {
          fullName: true,
          contactNo: true,
          email: true,
        };
        if (requestId) {
          const response = await createUpdateClientPermission(
            data,
            requestId,
            dispatch,
            sessionToken,
          );
          if (response.status === 200) {
            dispatch(setIsClientPermissionOpen(false));
          }
        }
      }
    } else {
      dispatch(setIsSessionTimeOut(true));
    }
  };
  /*  const handleCancel = () => {
    dispatch(setIsClientPermissionOpen(false));
    dispatch(setInitKeycloak(true));
  }; */
  return (
    <Dialog
      open={isClientPermissionOpen}
      sx={{
        color: merchantConfiguration?.color,
        backgroundColor: merchantConfiguration?.backgroundColor,
        font: merchantConfiguration?.font,
      }}
    >
      <StyledDialogContent
        sx={{
          color: merchantConfiguration?.color,
          backgroundColor: isMerchantProps
            ? merchantConfiguration?.backgroundColor
            : '',
        }}
      >
        <img
          src="../../assets/wink_semicolon.png"
          style={{ marginBottom: 10 }}
        ></img>
        <DialogContentText
          component="span"
          fontFamily={merchantConfiguration?.font}
        >
          <Typography
            fontWeight={600}
            fontSize={16}
            color="black"
            fontFamily={merchantConfiguration?.font}
          >
            Allow Wink to sign you into {merchantName}
          </Typography>
          <Typography
            fontSize={14}
            color="black"
            style={{ margin: '10px 0' }}
            fontFamily={merchantConfiguration?.font}
          >
            By continuing, Wink will share the below information with Merchant
            in accordance with their{' '}
            <a
              href="https://wink.cloud/privacy-policy"
              target="_blank"
              style={styles.link}
              rel="noreferrer"
            >
              privacy policy{' '}
            </a>{' '}
            and{' '}
            <a
              href="https://wink.cloud/terms"
              target="_blank"
              style={styles.link}
              rel="noreferrer"
            >
              terms of service.
            </a>
            You can change this and other account permissions in your{' '}
            <span style={styles.link}>Wink Account</span> anytime.
          </Typography>
        </DialogContentText>
        <Table>
          <TableBody>
            <TableRow>
              <TableCellLeft sx={{ fontFamily: merchantConfiguration?.font }}>
                Name
              </TableCellLeft>
              <TableCell padding="none" align="right">
                <Checkbox
                  defaultChecked
                  disabled
                  style={{ color: '#d8d8d8' }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellLeft sx={{ fontFamily: merchantConfiguration?.font }}>
                Email
              </TableCellLeft>
              <TableCell padding="none" align="right">
                <Checkbox
                  defaultChecked
                  disabled
                  style={{ color: '#d8d8d8' }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellLeft sx={{ fontFamily: merchantConfiguration?.font }}>
                Phone Number
              </TableCellLeft>
              <TableCell padding="none" align="right">
                <Checkbox
                  defaultChecked
                  disabled
                  style={{ color: '#d8d8d8' }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <DialogActions sx={{ justifyContent: 'center' }}>
          {/* <CancelButton variant="outlined" onClick={handleCancel}>
            Cancel
          </CancelButton> */}
          <AgreeButton
            variant="contained"
            onClick={handleAgreeAndShare}
            sx={{
              backgroundColor: merchantConfiguration?.buttonColor,
              fontFamily: merchantConfiguration?.font,
            }}
          >
            Agree & Share
          </AgreeButton>
        </DialogActions>
      </StyledDialogContent>
    </Dialog>
  );
};
