import React from 'react';

import { Typography } from '@mui/material';

import { isMobile } from 'const/breakpoints';

interface Props {
  text: string;
}

export const TextToRead: React.FC<Props> = ({ text }) => {
  const moderateThreshold = 84; // Length where text fits well on mobile
  const highThreshold = 120; // Higher length limit where we decide to truncate

  let displayText = text;
  let fontSize = isMobile ? '18px' : '20px'; // Base font size, larger on non-mobile

  if (isMobile) {
    if (text?.length > highThreshold) {
      // Adjust font size before applying ellipsis for very long texts
      const sizeReduction = Math.min(
        Math.floor((text.length - highThreshold) / 10),
        3, // Allow up to 3px reduction
      );
      fontSize = `${18 - sizeReduction}px`; // Reduce font size in steps of 1px
      displayText = `${text.substring(
        0,
        highThreshold - sizeReduction * 10,
      )}...`; // Shorten text before adding ellipsis
    } else if (text.length > moderateThreshold) {
      // Adjust font size for slightly longer texts
      const sizeReduction = Math.min(
        Math.floor((text.length - moderateThreshold) / 2),
        3,
      );
      fontSize = `${18 - sizeReduction}px`; // Reduce font size in steps of 1px
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        position: 'absolute',
      }}
    >
      <Typography
        style={{
          marginBottom: 20,
          fontSize: isMobile ? '20px' : '22px',
          lineHeight: '24px',
          color: '#1AA7B0',
          fontWeight: 800,
        }}
        variant="h3"
      >
        Now repeat the following:
      </Typography>
      <Typography
        variant="h4"
        style={{
          fontStyle: 'italic',
          lineHeight: '24px',
          fontSize,
          textAlign: 'center',
          color: '#3E3E3E',
          fontWeight: 600,
        }}
      >
        {displayText}
      </Typography>
    </div>
  );
};
