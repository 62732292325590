import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { stylesBtn } from 'Theme';

import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useLocalStorage } from 'context/AppContext';

import { useAppSelector } from 'hooks/useAppSelector';
import { useMediaDevices } from 'hooks/useMediaDevices';
import { useNavigateWithQuery } from 'hooks/useNavigateWithQuery';

import { initSynthesizer, restartSynthesizer, speak } from 'api/speech';

import { scrollToElement } from 'components/utils';

import { IEnrollmentInstructions, instructions } from 'const/instructions';
import {
  ErrorCode,
  EnrollMaxErrors,
  ExistingAccountCode,
  YellowCode,
  IncompleteEnrollment,
  VoiceLivenessError,
} from 'const/wsTypeMessages';

interface CommonProps {
  symbioCode: string | null;
  currentStep: number;
  setSymbioCode: Dispatch<SetStateAction<string | null>>;
  setYellowRetryPressed: Dispatch<SetStateAction<boolean>>;
  merchantConfiguration: any;
  wsErrorMessage?: string;
  setWsErrorMessage?: Dispatch<SetStateAction<string | undefined>>;
}

const ShowDisplayMessages: React.FC<CommonProps> = ({
  symbioCode,
  currentStep,
  setYellowRetryPressed,
  merchantConfiguration,
  wsErrorMessage,
  setWsErrorMessage,
}) => {
  if (symbioCode === ExistingAccountCode) {
    return <ExistingAccount />;
  }

  if (currentStep === 1) {
    return <NewUser />;
  }

  if (currentStep === 2) {
    return (
      <UserCreatedSuccessfully merchantConfiguration={merchantConfiguration} />
    );
  }

  if (wsErrorMessage && symbioCode === YellowCode) {
    return (
      <Yellow
        setYellowRetryPressed={setYellowRetryPressed}
        wsErrorMessage={wsErrorMessage}
        setWsErrorMessage={setWsErrorMessage}
      />
    );
  } else if (wsErrorMessage) {
    return <WsError wsErrorMessage={wsErrorMessage} />;
  } else {
    switch (symbioCode) {
      case YellowCode:
        return <Yellow setYellowRetryPressed={setYellowRetryPressed} />;
      case EnrollMaxErrors:
        return <MaxErrors />;
      case ErrorCode:
        return <Error />;
      case IncompleteEnrollment:
        return <IncompleteEnroll />;
      case VoiceLivenessError:
        return <VoiceError />;
      case 'liveness-tips':
        return <LivenessTips />;
      default:
        break;
    }
  }

  return <DefaultMessage merchantConfiguration={merchantConfiguration} />;
};

interface MerchantProps {
  merchantConfiguration: any;
}
const UserCreatedSuccessfully: React.FC<MerchantProps> = ({
  merchantConfiguration,
}) => (
  <>
    <Typography variant="subtitle1" fontFamily={merchantConfiguration?.font}>
      <b>Welcome to WINK</b>
    </Typography>
    <Typography
      variant="body1"
      style={{ textAlign: 'center' }}
      fontFamily={merchantConfiguration?.font}
    >
      Your Wink ID has been created.
    </Typography>
    <Typography
      variant="body1"
      style={{ textAlign: 'center' }}
      fontFamily={merchantConfiguration?.font}
    >
      Now you can use your face and voice to login.
    </Typography>
  </>
);

const NewUser: React.FC = () => {
  // LocalStorage
  const localStorage = useLocalStorage();
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const { isIdCaptured, idHasMorePages } = useAppSelector(
    (state) => state.user.userInfo,
  );

  const [description, setDescription] = React.useState(
    instructions.enrollment.startProfile,
  );

  useEffect(() => {
    speak(instructions.enrollment.startProfile, null, localStorage);
  }, []);

  useEffect(() => {
    const executeActions = (
      speakKey: keyof IEnrollmentInstructions,
      descriptionKey: keyof IEnrollmentInstructions,
    ) => {
      speak(instructions.enrollment[speakKey], null, localStorage);
      setDescription(instructions.enrollment[descriptionKey]);
    };

    if (isIdCaptured) {
      executeActions('speakSuccessOnIdCaptured', 'successOnIdCaptured');
    } else if (isIdCaptured === false) {
      executeActions('speakErrorOnIdCaptured', 'errorOnIdCaptured');
    } else if (idHasMorePages) {
      executeActions('speakErrorOnIdQuantityPages', 'errorOnIdQuantityPages');
    }
  }, [isIdCaptured, idHasMorePages]);

  return (
    <Typography
      variant="subtitle1"
      textAlign="center"
      lineHeight="20px"
      fontFamily={merchantConfiguration?.font}
      dangerouslySetInnerHTML={{ __html: description || '' }}
    />
  );
};

const WsError: React.FC<{ wsErrorMessage: string }> = ({ wsErrorMessage }) => {
  const localStorage = useLocalStorage();
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  useEffect(() => {
    speak(wsErrorMessage, null, localStorage);
  }, []);
  return (
    <Typography
      variant="subtitle1"
      textAlign="center"
      lineHeight="20px"
      fontFamily={merchantConfiguration?.font}
    >
      {wsErrorMessage}
    </Typography>
  );
};

const Error: React.FC = () => {
  // LocalStorage
  const localStorage = useLocalStorage();
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  useEffect(() => {
    speak(instructions.enrollment.errorMessage, null, localStorage);
  }, []);
  return (
    <Typography
      variant="subtitle1"
      textAlign="center"
      lineHeight="20px"
      fontFamily={merchantConfiguration?.font}
    >
      {instructions.enrollment.errorMessage}
    </Typography>
  );
};

const MaxErrors: React.FC = () => {
  // LocalStorage
  const localStorage = useLocalStorage();
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  useEffect(() => {
    speak(instructions.enrollment.enrollMaxErrors, null, localStorage);
  }, []);
  return (
    <Typography
      variant="subtitle1"
      textAlign="center"
      lineHeight="20px"
      fontFamily={merchantConfiguration?.font}
    >
      {instructions.enrollment.enrollMaxErrors}
    </Typography>
  );
};

interface YellowProps {
  setYellowRetryPressed: Dispatch<SetStateAction<boolean>>;
  wsErrorMessage?: string;
  setWsErrorMessage?: Dispatch<SetStateAction<string | undefined>>;
}

const Yellow: React.FC<YellowProps> = ({
  setYellowRetryPressed,
  wsErrorMessage = '',
  setWsErrorMessage,
}) => {
  // LocalStorage
  const localStorage = useLocalStorage();
  const addQueryAndNavigate = useNavigateWithQuery();
  const { toggleOffCameraAndMic } = useMediaDevices();
  const winkToken = useAppSelector((state) => state.app.winkToken);
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );

  const classes = stylesBtn(merchantConfiguration);

  const handleSkip = (e: React.MouseEvent) => {
    e.stopPropagation();
    setWsErrorMessage && setWsErrorMessage(undefined);
    toggleOffCameraAndMic();
    // Init bot synthetizer because IOS need the user interaction to speak
    restartSynthesizer();
    initSynthesizer();
    winkToken && addQueryAndNavigate('/security-questions', !!winkToken);
  };

  const retryRecognition = (e: React.MouseEvent) => {
    e?.stopPropagation();
    setYellowRetryPressed(true);
  };

  useEffect(() => {
    speak(
      wsErrorMessage || instructions.login.yellowCaseDrawer,
      null,
      localStorage,
    );
  }, []);

  return (
    <Box display="flex" gap={2} alignItems="center">
      <img
        src="assets/warning.png"
        alt="check face"
        style={{
          width: '35px',
          height: '35px',
          position: 'relative',
        }}
      />
      <Box>
        {' '}
        <Typography variant="subtitle1" textAlign="center" lineHeight="20px">
          {wsErrorMessage || instructions.login.yellowCaseDrawer}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          gap="15px"
          mt={1}
        >
          <Button
            style={{ ...classes.whiteButton, width: '50%' }}
            onClick={retryRecognition}
          >
            Scan Again
          </Button>
          <Button
            style={{ ...classes.greenButton, width: '50%' }}
            onClick={handleSkip}
          >
            Skip
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const ExistingAccount: React.FC = () => {
  // LocalStorage
  const localStorage = useLocalStorage();
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  useEffect(() => {
    speak(instructions.enrollment.userExist, null, localStorage);
  }, []);
  return (
    <>
      <Typography
        variant="subtitle1"
        textAlign="center"
        lineHeight="20px"
        fontFamily={merchantConfiguration?.font}
      >
        <b>Existing Account Found</b>
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        lineHeight="20px"
        fontFamily={merchantConfiguration?.font}
      >
        your face and voice are associated with an existing Wink ID. Tap below
        to login.
      </Typography>
    </>
  );
};

const IncompleteEnroll: React.FC = () => {
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  useEffect(() => {
    speak(instructions.enrollment.incompleteEnrollment, null, localStorage);
  }, []);
  return (
    <Typography
      variant="subtitle1"
      textAlign="center"
      lineHeight="20px"
      fontFamily={merchantConfiguration?.font}
    >
      {instructions.enrollment.incompleteEnrollment}
    </Typography>
  );
};

const VoiceError: React.FC = () => {
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  useEffect(() => {
    speak(instructions.enrollment.voiceLivenessError, null, localStorage);
  }, []);
  return (
    <Typography
      variant="subtitle1"
      textAlign="center"
      fontFamily={merchantConfiguration.font}
    >
      {instructions.enrollment.voiceLivenessError}
    </Typography>
  );
};

const LivenessTips: React.FC = () => {
  const enrollErrorCounter = useAppSelector(
    (state) => state.app.enrollErrorCounter,
  );
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );

  const message = useMemo(
    () =>
      enrollErrorCounter?.attempt === 0
        ? instructions.enrollment.firstLivenessTips
        : instructions.enrollment.livenessTips,
    [enrollErrorCounter],
  );

  useEffect(() => {
    speak(message, null, localStorage);
    scrollToElement('LetsDoThisButton', 2000);
  }, [message]);

  return (
    <Typography
      variant="subtitle1"
      textAlign="center"
      lineHeight="20px"
      fontFamily={merchantConfiguration?.font}
    >
      {enrollErrorCounter?.attempt === 0
        ? instructions.enrollment.firstLivenessTips
        : instructions.enrollment.livenessTips}
    </Typography>
  );
};

const DefaultMessage: React.FC<MerchantProps> = ({ merchantConfiguration }) => (
  <Typography
    id="msgeText"
    sx={{ color: '#3E3E3E' }}
    textAlign="center"
    variant="body1"
    lineHeight="20px"
    fontFamily={merchantConfiguration?.font}
  >
    {instructions.enrollment.title}
  </Typography>
);

export const HeaderMessages: React.FC<CommonProps> = ({
  symbioCode,
  currentStep,
  setSymbioCode,
  setYellowRetryPressed,
  merchantConfiguration,
  wsErrorMessage,
  setWsErrorMessage,
}) => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
      fontFamily: merchantConfiguration?.font,
    }}
  >
    <ShowDisplayMessages
      wsErrorMessage={wsErrorMessage}
      setWsErrorMessage={setWsErrorMessage}
      symbioCode={symbioCode}
      currentStep={currentStep}
      setSymbioCode={setSymbioCode}
      setYellowRetryPressed={setYellowRetryPressed}
      merchantConfiguration={merchantConfiguration}
    />
  </Box>
);
