import React, {
  useState,
  useEffect,
  forwardRef,
  useCallback,
  ForwardedRef,
  HTMLAttributes,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  Box,
  Collapse,
  Snackbar,
  SnackbarCloseReason,
  Typography,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import jwtDecode from 'jwt-decode';

import { setIsLoading, setLoginType, setRecognized } from 'app/slices/appSlice';
import {
  setIsSubDrawerOpen,
  setShowMediaDeviceIcons,
  toggleEditingProfile,
} from 'app/slices/drawerSlice';
import { setUserInfo } from 'app/slices/userSlice';

import { useLocalStorage } from 'context/AppContext';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useRequestId } from 'hooks/useRequestId';

import { keycloakCallback } from 'api/keycloak';
import { speak } from 'api/speech';
import { getClientPermissionStatus } from 'api/user-client-permission';
import { getProfileCompletion, getUser } from 'api/user/user';

import { AppPermission } from 'components/appPermission/AppPermission';
import { DrawerContainerStyled } from 'components/drawer/DrawerContainerStyled';
import { WinkToggle } from 'components/drawer/WinkToggle';
import { ProfileFormContainer } from 'components/profileForm/ProfileFormContainer';
import { WinkDrawer } from 'components/WinkDrawer';

import { instructions } from 'const/instructions';
import { getToken } from 'const/localStorage';

import '../../style.css';
import { ResumePanelMerchant } from './ResumePanelMerchant';

const Alert = forwardRef(
  (
    props: AlertProps & HTMLAttributes<HTMLDivElement>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

const ProfileCompletionTitle = {
  sucess: 'Success!',
  closed: 'Welcome Back',
};

const ProfileCompletionText = {
  success: 'Your profile was updated succesfully!',
  uncompleted: instructions.enrollment.startProfile,
  closed: "You've been successfully logged in.",
};

interface Instructions {
  enrollment: {
    startProfile: string;
    errorOnIdCaptured: string;
    speakErrorOnIdCaptured: string;
    errorOnIdQuantityPages: string;
    speakErrorOnIdQuantityPages: string;
    successOnIdCaptured: string;
    speakSuccessOnIdCaptured: string;
  };
}

type WinkToken = {
  WinkTag: string;
};

type DrawerMessageProps = {
  isIdCaptured?: boolean;
  idHasMorePages?: boolean;
  subDrawerOpen: boolean;
  percentageProgress: number;
  instructions: Instructions;
  speak: (message: string, callback: any, storage: Storage) => Promise<void>;
  localStorage: Storage | null;
  ProfileCompletionText: { [key: string]: string };
  ProfileCompletionTitle: { [key: string]: string };
};

const areEqual = (prevProps: any, nextProps: any) => {
  return (
    prevProps.isIdCaptured === nextProps.isIdCaptured &&
    prevProps.idHasMorePages === nextProps.idHasMorePages &&
    prevProps.subDrawerOpen === nextProps.subDrawerOpen &&
    prevProps.percentageProgress === nextProps.percentageProgress &&
    prevProps.instructions === nextProps.instructions &&
    prevProps.speak === nextProps.speak &&
    prevProps.localStorage === nextProps.localStorage &&
    prevProps.ProfileCompletionText === nextProps.ProfileCompletionText &&
    prevProps.ProfileCompletionTitle === nextProps.ProfileCompletionTitle
  );
};

const DrawerMessage = React.memo<DrawerMessageProps>(
  ({
    isIdCaptured,
    idHasMorePages,
    subDrawerOpen,
    percentageProgress,
    instructions,
    speak,
    localStorage,
    ProfileCompletionText,
    ProfileCompletionTitle,
  }) => {
    let title;
    let description;

    if (localStorage && isIdCaptured === false) {
      description = instructions.enrollment.errorOnIdCaptured;
      speak(
        instructions.enrollment.speakErrorOnIdCaptured,
        undefined,
        localStorage,
      );
    } else if (localStorage && isIdCaptured) {
      description = instructions.enrollment.successOnIdCaptured;
      speak(
        instructions.enrollment.speakSuccessOnIdCaptured,
        undefined,
        localStorage,
      );
    } else if (localStorage && idHasMorePages) {
      description = instructions.enrollment.errorOnIdQuantityPages;
      speak(
        instructions.enrollment.speakErrorOnIdQuantityPages,
        undefined,
        localStorage,
      );
    } else if (
      subDrawerOpen ||
      percentageProgress === 60 ||
      percentageProgress === 30
    ) {
      description = ProfileCompletionText.uncompleted;
    } else if (percentageProgress === 100 || percentageProgress === 70) {
      title = ProfileCompletionTitle.closed;
      description = ProfileCompletionText.closed;
    }

    return (
      <Box>
        <Typography textAlign="center" variant="body1" fontWeight="bold">
          {title}
        </Typography>
        <Typography
          mt={title ? 2 : 0}
          textAlign="center"
          variant="body2"
          dangerouslySetInnerHTML={{ __html: description || '' }}
        />
      </Box>
    );
  },
  areEqual,
);

export const MerchantContainer = () => {
  // Selectors
  const editingProfile = useAppSelector((state) => state.drawer.editingProfile);
  const subDrawerOpen = useAppSelector((state) => state.drawer.isSubDrawerOpen);
  const requestId = useAppSelector((state) => state.app.requestId);
  const drawerOpen = useAppSelector((state) => state.drawer.isDrawerOpen);
  const isClientPermissionOpen = useAppSelector(
    (state) => state.app.isClientPermissionOpen,
  );
  const initKeycloak = useAppSelector((state) => state.app.initKeycloak);
  const { isIdCaptured, idHasMorePages } = useAppSelector(
    (state) => state.user.userInfo,
  );
  const sessionToken = useAppSelector((state) => state.app.sessionToken);

  // Dispatch
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { pathname, search, state } = location;

  // State
  const [userInfo, setUserInfoForm] = useState(null);
  const [percentageProgress, setPercentageProgress] = useState(undefined);
  const [openSuccess, setOpenSuccess] = useState(false);

  // LocalStorage
  const localStorage = useLocalStorage();

  // Params
  const [searchParams] = useSearchParams();
  const redirectUri = searchParams.get('redirect_uri') || '';
  const clientId = searchParams.get('client_id') || '';
  const isBypassKC = searchParams.get('bypass_kc')
    ? searchParams.get('bypass_kc') === 'true'
    : false;

  window.onpopstate = () => {
    dispatch(setRecognized(false));
  };

  // Hooks
  const { getRequestId } = useRequestId();

  // Effects
  useEffect(() => {
    (async () => {
      if (localStorage) {
        const percentage = await getProfilePercentage(localStorage);
        if (state[0]?.percentage === percentage) {
          state[0]?.percentage && setPercentageProgress(state[0].percentage);
        } else {
          setPercentageProgress(percentage);
        }
      }
    })();
  }, [state, localStorage, requestId]);

  useEffect(() => {
    if (requestId && localStorage) {
      state &&
        state[0]?.percentage &&
        state[0]?.percentage < 100 &&
        getInfoUser(localStorage);
      dispatch(setLoginType(null));
    } else {
      dispatch(setIsLoading(true));
      getRequestId().finally(() => dispatch(setIsLoading(false)));
    }
  }, [requestId, localStorage]);

  useEffect(() => {
    if (localStorage && initKeycloak) {
      const oAuthId = localStorage.getItem('oAuthRequestId');
      const botVoiceEnabledItem = localStorage.getItem('botVoiceEnabled');
      const wssToken = getToken(localStorage);
      if (!!oAuthId && !!wssToken) {
        removeLocationState();
        !isBypassKC &&
          keycloakCallback(
            wssToken,
            redirectUri,
            oAuthId,
            clientId,
            localStorage,
            botVoiceEnabledItem,
          );
      }
    }
  }, [initKeycloak]);

  useEffect(() => {
    if (requestId && !isClientPermissionOpen) {
      if (percentageProgress === 60 || percentageProgress === 30) {
        dispatch(toggleEditingProfile());
        speak(ProfileCompletionText.uncompleted, null, localStorage);
      } else if (
        (percentageProgress === 100 || percentageProgress === 70) &&
        localStorage &&
        !isClientPermissionOpen &&
        !isBypassKC
      ) {
        !initKeycloak &&
          getClientPermissionStatus(requestId, dispatch, sessionToken).then(
            (response: any) => {
              if (typeof response !== 'string' && response.status === 200) {
                dispatch(setIsSubDrawerOpen(false));
                const oAuthId = localStorage.getItem('oAuthRequestId');
                const botVoiceEnabledItem =
                  localStorage.getItem('botVoiceEnabled');
                const wssToken = getToken(localStorage);
                if (!!oAuthId && !!wssToken) {
                  removeLocationState();
                  !isBypassKC &&
                    keycloakCallback(
                      wssToken,
                      redirectUri,
                      oAuthId,
                      clientId,
                      localStorage,
                      botVoiceEnabledItem,
                    );
                }
              }
            },
          );
      }
    }
  }, [percentageProgress, requestId, localStorage, isClientPermissionOpen]);

  useEffect(() => {
    if (
      localStorage &&
      (percentageProgress === 100 || percentageProgress === 70)
    ) {
      const wssToken = getToken(localStorage);
      const decodedUserInfo: WinkToken | null = wssToken
        ? jwtDecode(wssToken, { header: true })
        : null;
      if (isBypassKC) {
        window.location.assign(
          `${redirectUri}&winkTag=${decodedUserInfo?.WinkTag}`,
        );
      } else {
        dispatch(setShowMediaDeviceIcons(false));
        speak(
          "Welcome back, You've been successfully logged in.",
          null,
          localStorage,
        );
      }
    }
  }, [localStorage, percentageProgress]);

  // disable the possibilty to come back to /successPage route
  const removeLocationState = useCallback(() => {
    const updatedState = { ...state, isAllowed: false };
    window.history.replaceState(updatedState, '', `${pathname}${search}`);
  }, [pathname, search, state]);

  const getInfoUser = async (localStorage: Storage | null) => {
    if (requestId && localStorage) {
      try {
        const data = await getUser(
          requestId,
          localStorage,
          dispatch,
          sessionToken,
        );
        Object.keys(data).includes('profileCompletion') &&
          delete data.profileCompletion;
        Object.keys(data).includes('isFaceCompleted') &&
          delete data.isFaceCompleted;
        Object.keys(data).includes('isVoiceCompleted') &&
          delete data.isVoiceCompleted;
        if (data.dateOfBirth === '0001-01-01T00:00:00') {
          setUserInfoForm({
            ...data,
            dateOfBirth: null,
          });
          dispatch(setUserInfo({ ...data, dateOfBirth: null }));
        } else {
          setUserInfoForm({
            ...data,
            dateOfBirth: data.dateOfBirth?.split('T')[0],
          });
          dispatch(setUserInfo(data));
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getProfilePercentage = async (localStorage: Storage | null) => {
    if (requestId && localStorage && sessionToken) {
      try {
        const { data } = await getProfileCompletion(
          requestId,
          localStorage,
          dispatch,
          sessionToken,
        );
        setPercentageProgress(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleCloseSuccess = (
    event: React.SyntheticEvent<any> | Event,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const profileUpdatedSuccessfully = () => {
    dispatch(toggleEditingProfile());
    setOpenSuccess(true);
    setTimeout(() => {
      setOpenSuccess(false);
    }, 2500);
  };
  return (
    <>
      {percentageProgress && (
        <WinkDrawer
          disableSubDrawer={
            percentageProgress === 100 || percentageProgress === 70
          }
          headerComponent={
            <DrawerContainerStyled drawerOpen={drawerOpen}>
              <WinkToggle />
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100%"
                width="100%"
              >
                <DrawerMessage
                  isIdCaptured={isIdCaptured}
                  idHasMorePages={idHasMorePages}
                  subDrawerOpen={subDrawerOpen}
                  percentageProgress={percentageProgress}
                  instructions={instructions}
                  ProfileCompletionText={ProfileCompletionText}
                  ProfileCompletionTitle={ProfileCompletionTitle}
                  localStorage={localStorage}
                  speak={speak}
                />
              </Box>
            </DrawerContainerStyled>
          }
        >
          <Box
            py={1}
            className="no-style-scrollbar"
            style={{
              width: '100%',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            <ResumePanelMerchant
              percentage={percentageProgress}
              localStorage={localStorage}
            />
            <Collapse in={editingProfile}>
              <Box
                display="flex"
                flexDirection="column"
                gap="15px"
                padding="25px"
                onClick={(e) => e.stopPropagation()}
              >
                <Box>
                  <Typography variant="h5" fontWeight="bold" color="primary">
                    Profile
                  </Typography>
                </Box>
                <ProfileFormContainer
                  getProfilePercentage={getProfilePercentage}
                  percentage={percentageProgress}
                  // @ts-ignore correct typings
                  userInfo={userInfo}
                  onSave={() => {
                    profileUpdatedSuccessfully();
                  }}
                />
              </Box>
            </Collapse>
          </Box>
        </WinkDrawer>
      )}
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={() => setOpenSuccess(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Profile Updated Successfully!
        </Alert>
      </Snackbar>
      <AppPermission />
    </>
  );
};
