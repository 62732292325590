import axiosInstance from '../axiosInstance';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { getMerchantConfigurationUrl } from './MerchantApiService';

type DispatchType = ReturnType<typeof useAppDispatch>;
interface Options {
  clientId: string;
  localStorage: Storage;
  dispatch: DispatchType;
  sessionToken: string;
}

export const getMerchantConfiguration = async ({
  clientId,
  localStorage,
  dispatch,
  sessionToken,
}: Options) => {
  try {
    const url = getMerchantConfigurationUrl();
    const instance = axiosInstance(localStorage, dispatch, sessionToken);
    const response = await instance.get(url, {
      params: {
        clientId,
      },
    });
    return response.data;
  } catch (error) {
    console.debug(error);
  }
};
