import axiosInstance from '../axiosInstance';
import { AxiosError } from 'axios';

import { setIsClientPermissionOpen, setIsLoading } from 'app/slices/appSlice';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import {
  getPermissionStatusUrl,
  createUpdatePermissionUrl,
} from './UserClientPermissionService';

type DispatchType = ReturnType<typeof useAppDispatch>;

export async function getClientPermissionStatus(
  requestId: string,
  dispatch: DispatchType,
  sessionToken: string,
) {
  const url = getPermissionStatusUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  let data;
  try {
    data = await instance.get(url, {
      headers: {
        RequestId: requestId,
        Handle404Locally: 'true',
      },
    });
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 404) {
      dispatch(setIsLoading(false));
      dispatch(setIsClientPermissionOpen(true));
      return '404 user has no permission';
    }
    // If it's any other error, re-throw it so it's caught by the global errorHandler
    // throw axiosError;
    return error;
  }
}

export async function createUpdateClientPermission(
  body: any,
  requestId: string,
  dispatch: DispatchType,
  sessionToken: string,
) {
  const url = createUpdatePermissionUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const data = await instance.post(url, body, {
    headers: {
      RequestId: requestId,
    },
  });
  return data;
}
