import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ContainerProps, StyledImageProps } from './types';

const MobileContainer = styled('div')<ContainerProps>(
  ({
    theme,
    width,
    merchantConfiguration,
    isMerchantProps,
    isSessionTimeOut,
  }) => ({
    position: 'absolute',
    zIndex: !isSessionTimeOut ? 2000 : 1250,
    left: 0,
    right: 0,
    margin: 'auto',
    width,
    maxWidth: '95%',
    boxSizing: 'border-box',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 10px',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    backgroundColor: isMerchantProps
      ? merchantConfiguration?.buttonColor
      : 'rgba(87, 98, 163, 0.7)',
    opacity: 0.8,
    overflowX: 'hidden',
    borderRadius: 15,
    display: 'none',
    '&.entering, &.exiting, &.exited': {
      display: 'flex',
      transform: 'translateY(100%)',
    },
    '&.entered': {
      display: 'flex',
      transform: 'translateY(0)',
    },
    transition: 'transform 700ms ease-in-out',
  }),
);

const DesktopContainer = styled('div')<ContainerProps>(
  ({
    width,
    totalHeight,
    subDrawerOpen,
    merchantConfiguration,
    isMerchantProps,
  }) => ({
    position: 'relative',
    margin: 'auto',
    width,
    height: `${totalHeight}px`,
    transition: 'height 0.3s ease',
    boxSizing: 'border-box',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 10px',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
    backgroundImage: `linear-gradient(to bottom, ${
      isMerchantProps ? merchantConfiguration?.buttonColor : '#5762A3'
    } ${subDrawerOpen ? '65%' : '80%'}, #C2C3DE ${
      subDrawerOpen ? '35%' : '20%'
    })`,
    overflowX: 'hidden',
    borderRadius: 15,
    display: totalHeight && totalHeight < 60 ? 'none' : 'flex',
  }),
);

const StyledButton = styled(Button)({
  width: '80%',
  backgroundColor: '#1aa7b0',
  color: 'white',
});

const StyledImage = styled('img')<StyledImageProps>(
  ({
    visible = true,
    width,
    height,
    position = 'relative',
    top,
    right,
    left,
  }) => ({
    width,
    height,
    position,
    top,
    left,
    right,
    opacity: visible ? 1 : 0,
    maxHeight: visible ? '500px' : '0', // Asume que 500px es siempre mayor que la altura real.
    transition: visible
      ? `
        max-height 0s 0s, 
        opacity 0.3s 0s`
      : `
        opacity 0.3s 0s,
        max-height 0.3s 0.3s`,
  }),
);

const LivenessTipImage = styled('img')({
  maxWidth: '42px',
  width: '100%',
  height: 'auto',
  position: 'absolute',
  bottom: '45px',
  right: '0',
  animation: 'pulsate 2s infinite',
  transition: 'all 0.3s',
  cursor: 'pointer',
  WebkitAnimation: 'pulsate 2s infinite',
  MozAnimation: 'pulsate 2s infinite',
  OAnimation: 'pulsate 2s infinite',

  WebkitTransform: 'scale(0.9)',
  MozTransform: 'scale(0.9)',
  OTransform: 'scale(0.9)',
  transform: 'scale(0.9)',

  '@keyframes pulsate': {
    '0%': {
      WebkitTransform: 'scale(0.9)',
      MozTransform: 'scale(0.9)',
      OTransform: 'scale(0.9)',
      transform: 'scale(0.9)',
    },
    '50%': {
      WebkitTransform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      OTransform: 'scale(1.5)',
      transform: 'scale(1.5)',
    },
    '100%': {
      WebkitTransform: 'scale(0.9)',
      MozTransform: 'scale(0.9)',
      OTransform: 'scale(0.9)',
      transform: 'scale(0.9)',
    },
  },

  ':hover': {
    animationPlayState: 'paused',
    WebkitAnimationPlayState: 'paused',
    MozAnimationPlayState: 'paused',
    OAnimationPlayState: 'paused',
    transform: 'scale(1.5)',
  },
});

export {
  MobileContainer,
  DesktopContainer,
  StyledButton,
  LivenessTipImage,
  StyledImage,
};
