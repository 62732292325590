import React, { useCallback, useEffect } from 'react';

import { setProcessingMediaData } from 'app/slices/appSlice';
import {
  setIsSubDrawerOpen,
  setShowMediaDeviceIcons,
} from 'app/slices/drawerSlice';

import { useLocalStorage } from 'context/AppContext';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigateWithQuery } from 'hooks/useNavigateWithQuery';

import { initSynthesizer, restartSynthesizer, speak } from 'api/speech';
import { getProfileCompletion } from 'api/user';

import { instructions } from 'const/instructions';
import { getToken } from 'const/localStorage';

interface HookResult {
  handleActionTo(): void;
}

interface HookProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useSuccessSaveDataUser = ({
  setIsLoading,
}: HookProps): HookResult => {
  // LocalStorage
  const localStorage = useLocalStorage();
  const dispatch = useAppDispatch();
  const requestId = useAppSelector((state) => state.app.requestId);
  const sessionToken = useAppSelector((state) => state.app.sessionToken);
  const addQueryAndNavigate = useNavigateWithQuery();

  useEffect(() => {
    localStorage &&
      speak(
        `Hi ${instructions.login.loginAfterSaveDataProfile}`,
        null,
        localStorage,
      );
  }, [localStorage]);

  const handleActionTo = useCallback(async () => {
    try {
      if (requestId && localStorage && sessionToken) {
        setIsLoading(true);
        const { data } = await getProfileCompletion(
          requestId,
          localStorage,
          dispatch,
          sessionToken,
        );
        addQueryAndNavigate('/successPage', !!getToken(localStorage), {
          percentage: data,
        });
        dispatch(setShowMediaDeviceIcons(false));
        dispatch(setProcessingMediaData(false));
        dispatch(setIsSubDrawerOpen(false));
        restartSynthesizer();
        initSynthesizer();
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    addQueryAndNavigate,
    dispatch,
    requestId,
    setIsLoading,
    localStorage,
    sessionToken,
  ]);

  return {
    handleActionTo,
  };
};
