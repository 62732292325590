import { useEffect } from 'react';

import { useLocalStorage } from 'context/AppContext';

import { useAppSelector } from 'hooks/useAppSelector';
import { useMediaDevices } from 'hooks/useMediaDevices';

import { speak } from 'api/speech';

import { setGrantedPermissions } from 'const/localStorage';

const SERVICE_UNAVAILABLE = 'This service is not available in your device.';

interface HookResult {
  drawerOpen: boolean;
  headerText: string;
}

export const useFraudScore = (): HookResult => {
  const localStorage = useLocalStorage();

  const { toggleOffCameraAndMic } = useMediaDevices();
  const drawerOpen = useAppSelector(
    ({ drawer: { isDrawerOpen } }) => isDrawerOpen,
  );

  useEffect(() => {
    if (localStorage) {
      toggleOffCameraAndMic();
      setGrantedPermissions(false, localStorage);
      speak(SERVICE_UNAVAILABLE, null, localStorage);
    }
  }, [localStorage]);

  return {
    drawerOpen,
    headerText: SERVICE_UNAVAILABLE,
  };
};
