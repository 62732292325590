import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import { useAppSelector } from 'hooks/useAppSelector';

import { StyledButton } from 'components/button';

export const SessionTimeOut: React.FC = () => {
  const isSessionTimeOut = useAppSelector(
    (state) => state.app.isSessionTimeOut,
  );
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const isMerchantProps = useAppSelector((state) => state.app.isMerchantProps);

  const handleClick = () => {
    window.location.href = '/' + window.location.search;
  };
  return (
    <Dialog
      sx={{
        color: merchantConfiguration?.color,
        backgroundColor: isMerchantProps
          ? merchantConfiguration?.backgroundColor
          : '',
      }}
      open={isSessionTimeOut}
    >
      <DialogTitle>
        <Typography
          fontWeight={600}
          fontSize={16}
          fontFamily={merchantConfiguration?.font}
        >
          Session Expired!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            fontWeight={600}
            fontSize={14}
            fontFamily={merchantConfiguration?.font}
          >
            Your session has been expired, please click on return home button to
            restart your session.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <StyledButton onClick={handleClick}>Return Home </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
