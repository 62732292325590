import config from 'config/config.json';

export const keycloakCallback = async (
  token: string,
  keyCloakRedirectUri: string,
  oAuthId: string,
  clientId: string,
  localStorage: Storage,
  botVoiceEnabledItem: string | null,
) => {
  let endpoint: string;
  if (keyCloakRedirectUri && clientId) {
    endpoint = `${config.KC_CALLBACK_URL}?Authorization=${token}&oAUTH_Request_Id=${oAuthId}&redirect_uri=${keyCloakRedirectUri}&client_id=${clientId}`;
  } else if (keyCloakRedirectUri && !clientId) {
    endpoint = `${config.KC_CALLBACK_URL}?Authorization=${token}&oAUTH_Request_Id=${oAuthId}&redirect_uri=${keyCloakRedirectUri}`;
  } else if (!keyCloakRedirectUri && clientId) {
    endpoint = `${config.KC_CALLBACK_URL}?Authorization=${token}&oAUTH_Request_Id=${oAuthId}&client_id=${clientId}`;
  } else {
    endpoint = `${config.KC_CALLBACK_URL}?Authorization=${token}&oAUTH_Request_Id=${oAuthId}`;
  }
  localStorage.removeItem('oAuthRequestId');
  console.debug('redirect endpoint -->', endpoint);
  /** Wait the bot ends speaking */
  let waitTime = 2000;
  if (botVoiceEnabledItem !== null && botVoiceEnabledItem !== undefined) {
    waitTime = JSON.parse(botVoiceEnabledItem) ? 5000 : 2000;
  }
  setTimeout(() => {
    window.location.assign(endpoint);
  }, waitTime);
};
