import { useLocalStorage } from 'context/AppContext';

import { useAppSelector } from 'hooks/useAppSelector';

interface HookResult {
  route: '/enroll' | '/';
  localStorage: Storage | null;
}

export const useWrapperContainer = (): HookResult => {
  const route = useAppSelector(({ app: { route } }) => route);
  const localStorage = useLocalStorage();

  return {
    route,
    localStorage,
  };
};
