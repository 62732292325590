import React from 'react';
import { Navigate, NavigateProps, useLocation } from 'react-router-dom';

const NavigateWithQuery: React.FC<NavigateProps> = ({ to, ...props }) => {
  const { search } = useLocation();
  return <Navigate to={to + search} {...props} />;
};

// TODO there is a customHook 'useNavigateWithQuery' to do the same for navigate() function

export default NavigateWithQuery;
