import {
  SpeechSynthesizer,
  SpeakerAudioDestination,
  AudioConfig,
  SpeechConfig,
} from 'microsoft-cognitiveservices-speech-sdk';

import { getBrowser } from 'components/utils';

import configData from 'config/config.json';

const subscriptionKey = configData['MICROSOFT-COGNITIVE-SERVICES-SPEECH-KEY'];
const serviceRegion = 'centralus';

let isBotSpeaking = false;
let pendingCallbacks: any[] = []; // Store the callbacks with a field "ID" and a callback to execute. Example: [{id: "The text to speak is used as id", callback: () => {}}]
let synthesizer: SpeechSynthesizer | undefined,
  player: SpeakerAudioDestination | null,
  audioSpeakConfig: AudioConfig | null,
  speechConfig: SpeechConfig | null,
  timeOutId: ReturnType<typeof setTimeout> | undefined;

const resetConfig = () => {
  audioSpeakConfig = null;
  speechConfig = null;
};

const closeSynthesizer = () => {
  synthesizer?.close();
  synthesizer = undefined;
};

export const initSynthesizer = () => {
  try {
    speechConfig = SpeechConfig.fromSubscription(
      subscriptionKey,
      serviceRegion,
    );
    const browserName = getBrowser();
    // list of OutputFormatCodes https://learn.microsoft.com/en-us/javascript/api/microsoft-cognitiveservices-speech-sdk/speechsynthesisoutputformat?view=azure-node-latest
    if (browserName === 'Firefox') {
      speechConfig.speechSynthesisOutputFormat = 25;
    }
    player = new SpeakerAudioDestination();
    audioSpeakConfig = AudioConfig.fromSpeakerOutput(player);
    synthesizer = new SpeechSynthesizer(speechConfig, audioSpeakConfig);
  } catch (error) {
    console.error(error);
  }
};

const emptySynthesizer = () => {
  player?.pause();
  player = null;
  resetConfig();
  isBotSpeaking = false;
};

const executePendingCallbacks = () => {
  pendingCallbacks.forEach((callback) => callback?.callback()); // Execute every callback from the queue
  pendingCallbacks = []; // Empty the queue.
};

export async function speak(
  text: string,
  callback: any,
  localStorage: Storage | null,
) {
  let botVoiceEnabledItem;
  if (localStorage) {
    botVoiceEnabledItem = localStorage.getItem('botVoiceEnabled');
  }
  const botVoiceEnabled = botVoiceEnabledItem
    ? JSON.parse(botVoiceEnabledItem)
    : null;

  if (!botVoiceEnabled) {
    callback && callback(); // If bot is turned off we can just execute the callback and stop the function.
    return;
  }

  if (callback) pendingCallbacks.push({ callback, id: text });
  // Store the callbacks in a queue with the text-to-speak as an id to identify it later.

  if (isBotSpeaking) {
    // Since there is no stop function, if we call speak() and the bot is already speaking it will overlap the sound. We need to empty the previous synthetizer and initialize a new one.
    restartSynthesizer();
  }

  if (!synthesizer) {
    initSynthesizer();
  }

  try {
    isBotSpeaking = true;
    textToSpeech(text);
  } catch (error) {
    console.error(error);
  }
}

const textToSpeech = async (text: string) => {
  synthesizer?.speakTextAsync(
    text,
    (result) => {
      const audioDuration = result.audioDuration / 10000 - 1500;
      timeOutId = setTimeout(() => {
        isBotSpeaking = false;
        const callback = pendingCallbacks.find(
          (callback) => callback.id === text,
        ); // Find callback by id
        if (callback) {
          callback?.callback(); // Excecute it
          pendingCallbacks.splice(
            pendingCallbacks.findIndex((callback) => callback.id === text),
            1,
          ); // Remove it from queue
        }
      }, audioDuration);
      closeSynthesizer();
      resetConfig();
    },
    (error) => {
      console.error(error);
      closeSynthesizer();
      resetConfig();
      isBotSpeaking = false;
    },
  );
};

export const restartSynthesizer = () => {
  if (timeOutId) {
    clearTimeout(timeOutId);
    isBotSpeaking = false;
  }
  executePendingCallbacks(); // If we have pending callbacks and we toggle off the bot, we execute them all
  emptySynthesizer();
  closeSynthesizer();
};
