import React, { useState, useEffect } from 'react';

import { setRoute } from 'app/slices/appSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { initSynthesizer } from 'api/speech';

import { NotReturningUserContainer } from './NotReturningUser';
import { ReturningUserContainer } from './ReturningUserContainer';

const RedirectComponent = () => {
  const dispatch = useAppDispatch();
  dispatch(setRoute('/enroll'));
  return <></>;
};

interface Props {
  localStorage: Storage | null;
}

export const HomeContainer: React.FC<Props> = ({ localStorage }) => {
  const [allowedRetrieveData, setAllowRetrieveData] = useState<
    boolean | undefined
  >(undefined);
  const dispatch = useAppDispatch();

  const handleEnable = (e: React.MouseEvent) => {
    if (e) e.stopPropagation();
    // Init bot synthetizer because IOS need the user interaction to speak
    initSynthesizer();
    setAllowRetrieveData(true);
    dispatch(setRoute('/enroll'));
    localStorage?.setItem('allowedRetrieveData', 'true');
  };

  useEffect(() => {
    if (localStorage) {
      const allowedRetrieveDataItem = localStorage.getItem(
        'allowedRetrieveData',
      );
      const retrieveData = allowedRetrieveDataItem
        ? JSON.parse(allowedRetrieveDataItem)
        : null;
      setAllowRetrieveData(retrieveData);
    }
  }, [localStorage]);

  if (localStorage && allowedRetrieveData !== undefined) {
    if (!allowedRetrieveData)
      return <NotReturningUserContainer handleEnable={handleEnable} />;
    else if (allowedRetrieveData) return <ReturningUserContainer />;
    else return <RedirectComponent />;
  } else return <></>;
};
