import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { format } from 'date-fns';

import { setRecognized, setRoute } from 'app/slices/appSlice';
import {
  setIsSubDrawerOpen,
  setMediaRecording,
  setShowMediaDeviceIcons,
} from 'app/slices/drawerSlice';
import { resetUserInfo } from 'app/slices/userSlice';

import { useLocalStorage } from 'context/AppContext';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigateWithQuery } from 'hooks/useNavigateWithQuery';

import { initSynthesizer, restartSynthesizer } from 'api/speech';
import { getProfileCompletion, getSecretQuestions } from 'api/user';
import { verifyUserQuestions } from 'api/verify-user';

import { StyledButton } from 'components/button';
import { DrawerContainerStyled } from 'components/drawer/DrawerContainerStyled';
import { WinkToggle } from 'components/drawer/WinkToggle';
import { Loader } from 'components/loader/Loader';
import {
  isDateOlderThanToday,
  dateFormat,
  isOlderThan100Years,
  isValidDate,
} from 'components/utils';
import { WinkDrawer } from 'components/WinkDrawer';

import { instructions } from 'const/instructions';
import {
  getToken,
  getGrantedPermissions,
  setGrantedPermissions,
} from 'const/localStorage';

import { DateQuestion } from './DateQuestion';

export const SecurityQuestions: React.FC = () => {
  const [attempts, setAttempts] = useState(0);

  const drawerOpen = useAppSelector((state) => state.drawer.isDrawerOpen);
  const dispatch = useAppDispatch();
  const addQueryAndNavigate = useNavigateWithQuery();

  // LocalStorage
  const localStorage = useLocalStorage();

  // Params
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('client_id') || '';
  const hostUrl = searchParams.get('redirect_uri') || '';

  const handleRetry = (e: React.MouseEvent) => {
    e.stopPropagation();
    restartSynthesizer();
    initSynthesizer();
    dispatch(setRoute('/'));
    addQueryAndNavigate('/');
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (hostUrl) {
      window.location.assign(hostUrl);
    } else {
      dispatch(setMediaRecording(false));
      dispatch(setShowMediaDeviceIcons(false));
      // What to do here? login.default does not exists
      // dispatch(setDrawerMessage(instructions.login.default));
      dispatch(resetUserInfo());
      restartSynthesizer();
      initSynthesizer();
      // Temporarily store the value you want to keep
      const permissionsGranted = getGrantedPermissions(localStorage);
      const permissionValue = permissionsGranted
        ? JSON.parse(permissionsGranted)
        : null;
      localStorage?.clear();
      // Restore value
      if (permissionValue !== null && localStorage) {
        setGrantedPermissions(permissionValue, localStorage);
      }
      // Wrapper component need the 'route'
      dispatch(setRoute('/'));
      addQueryAndNavigate('/');
    }
  };

  return (
    <WinkDrawer
      headerComponent={
        <DrawerContainerStyled drawerOpen={drawerOpen}>
          {attempts === 0 ? (
            <WinkToggle
              headerText={
                attempts === 0 ? instructions.login.yellowCase : undefined
              }
            />
          ) : (
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              flexDirection="column"
              width="20%"
            >
              <img src="assets/alert-icon.svg" alt="Alert Icon" />
            </Box>
          )}
          {attempts > 0 && (
            <Box width="100%">
              <Box width="100%">
                <Typography
                  component="span"
                  textAlign="center"
                  variant="body1"
                  fontWeight="bold"
                >
                  {`Incorrect DOB. Attempt ${attempts} of 3 `}
                </Typography>
              </Box>
              <Box width="100%" paddingTop={1}>
                <Typography
                  sx={{ color: '#3E3E3E' }}
                  textAlign="center"
                  variant="body2"
                >
                  {attempts === 1 || attempts === 2
                    ? instructions.login.yellowCaseFailedText
                    : instructions.login.yellowCaseFinalText}
                </Typography>
              </Box>
              {attempts === 3 && (
                <Box width="100%" paddingTop={1}>
                  <StyledButton
                    size="small"
                    sx={{
                      alignSelf: 'center',
                      mr: 1.5,
                      backgroundColor: '#BBBBBB',
                      '&:hover': {
                        backgroundColor: '#ada8a8',
                      },
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    size="small"
                    sx={{
                      alignSelf: 'center',
                    }}
                    onClick={handleRetry}
                  >
                    Retry
                  </StyledButton>
                </Box>
              )}
            </Box>
          )}
        </DrawerContainerStyled>
      }
    >
      <SubDrawerContent
        attempts={attempts}
        setAttempts={setAttempts}
        clientId={clientId}
        localStorage={localStorage}
      />
    </WinkDrawer>
  );
};

interface SubDrawerContentProps {
  attempts: number;
  setAttempts: React.Dispatch<React.SetStateAction<number>>;
  clientId: string;
  localStorage: Storage | null;
}

const SubDrawerContent: React.FC<SubDrawerContentProps> = ({
  attempts,
  setAttempts,
  clientId,
  localStorage,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  // TODO extend the typing
  const [secretQuestions, setSecretQuestions] = useState<
    { userSecurityQuestionId: string; question: string }[]
  >([]);

  const dispatch = useAppDispatch();
  const addQueryAndNavigate = useNavigateWithQuery();

  const winkToken = useAppSelector((state) => state.app.winkToken);
  const requestId = useAppSelector((state) => state.app.requestId);
  const sessionToken = useAppSelector((state) => state.app.sessionToken);

  useEffect(() => {
    const getQuestions = async () => {
      try {
        if (requestId && localStorage) {
          const questions = await getSecretQuestions(
            requestId,
            localStorage,
            dispatch,
            sessionToken,
          );
          setSecretQuestions(questions.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    requestId &&
      localStorage &&
      secretQuestions?.length === 0 &&
      getQuestions();
  }, [requestId, localStorage]);

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (!birthDate) {
      setButtonEnabled(false);
      return;
    }

    const dateString = birthDate.toDateString();
    const yearString = birthDate.getFullYear().toString();

    // TODO fix validations, handle only as date type, omit custom validations, use date-fns
    if (
      yearString.length !== 4 ||
      !isValidDate(dateString) ||
      isDateOlderThanToday(birthDate) ||
      isOlderThan100Years(dateString)
    ) {
      setButtonEnabled(false);
    } else if (birthDate) setButtonEnabled(true);
  }, [birthDate]);

  const handleSubmit = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setButtonEnabled(false);
    setIsSubmitting(true);
    restartSynthesizer();
    initSynthesizer();
    try {
      if (birthDate && winkToken && requestId && localStorage && sessionToken) {
        const formattedDate = format(birthDate, dateFormat);
        const response = await verifyUserQuestions(
          formattedDate,
          winkToken,
          clientId,
          secretQuestions[0]?.userSecurityQuestionId,
          requestId,
          localStorage,
          dispatch,
          sessionToken,
        );
        dispatch(setRecognized(true));
        response.data?.token &&
          localStorage.setItem(`wssToken-${clientId}`, response.data.token);
        response.data?.oAuthRequestId &&
          localStorage.setItem('oAuthRequestId', response.data.oAuthRequestId);
        const { data } = await getProfileCompletion(
          requestId,
          localStorage,
          dispatch,
          sessionToken,
        );
        dispatch(setShowMediaDeviceIcons(true));
        addQueryAndNavigate('/successPage', !!getToken(localStorage), {
          percentage: data,
        });
        dispatch(setIsSubDrawerOpen(false));
      }
    } catch (error) {
      setAttempts(attempts + 1);
    }
    setButtonEnabled(true);
    setIsSubmitting(false);
  };

  const handleInput = (value: Date | null) => {
    setBirthDate(value);
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      p="24px"
      boxSizing="border-box"
    >
      <Typography
        mb={3.2}
        textAlign="center"
        fontWeight={600}
        fontSize={18}
        width="100%"
      >
        Verifying Wink Id
      </Typography>
      <Typography
        mb="10px"
        fontSize={18}
        fontWeight={600}
        letterSpacing="-0.55px"
      >
        Security Question:
      </Typography>
      <DateQuestion
        disabled={isSubmitting}
        title={secretQuestions[0]?.question}
        birthDate={birthDate}
        handleInput={handleInput}
      />
      <Box
        onClick={stopPropagation}
        display="flex"
        width="100%"
        justifyContent="center"
      >
        {!isSubmitting && (
          <StyledButton
            size="small"
            disabled={!buttonEnabled || attempts === 3}
            sx={{
              alignSelf: 'center',
              marginBottom: '20px',
              width: '100%',
              paddingY: '12px',
            }}
            onClick={handleSubmit}
          >
            Submit
          </StyledButton>
        )}
      </Box>
      {isSubmitting && <Loader />}
    </Box>
  );
};
