import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  userInfo: {
    firstName: string;
    lastName: string;
    contactNo: string;
    email: string;
    dateOfBirth: any;
    isIdVerified: boolean;
    isIdCaptured: boolean | undefined;
    idHasMorePages: boolean;
  };
  voice: null | boolean;
} = {
  userInfo: {
    firstName: '',
    lastName: '',
    contactNo: '',
    email: '',
    dateOfBirth: null,
    isIdVerified: false,
    isIdCaptured: undefined,
    idHasMorePages: false,
  },
  voice: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = {
        ...action.payload,
        dateOfBirth: action.payload.dateOfBirth?.split('T')[0],
      }; /* the .split() is because BE sends date with hour (should change) */
    },
    resetUserInfo: (state) => {
      state.userInfo = { ...initialState.userInfo };
    },
    setVoice: (state, action) => {
      switch (action.payload) {
        case '0':
          // no voice in DB or voice enrollment fail
          state.voice = false;
          break;
        case '1':
          // In enrollment we are not checking voice liveness
          state.voice = null;
          break;
        case '2':
          // voice enrollment successful + voice livness = true
          state.voice = true;
          break;
      }
    },
    setIsIdVerified: (state, action) => {
      state.userInfo = { ...state.userInfo, isIdVerified: action.payload };
    },
    setIdCaptured: (state, action) => {
      state.userInfo = { ...state.userInfo, isIdCaptured: action.payload };
    },
    setIdHasMorePages: (state, action) => {
      state.userInfo = { ...state.userInfo, idHasMorePages: action.payload };
    },
  },
});

export const {
  setUserInfo,
  resetUserInfo,
  setVoice,
  setIsIdVerified,
  setIdCaptured,
  setIdHasMorePages,
} = userSlice.actions;
export default userSlice.reducer;
