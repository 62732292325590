import React from 'react';

import { DrawerContainerStyled } from 'components/drawer/DrawerContainerStyled';
import { WinkToggle } from 'components/drawer/WinkToggle';
import { WinkDrawer } from 'components/WinkDrawer';

import { useFraudScore } from '../../hooks/useFraudScore';

export const FraudScore: React.FC = () => {
  const { drawerOpen, headerText } = useFraudScore();

  return (
    <>
      <WinkDrawer
        headerComponent={
          <DrawerContainerStyled drawerOpen={drawerOpen}>
            <WinkToggle headerText={headerText} />
          </DrawerContainerStyled>
        }
      />
    </>
  );
};
