import { styled } from '@mui/material/styles';

interface CameraSnapshotProps {
  idCaptureOpen?: boolean;
  isMobile?: boolean;
}

const CameraSnapshotStyled = styled('div')<CameraSnapshotProps>(
  ({ idCaptureOpen, isMobile }) => ({
    display: `${idCaptureOpen ? 'flex' : 'none'}`,
    position: `${idCaptureOpen && !isMobile ? 'fixed' : 'absolute'}`,
    height: '100%',
    width: `${isMobile ? '100%' : '80%'}`,
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(10px)',
    overflow: 'auto',
  }),
);

export { CameraSnapshotStyled };
