import React, { useEffect, useState } from 'react';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MicIcon from '@mui/icons-material/Mic';
import { Box, Grid, IconButton } from '@mui/material';

import { useLocalStorage } from 'context/AppContext';

import { useAppSelector } from 'hooks/useAppSelector';
import { useMediaDevices } from 'hooks/useMediaDevices';

import { getGrantedPermissions } from 'const/localStorage';

interface Props {
  isiOS?: boolean;
}

const MediaDeviceIcons: React.FC<Props> = ({ isiOS = false }) => {
  const [hasPermissions, setHasPermissions] = useState(false);
  const localStorage = useLocalStorage();

  const { toggleOffCamera, toggleOnCamera, toggleOffMic, toggleOnMic } =
    useMediaDevices();

  const cameraEnabled = useAppSelector((state) => state.app.cameraEnabled);
  const mediaRecording = useAppSelector((state) => state.drawer.mediaRecording);
  const microphoneEnabled = useAppSelector((state) => state.app.micEnabled);
  const showMediaDeviceIcons = useAppSelector(
    (state) => state.drawer.showMediaDeviceIcons,
  );
  const deniedPermissions = useAppSelector(
    (state) => state.app.deniedPermissions,
  );
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const isMerchantProps = useAppSelector((state) => state.app.isMerchantProps);
  const classes = {
    mediaContainer: {
      height: '80%',
      marginRight: '15px',
      marginTop: '15px',
    },
    IconButton: {
      backgroundColor: isMerchantProps
        ? merchantConfiguration.color
        : '#5762A3',
      '&:hover': {
        backgroundColor: '#7181de',
      },
    },
    IconButtonDisabled: {
      backgroundColor: isMerchantProps
        ? merchantConfiguration.color
        : '#5762a35c',
    },
  };
  useEffect(() => {
    const permissionsGranted = getGrantedPermissions(localStorage);
    if (permissionsGranted) {
      setHasPermissions(JSON.parse(permissionsGranted));
    } else {
      setHasPermissions(false);
    }
  }, [localStorage]);

  useEffect(() => {
    setHasPermissions(!deniedPermissions);
  }, [deniedPermissions]);

  return showMediaDeviceIcons && hasPermissions ? (
    <Grid
      item
      xs={2.5}
      marginRight="-50px"
      borderLeft="3px solid #fff"
      height="100%"
      pl={1.5}
    >
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection="column"
        style={classes.mediaContainer}
        visibility="hidden"
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (!mediaRecording && !isiOS) {
              microphoneEnabled ? toggleOffMic(e) : toggleOnMic(e);
            }
          }}
          style={
            !isiOS && !mediaRecording
              ? classes.IconButton
              : classes.IconButtonDisabled
          }
        >
          <MicIcon sx={{ color: '#fff' }} />
          <div
            style={
              !microphoneEnabled
                ? {
                    position: 'absolute',
                    backgroundColor: 'red',
                    transform: 'rotate(30deg)',
                    height: 38,
                    width: 2,
                  }
                : undefined
            }
          ></div>
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (!mediaRecording) {
              cameraEnabled ? toggleOffCamera(e) : toggleOnCamera(e);
            }
          }}
          style={
            !mediaRecording ? classes.IconButton : classes.IconButtonDisabled
          }
        >
          <CameraAltIcon sx={{ color: '#fff' }} />
          <div
            style={
              !cameraEnabled
                ? {
                    position: 'absolute',
                    backgroundColor: 'red',
                    transform: 'rotate(30deg)',
                    height: 38,
                    width: 2,
                  }
                : undefined
            }
          ></div>
        </IconButton>
      </Box>
    </Grid>
  ) : null;
};

export default MediaDeviceIcons;
