import axiosInstance from '../axiosInstance';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { getVerifyDeviceUrl, getIsSessionActive } from './DeviceApiService';

type DispatchType = ReturnType<typeof useAppDispatch>;
interface Options {
  requestId?: string;
  clientId?: string;
  winkSeed?: string;
  winkTag?: string;
  localStorage: Storage;
  dispatch: DispatchType;
  sessionToken: string;
}

export const verifyDevice = async ({
  requestId,
  clientId,
  winkSeed,
  winkTag,
  localStorage,
  dispatch,
  sessionToken,
}: Options) => {
  const url = getVerifyDeviceUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const response = await instance.post(
    url,
    {},
    {
      params: {
        RequestId: requestId,
        ClientId: clientId,
        Winkseed: winkSeed,
        WinkTag: winkTag,
      },
    },
  );
  return response;
};

export const isSessionActive = async ({
  localStorage,
  dispatch,
  sessionToken,
}: Options) => {
  try {
    const url = getIsSessionActive();
    const instance = axiosInstance(localStorage, dispatch, sessionToken);
    const response = await instance.get(url, {
      params: {
        SessionId: sessionToken,
      },
    });
    return response;
  } catch (error: any) {
    console.debug(error);
    return false;
  }
};
