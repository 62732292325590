import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Title = styled(Typography)`
  color: #424347;
  text-align: center;
  font-size: 50px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.55px;
`;

const Text = styled(Typography)`
  color: #272727;
  text-align: center;
  font-size: 24px;
  font-family: 'Source Sans 3', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
`;

const ErrorImg = styled('img')`
  max-width: 182px;
  width: 100%;
  height: auto;
`;

export { Title, ErrorImg, Text };
