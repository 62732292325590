/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { Box } from '@mui/system';

import { isMobile } from 'wink-lib';

import { setLivenessTipsAccepted } from 'app/slices/appSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import { DrawerContainerStyled } from 'components/drawer/DrawerContainerStyled';
import MediaDeviceIcons from 'components/drawer/MediaDeviceIcons';
import { WinkToggle } from 'components/drawer/WinkToggle';
import { LivenessTipsContainer } from 'components/liveness/tips/LivenessTipsContainer';
import { WinkDrawer } from 'components/WinkDrawer';

import { EnrollmentComponents } from './EnrollmentComponents';
import { HeaderMessages } from './HeaderMessages';

interface Props {
  localStorage: Storage | null;
}

export const EnrollmentContainer: React.FC<Props> = ({ localStorage }) => {
  const [symbioCode, setSymbioCode] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isYellowCode, setIsYellowCode] = useState<boolean>(false);
  const [wsErrorMessage, setWsErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [yellowRetryPressed, setYellowRetryPressed] = useState<boolean>(false);
  const drawerOpen = useAppSelector((state) => state.drawer.isDrawerOpen);
  const livenessError = useAppSelector((state) => state.app.livenessError);
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );

  const [showInstructions, setShowInstructions] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const goToNextStep = () => {
    setCurrentStep((currentStep) => currentStep + 1);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            margin: 'auto',
            '@media (max-width: 400px)': {
              width: '91vw',
            },
            display:
              (isMobile && showInstructions) || livenessError
                ? 'block'
                : 'none',
            fontFamily: merchantConfiguration?.font,
          }}
        >
          <LivenessTipsContainer
            handleContinue={() => dispatch(setLivenessTipsAccepted(true))}
            livenessError={livenessError}
            showInstructions={showInstructions}
            setShowInstructions={setShowInstructions}
            width="95vw"
          />
        </Box>

        <WinkDrawer
          disableSubDrawer={isYellowCode}
          headerComponent={
            <DrawerContainerStyled drawerOpen={drawerOpen}>
              {!isYellowCode && <WinkToggle />}
              <Box width="100%" padding="10px">
                <HeaderMessages
                  symbioCode={symbioCode}
                  currentStep={currentStep}
                  setSymbioCode={setSymbioCode}
                  setYellowRetryPressed={setYellowRetryPressed}
                  merchantConfiguration={merchantConfiguration}
                  wsErrorMessage={wsErrorMessage}
                  setWsErrorMessage={setWsErrorMessage}
                />
              </Box>
              <MediaDeviceIcons />
            </DrawerContainerStyled>
          }
        >
          <EnrollmentComponents
            localStorage={localStorage}
            symbioCode={symbioCode}
            setSymbioCode={setSymbioCode}
            goToNextStep={goToNextStep}
            currentStep={currentStep}
            setShowInstructions={setShowInstructions}
            showInstructions={showInstructions}
            setIsYellowCode={setIsYellowCode}
            isYellowCode={isYellowCode}
            setYellowRetryPressed={setYellowRetryPressed}
            yellowRetryPressed={yellowRetryPressed}
            setWsErrorMessage={setWsErrorMessage}
          />
        </WinkDrawer>
      </div>
    </>
  );
};
