import { Button, DialogContent, TableCell, styled } from '@mui/material';

export const StyledDialogContent = styled(DialogContent)(() => ({
  backgroundColor: '#f4f3fe',
  textAlign: 'center',
  color: 'black',
}));
export const TableCellLeft = styled(TableCell)(() => ({
  color: 'black',
  padding: '0px 10px',
  fontSize: '14px',
}));
export const CancelButton = styled(Button)(() => ({
  textTransform: 'none',
  color: '#1aa7b0',
  borderColor: '#1aa7b0',
  fontWeight: 600,
  borderWidth: 2,
  fontSize: 12,
  marginTop: 10,
  ':hover': {
    borderWidth: 2,
  },
}));
export const AgreeButton = styled(Button)(() => ({
  textTransform: 'none',
  backgroundColor: '#1aa7b0',
  fontWeight: 600,
  fontSize: 12,
  marginTop: 10,
}));
