import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { setEnrollErrorCounter, setRoute } from 'app/slices/appSlice';
import {
  setIsSubDrawerOpen,
  setShowMediaDeviceIcons,
} from 'app/slices/drawerSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigateWithQuery } from 'hooks/useNavigateWithQuery';

import { initSynthesizer, restartSynthesizer } from 'api/speech';
import { getProfileCompletion } from 'api/user';

import { ButtonLink } from 'components/button';
import { LivenessDetection } from 'components/liveness/LivenessDetection';
import { Loader } from 'components/loader/Loader';

import { getToken } from 'const/localStorage';
import {
  ErrorCode,
  ExistingAccountCode,
  EnrollMaxErrors,
  IncompleteEnrollment,
  VoiceLivenessError,
} from 'const/wsTypeMessages';

import { Profile } from './Profile';
import { SuccessSaveDataUser } from './SuccessSaveDataUser';

const styles = {
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 40,
  },
};

interface Props {
  symbioCode: string | null;
  goToNextStep: () => void;
  currentStep: number;
  setSymbioCode: Dispatch<SetStateAction<string | null>>;
  setIsYellowCode: Dispatch<SetStateAction<boolean>>;
  isYellowCode: boolean;
  localStorage: Storage | null;
  showInstructions: boolean;
  setShowInstructions: Dispatch<SetStateAction<boolean>>;
  setYellowRetryPressed: Dispatch<SetStateAction<boolean>>;
  yellowRetryPressed: boolean;
  setWsErrorMessage: Dispatch<SetStateAction<string | undefined>>;
}

export const EnrollmentComponents: React.FC<Props> = ({
  symbioCode,
  setSymbioCode,
  setIsYellowCode,
  isYellowCode,
  goToNextStep,
  currentStep,
  localStorage,
  showInstructions,
  setShowInstructions,
  setYellowRetryPressed,
  yellowRetryPressed,
  setWsErrorMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const addQueryAndNavigate = useNavigateWithQuery();

  const requestId = useAppSelector((state: any) => state.app.requestId);
  const sessionToken = useAppSelector((state) => state.app.sessionToken);

  const ENROLLMENT_STEPS = [
    // The enrollment consist in 3 screens: Liveness, Complete basic info and account created successfully.
    <LivenessDetection
      showInstructions={showInstructions}
      setShowInstructions={setShowInstructions}
      onSuccess={() => goToNextStep()}
      setSymbioCode={setSymbioCode}
      setIsYellowCode={setIsYellowCode}
      setWsErrorMessage={setWsErrorMessage}
      isYellowCode={isYellowCode}
      symbioCode={symbioCode}
      key={0}
      setYellowRetryPressed={setYellowRetryPressed}
      yellowRetryPressed={yellowRetryPressed}
    />,
    <Profile
      onSave={() => {
        setSymbioCode(null);
        goToNextStep();
      }}
      key={1}
    />,
    <SuccessSaveDataUser key={2} />,
  ];

  const successLogin = useCallback(async () => {
    try {
      if (requestId && localStorage && sessionToken) {
        const { data } = await getProfileCompletion(
          requestId,
          localStorage,
          dispatch,
          sessionToken,
        );
        addQueryAndNavigate('/successPage', !!getToken(localStorage), {
          percentage: data,
        });
        dispatch(setShowMediaDeviceIcons(false));
        dispatch(setIsSubDrawerOpen(false));
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [addQueryAndNavigate, dispatch, requestId, localStorage, sessionToken]);

  const retryRecognition = (e: React.MouseEvent) => {
    e?.stopPropagation();
    setWsErrorMessage(undefined);
    setSymbioCode(null);
    // Init bot synthetizer because IOS need the user interaction to speak
    restartSynthesizer();
    initSynthesizer();
  };

  // we render different components if we receive a code such as Error or Existing Account, if not we continue with the enrollment steps.
  switch (symbioCode) {
    case EnrollMaxErrors:
      return (
        <div style={styles.buttonWrapper as React.CSSProperties}>
          <ButtonLink
            actionTo={() => {
              dispatch(
                setEnrollErrorCounter({
                  attempt: 0,
                  status: 'resting',
                }),
              );
              setWsErrorMessage(undefined);
              setSymbioCode(null);
              // Init bot synthetizer because IOS need the user interaction to speak
              restartSynthesizer();
              initSynthesizer();
            }}
            fullWidth
            title="Start again"
          />
        </div>
      );
    case ErrorCode:
    case VoiceLivenessError:
      return (
        <div style={styles.buttonWrapper as React.CSSProperties}>
          <ButtonLink actionTo={retryRecognition} fullWidth title="Retry" />
          <ButtonLink
            actionTo={() => {
              setWsErrorMessage(undefined);
              dispatch(setRoute('/'));
            }}
            fullWidth
            title="Cancel"
          />
        </div>
      );
    case ExistingAccountCode:
      return (
        <>
          {isLoading ? (
            <Box display="flex">
              <Loader />
            </Box>
          ) : (
            <div style={styles.buttonWrapper as React.CSSProperties}>
              <ButtonLink
                actionTo={() => {
                  setIsLoading(true);
                  setWsErrorMessage(undefined);
                  restartSynthesizer();
                  initSynthesizer();
                  successLogin();
                }}
                fullWidth
                title="Login Now"
              />
            </div>
          )}
        </>
      );
    case IncompleteEnrollment:
      return (
        <div style={styles.buttonWrapper as React.CSSProperties}>
          <Typography>Please complete enrollment in origin device</Typography>
          <ButtonLink
            actionTo={() => {
              setWsErrorMessage(undefined);
              dispatch(setRoute('/'));
            }}
            fullWidth
            title="Cancel"
          />
        </div>
      );
    default:
      return ENROLLMENT_STEPS[currentStep];
  }
};
