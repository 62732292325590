import React, { useEffect, useState } from 'react';

import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';

import { useAppSelector } from 'hooks/useAppSelector';

import { DesktopContainer, StyledImage } from './styles';
import { ComponentProps } from './types';

export const TipsDesktopComponent: React.FC<ComponentProps> = ({
  width = '95vw',
}) => {
  const [totalHeight, setTotalHeight] = useState(0);

  const theme = useTheme();

  const isBreakpointUp800 = useMediaQuery(theme.breakpoints.up(800));
  const isBreakpointDown1200 = useMediaQuery(theme.breakpoints.down(1200));
  const isBreakpointDown1470 = useMediaQuery(theme.breakpoints.down(1470));
  const isBreakpointUp1740 = useMediaQuery(theme.breakpoints.up(1740));

  const livenessError = useAppSelector((state) => state.app.livenessError);
  const subDrawerOpen = useAppSelector((state) => state.drawer.isSubDrawerOpen);
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const isMerchantProps = useAppSelector((state) => state.app.isMerchantProps);
  useEffect(() => {
    const calculateHeight = () => {
      const componente1 = document.getElementById('drawer');
      const componente2 = document.getElementById('subdrawer');

      if (componente1 && componente2) {
        const height1 = componente1.clientHeight;
        const height2 = componente2.clientHeight;
        setTotalHeight(height1 + height2 + 10);
      }
    };
    const image = document.getElementById(
      'subdrawerLivenessTipImage',
    ) as HTMLImageElement;

    if (image) {
      if (image.complete) {
        calculateHeight();
      } else {
        image.addEventListener('load', calculateHeight);
      }
    }

    const observer = new MutationObserver(calculateHeight);

    const config = { attributes: true, childList: true, subtree: true };

    const componente1 = document.getElementById('drawer');
    const componente2 = document.getElementById('subdrawer');
    if (componente1) observer.observe(componente1, config);
    if (componente2) observer.observe(componente2, config);

    return () => {
      observer.disconnect();
      if (image) {
        image.removeEventListener('load', calculateHeight);
      }
    };
  }, [livenessError]);

  return (
    <DesktopContainer
      width={width}
      totalHeight={totalHeight}
      subDrawerOpen={subDrawerOpen}
      merchantConfiguration={merchantConfiguration}
      isMerchantProps={isMerchantProps}
    >
      <Grid
        container
        display={isBreakpointUp800 ? 'flex' : 'none'}
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        padding={4}
      >
        <Grid
          container
          item
          xs={4}
          justifyContent={isBreakpointDown1470 ? 'flex-start' : 'center'}
          display="flex"
          alignItems={
            !subDrawerOpen && isBreakpointDown1200 ? 'flex-start' : 'center'
          }
          position="relative"
          top={isBreakpointDown1200 && subDrawerOpen ? -40 : 0}
        >
          <Grid
            container
            item
            xs={6}
            position="relative"
            display={!subDrawerOpen && isBreakpointDown1200 ? 'none' : 'flex'}
            justifyContent="flex-start"
            marginBottom={isBreakpointDown1200 ? 2 : 0}
          >
            <StyledImage
              src="../../../assets/sun.png"
              width={163}
              height={120}
              visible={subDrawerOpen || !isBreakpointDown1200}
            />
            <StyledImage
              src="../../../assets/wink-phototips_covered.png"
              width={177}
              height={188}
              position="absolute"
              top={-20}
              right={
                isBreakpointDown1200
                  ? -50
                  : isBreakpointDown1470
                  ? -20
                  : isBreakpointUp1740
                  ? 40
                  : 0
              }
              visible={subDrawerOpen || !isBreakpointDown1200}
            />
          </Grid>

          <Grid item xs={isBreakpointDown1200 ? 7 : 4} textAlign="left">
            <Typography
              fontSize={18}
              fontWeight={600}
              color="white"
              component="span"
            >
              Remove Face Coverings
              <Typography fontSize={14} fontWeight={400} color="white">
                Take off sunglasses, masks, and move any hair covering your face
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={4}
          display="flex"
          alignItems={
            !subDrawerOpen && isBreakpointDown1200 ? 'flex-start' : 'center'
          }
          justifyContent="flex-end"
        >
          <Grid
            item
            xs={isBreakpointDown1200 ? 7 : 4}
            textAlign="right"
            position="relative"
          >
            <Typography
              fontSize={18}
              fontWeight={600}
              color="white"
              component="span"
            >
              Avoid Poor Lighting
              <Typography fontSize={14} fontWeight={400} color="white">
                Back-lit and low-light settings are less ideal
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            display={!subDrawerOpen && isBreakpointDown1200 ? 'none' : 'flex'}
            justifyContent="flex-start"
            position="relative"
            marginBottom={!subDrawerOpen && isBreakpointDown1200 ? 2 : 0}
            top={!subDrawerOpen && isBreakpointDown1200 ? -30 : 0}
            left={!subDrawerOpen && isBreakpointDown1200 ? -50 : 0}
          >
            <StyledImage
              src="../../../assets/cloud.png"
              height={130}
              left={5}
              visible={!isBreakpointDown1200}
            />
            <StyledImage
              src="../../../assets/wink-phototips-dark.png"
              width={177}
              height={188}
              position={!isBreakpointDown1200 ? 'absolute' : 'relative'}
              top={-10}
              left={isBreakpointDown1200 ? -20 : 0}
              visible={subDrawerOpen || !isBreakpointDown1200}
            />
          </Grid>
        </Grid>
      </Grid>
    </DesktopContainer>
  );
};
