import React, { useState, useEffect, useRef, useCallback } from 'react';

import { select, arc, pie, Selection, Pie, DefaultArcObject, Arc } from 'd3';

import { useDeviceOrientation } from 'hooks/useDeviceOrientation';

import configData from 'config/config.json';

import 'css/imageProgress.css';

import { TextToRead } from './TextToRead';

const IMAGES = {
  resting: 'assets/wink-semicolon--resting.svg',
  success: 'assets/wink-semicolon--success.svg',
  positionError: 'assets/wink-semicolon--position-error.svg',
  error: 'assets/wink-semicolon--error.svg',
  cutout: 'assets/face-cutout.svg',
};

interface RenderChartProps {
  value: number[];
  arcChart: React.MutableRefObject<Arc<any, DefaultArcObject>>;
  circle: React.MutableRefObject<
    Selection<SVGGElement | null, unknown, null, undefined> | undefined
  >;
  pieChart: React.MutableRefObject<Pie<any, number | { valueOf(): number }>>;
  arcData: React.MutableRefObject<number[]>;
  error: boolean;
  success?: boolean;
  userPositionError?: boolean;
}
interface ImageProgressProps {
  progress: number;
  text?: string;
  textError?: string;
  defaultText?: string;
  defaultError?: boolean;
  defaultSuccess?: boolean;
  userPositionError?: boolean;
  success?: boolean;
  children?: React.ReactNode;
  textToRead?: string | null;
}

const renderChart = ({
  value,
  arcChart,
  circle,
  pieChart,
  arcData,
  error,
  userPositionError,
  success,
}: RenderChartProps) => {
  if (!circle.current) {
    return;
  }

  arcChart.current
    .innerRadius(225)
    .outerRadius(218)
    .padAngle(0.1)
    .cornerRadius(10);

  const data = pieChart.current(arcData.current);
  const arcs = circle.current.selectAll<SVGGElement, any>('.arc').data(data);

  const newArcs = arcs.enter().append('g').attr('class', 'arc');

  newArcs
    .append('image')
    .attr('x', -10)
    .attr('y', -10)
    .attr('height', 20)
    .attr('width', 20);

  arcs.merge(newArcs).each(function (d, i) {
    const angleRad = (d.startAngle + d.endAngle) / 2 - Math.PI / 2;
    const transform = `translate(${218 * Math.cos(angleRad)}, ${
      218 * Math.sin(angleRad)
    }) rotate(${((d.startAngle + d.endAngle) / 2) * (180 / Math.PI)})`;

    let imageHref = IMAGES.resting;
    if (error && value.includes(i)) {
      imageHref = IMAGES.error;
    } else if (value.includes(i)) {
      imageHref = success ? IMAGES.success : IMAGES.resting;
    }

    const imageElement = select(this).select('image');
    const currentHref = imageElement.attr('xlink:href');

    if (currentHref !== imageHref) {
      imageElement.attr('xlink:href', imageHref).attr('transform', transform);
    }
  });

  arcs.exit().remove();
};

export const ImageProgress: React.FC<ImageProgressProps> = ({
  progress,
  children,
  defaultError,
  defaultSuccess,
  defaultText,
  success,
  text,
  textError,
  textToRead,
  userPositionError = false,
}) => {
  const [value, setValue] = useState<number[]>([]);
  const [error, setError] = useState(true);
  const [min] = useState(0);
  const [max] = useState(80);
  const [init, setInit] = useState(0);
  const svg =
    useRef<Selection<SVGSVGElement | null, unknown, null, undefined>>();
  const g = useRef<Selection<SVGSVGElement | null, unknown, null, undefined>>();
  const container =
    useRef<Selection<HTMLDivElement | null, unknown, null, undefined>>();
  const arcChart = useRef<Arc<any, DefaultArcObject>>(arc());
  const pieChart = useRef<Pie<any, number | { valueOf(): number }>>(pie());
  const arcData = useRef<number[]>([]);
  const circle =
    useRef<Selection<SVGGElement | null, unknown, null, undefined>>();
  const foreignObject =
    useRef<
      Selection<SVGForeignObjectElement | null, unknown, null, undefined>
    >();
  const selectedRef = useRef<HTMLDivElement | null>(null);
  const selectedSvg = useRef<SVGSVGElement | null>(null);
  const selectedG = useRef<SVGSVGElement | null>(null);
  const selectedCircle = useRef<SVGGElement | null>(null);
  const selectedForeignObject = useRef<SVGForeignObjectElement | null>(null);

  // Timer
  const { ENROLLMENT_TIME } = configData;

  // Device orientation
  const { isLandscape } = useDeviceOrientation();

  const initChart = useCallback(() => {
    svg.current?.attr('xmlns', 'http://www.w3.org/2000/svg');
    svg.current?.attr('width', '100%');
    svg.current?.attr('viewBox', '0 0 500 500');

    const divDimensions = svg.current?.node()?.getBoundingClientRect();
    const divWidth = divDimensions?.width;
    const divHeight = divDimensions?.height;

    g.current?.style('transform', 'translate(50%, 50%)');

    const foreignObjectCurrent = foreignObject.current;

    if (foreignObjectCurrent) {
      if (divHeight && divWidth) {
        foreignObjectCurrent.attr('width', divWidth);
        foreignObjectCurrent.attr('height', divHeight);
        foreignObjectCurrent.attr('x', -divWidth / 2);
        foreignObjectCurrent.attr('y', -divWidth / 2);
      }

      foreignObjectCurrent.style('overflow', 'visible');
      foreignObjectCurrent.style('width', '100%');
      foreignObjectCurrent.style('height', '100%');
      foreignObjectCurrent.style('x', '-50%');
      foreignObjectCurrent.style('y', '-50%');
    }

    arcData.current = [...Array(80)].map((e, i) => 1);
    renderChart({
      value,
      arcChart,
      circle,
      pieChart,
      arcData,
      error,
      success,
      userPositionError,
    });
  }, [
    progress,
    children,
    defaultError,
    defaultSuccess,
    defaultText,
    success,
    text,
    textError,
  ]);

  useEffect(() => {
    container.current = select(selectedRef.current);
    svg.current = select(selectedSvg.current);
    g.current = select(selectedG.current);
    circle.current = select(selectedCircle.current);
    foreignObject.current = select(selectedForeignObject.current);
    initChart();
  }, [initChart]);

  useEffect(() => {
    let timer = 40;
    if ((!defaultSuccess || !defaultError) && progress > init) {
      timer = ENROLLMENT_TIME * 10 - 5;
    }
    const time = setInterval(() => {
      if (!defaultSuccess || !defaultError) {
        if (progress < init) {
          setError(true);
          setInit((prevInit) => {
            const newInit = Math.max(min, prevInit - 1);
            setValue((prevValues) => {
              if (newInit === 0 && progress === 0) {
                return [];
              } else {
                return prevValues.slice(0, newInit + 1);
              }
            });
            return newInit;
          });
        } else if (progress > init || init + 1 < progress) {
          setError(false);
          setInit((prevInit) => {
            const newInit = Math.min(max, prevInit + 1);
            setValue((prevValues) => {
              const nextValue = prevInit;
              if (prevValues.indexOf(nextValue) === -1) {
                const newValues = [...prevValues, nextValue].sort(
                  (a, b) => a - b,
                );
                return newValues;
              }
              return prevValues;
            });
            return newInit;
          });
        }
        renderChart({
          value,
          arcChart,
          circle,
          pieChart,
          arcData,
          error,
          success,
          userPositionError,
        });
      }
    }, timer);

    if (defaultSuccess || defaultError) {
      if (defaultError) {
        setError(true);
      } else {
        setError(false);
      }
      setInit(100);
      const newValue: number[] = [];
      for (let i = 0; i <= 100; i++) {
        newValue.push(i);
      }
      setValue(newValue);
      clearInterval(time);
      renderChart({
        value,
        arcChart,
        circle,
        pieChart,
        arcData,
        error,
        success,
        userPositionError,
      });
    }

    if (init > 100 && progress > 100) {
      clearInterval(time);
    }

    return () => {
      clearInterval(time);
    };
  }, [
    progress,
    children,
    defaultError,
    defaultSuccess,
    defaultText,
    success,
    text,
    textError,
    init,
    error,
  ]);

  return (
    <div className="svg-container" ref={selectedRef}>
      <svg ref={selectedSvg} width="100%" height="300" viewBox="0 0 300 300">
        <g ref={selectedG}>
          <g className="circle" ref={selectedCircle}>
            <foreignObject ref={selectedForeignObject}>
              <div
                className={
                  isLandscape
                    ? 'progress-container progress-container--landscape'
                    : 'progress-container'
                }
              >
                {children}
                {defaultText && (defaultError || defaultSuccess) ? (
                  <div className="progress-content">
                    {defaultError ? (
                      <p className="progress-text error">{defaultText}</p>
                    ) : (
                      <p className="progress-text">{defaultText}</p>
                    )}
                  </div>
                ) : text || (error && textError) ? (
                  <div className="progress-content">
                    {error ? (
                      <p className="progress-text error">{textError}</p>
                    ) : (
                      <p className="progress-text">{text}</p>
                    )}
                  </div>
                ) : textToRead ? (
                  <div className="progress-content">
                    <p
                      className="progress-text"
                      style={{ display: 'contents' }}
                    >
                      <TextToRead text={textToRead} />
                    </p>
                  </div>
                ) : null}
                <img
                  src="assets/face-cutout.svg"
                  className="cutout-image"
                  alt="cutout-face"
                />
              </div>
            </foreignObject>
          </g>
        </g>
      </svg>
    </div>
  );
};
