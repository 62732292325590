import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import * as tf from '@tensorflow/tfjs';
import {
  setWasmPaths,
  version_wasm as VersionWasm,
  setThreadsCount,
} from '@tensorflow/tfjs-backend-wasm';
import { setBackend } from '@tensorflow/tfjs-core';

import { setIsFraud } from 'app/slices/appSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigateWithQuery } from 'hooks/useNavigateWithQuery';

interface AppContextValue {
  localStorage: Storage | null;
  isBackendWasm: boolean;
  invitationId: string;
}

export const AppContext = createContext<AppContextValue>({
  localStorage: null,
  isBackendWasm: false,
  invitationId: '',
});

const AppContextProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [localStorage, setLocalStorage] = useState<Storage | null>(null);
  const [isBackendWasm, setIsBackendWasm] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const addQueryAndNavigate = useNavigateWithQuery();

  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('client_id') || '';
  const invitationId = searchParams.get('invitationId') || '';

  const optimizedBackend = useCallback(async () => {
    try {
      setWasmPaths(
        `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${VersionWasm}/dist/`,
      );
      const logicalCpuCoresCount = navigator.hardwareConcurrency;

      // NOTE known issue: https://github.com/tensorflow/tfjs/issues/6081
      setThreadsCount(logicalCpuCoresCount);

      let backendUpdated = false;
      const currentBackend = tf.getBackend();

      if (currentBackend === 'wasm') {
        backendUpdated = true;
      } else {
        backendUpdated = await setBackend('wasm');
      }
      setIsBackendWasm(backendUpdated);
    } catch (err) {
      console.error('wasm backend err: ', err);
    }
  }, []);

  // Fraud handlers
  useEffect(() => {
    if (!clientId) {
      dispatch(setIsFraud(true));
    }
  }, [clientId]);

  const isFraud = useAppSelector((state) => state.app.isFraud);
  useEffect(() => {
    if (isFraud) {
      addQueryAndNavigate('/service-unavailable', isFraud);
    }
  }, [addQueryAndNavigate, isFraud]);

  // Local storage handler
  useEffect(() => {
    optimizedBackend();
    try {
      window.localStorage.getItem('test-localStorage');
      setLocalStorage(window.localStorage);
    } catch {
      const factoryStorage = (): Storage => {
        const memory = new Map();

        return {
          getItem: (key: string) => memory.get(key),
          setItem: (key: string, value: string) => memory.set(key, value),
          removeItem: (key: string) => memory.delete(key),
          clear: () => memory.clear(),
          length: 0,
          key: () => null,
        };
      };
      setLocalStorage(factoryStorage());
    }
  }, []);

  const contextValue: AppContextValue = useMemo(
    () => ({ localStorage, isBackendWasm, invitationId }),
    [localStorage, isBackendWasm],
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useLocalStorage = (): Storage | null => {
  const { localStorage } = useContext(AppContext);
  return localStorage;
};

export const useIsBackendWasm = (): boolean => {
  const { isBackendWasm } = useContext(AppContext);
  return isBackendWasm;
};

export const useInvitationId = (): string => {
  const { invitationId } = useContext(AppContext);
  return invitationId;
};

export default AppContextProvider;
