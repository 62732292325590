import React, {
  createContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  ReactNode,
} from 'react';

interface MediaContextType {
  state: {
    stream: MediaStream | null;
    webcamIsOn: boolean;
    micIsOn: boolean;
    videoTrack: MediaStreamTrack | null;
    audioTrack: MediaStreamTrack | null;
  };
  deactivateMedia: (mediaType?: string) => Promise<void>;
  activateMedia: (mediaType?: string) => Promise<void>;
}

interface Props {
  children: ReactNode;
}

interface DefaultMediaTracksOptions {
  mic: boolean;
  webcam: boolean;
}

interface GetDevicesOptions {
  micEnabled: boolean;
  webcamEnabled: boolean;
}

export const MediaContext = createContext<MediaContextType>(
  {} as MediaContextType,
);

const MediaContextProvider: React.FC<Props> = ({ children }) => {
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack | null>(null);
  const [audioTrack, setAudioTrack] = useState<MediaStreamTrack | null>(null);

  const [stream, setStream] = useState<MediaStream | null>(null);

  const videoTrackRef = useRef<MediaStreamTrack | null>(null);
  const audioTrackRef = useRef<MediaStreamTrack | null>(null);

  const videoPlayerRef = useRef<HTMLVideoElement | null>(null);
  const popupVideoPlayerRef = useRef<HTMLVideoElement | null>(null);

  const webcamIsOn = useMemo(() => !!videoTrack, [videoTrack]);
  const micIsOn = useMemo(() => !!audioTrack, [audioTrack]);

  const _handleTurnOffWebcam = () => {
    const videoTrack = videoTrackRef.current;
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(null);
    }
  };
  const _handleTurnOnWebcam = async () => {
    const videoTrack = videoTrackRef.current;
    if (!videoTrack) {
      await getDefaultMediaTracks({ mic: false, webcam: true });
    }
  };
  const _handleTurnOffMic = () => {
    const audioTrack = audioTrackRef.current;
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(null);
    }
  };
  const _handleTurnOnMic = async () => {
    const audio = audioTrackRef.current;
    if (!audio) {
      await getDefaultMediaTracks({ mic: true, webcam: false });
    }
  };

  const getDefaultMediaTracks = async ({
    mic,
    webcam,
  }: DefaultMediaTracksOptions): Promise<void> => {
    if (mic && webcam) {
      const constraints = {
        audio: true,
        video: true,
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setStream(stream);
      const audioTracks = stream.getAudioTracks();
      const audioTrack = audioTracks.length ? audioTracks[0] : null;
      setAudioTrack(audioTrack);
      const videoTracks = stream.getVideoTracks();
      const videoTrack = videoTracks.length ? videoTracks[0] : null;
      setVideoTrack(videoTrack);
    } else {
      if (mic) {
        const audioConstraints = {
          audio: true,
        };
        const stream = await navigator.mediaDevices.getUserMedia(
          audioConstraints,
        );
        setStream(stream);
        const audioTracks = stream.getAudioTracks();
        const audioTrack = audioTracks.length ? audioTracks[0] : null;
        setAudioTrack(audioTrack);
      }
      if (webcam) {
        const videoConstraints = {
          video: true,
        };
        const stream = await navigator.mediaDevices.getUserMedia(
          videoConstraints,
        );
        setStream(stream);
        const videoTracks = stream.getVideoTracks();
        const videoTrack = videoTracks.length ? videoTracks[0] : null;
        setVideoTrack(videoTrack);
      }
    }
  };

  const getDevices = async ({
    micEnabled,
    webcamEnabled,
  }: GetDevicesOptions): Promise<void> => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const webcams = devices.filter((d) => d.kind === 'videoinput');
      const mics = devices.filter((d) => d.kind === 'audioinput');

      const hasMic = mics.length > 0;
      const hasWebcam = webcams.length > 0;

      getDefaultMediaTracks({
        mic: hasMic && micEnabled,
        webcam: hasWebcam && webcamEnabled,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    audioTrackRef.current = audioTrack;
  }, [audioTrack]);

  useEffect(() => {
    videoTrackRef.current = videoTrack;

    if (videoTrack) {
      const videoSrcObject = new MediaStream([videoTrack]);

      if (videoPlayerRef.current) {
        videoPlayerRef.current.srcObject = videoSrcObject;
        videoPlayerRef.current.play();
      }

      setTimeout(() => {
        if (popupVideoPlayerRef.current) {
          popupVideoPlayerRef.current.srcObject = videoSrcObject;
          popupVideoPlayerRef.current.play();
        }
      }, 1000);
    } else {
      if (videoPlayerRef.current) {
        videoPlayerRef.current.srcObject = null;
      }
      if (popupVideoPlayerRef.current) {
        popupVideoPlayerRef.current.srcObject = null;
      }
    }
  }, [videoTrack]);

  useEffect(() => {
    getDevices({ micEnabled: micIsOn, webcamEnabled: webcamIsOn });
  }, []);

  const deactivateMedia = async (mediaType?: string): Promise<void> => {
    if (mediaType === 'video') {
      _handleTurnOffWebcam();
    } else if (mediaType === 'audio') {
      _handleTurnOffMic();
    } else if (!mediaType) {
      _handleTurnOffWebcam();
      _handleTurnOffMic();
    }
  };

  const activateMedia = async (mediaType?: string): Promise<void> => {
    if (!mediaType) {
      await getDefaultMediaTracks({
        mic: true,
        webcam: true,
      });
    } else if (mediaType === 'audio') {
      await _handleTurnOnMic();
    } else if (mediaType === 'video') {
      await _handleTurnOnWebcam();
    }
  };

  const contextValue = {
    state: {
      stream,
      webcamIsOn,
      micIsOn,
      videoTrack,
      audioTrack,
    },
    deactivateMedia,
    activateMedia,
  };

  return (
    <MediaContext.Provider value={contextValue}>
      {children}
    </MediaContext.Provider>
  );
};

export default MediaContextProvider;
