import { useCallback } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

export const useNavigateWithQuery = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const addQueryAndNavigate = useCallback(
    (to: To, isAllowed?: boolean, ...rest: any) => {
      isAllowed !== undefined
        ? navigate(to + search, { state: { isAllowed, ...rest } })
        : navigate(to + search);
    },
    [navigate, search],
  );

  return addQueryAndNavigate;
};
