import React, {
  useEffect,
  useCallback,
  useContext,
  useState,
  useRef,
} from 'react';

import { Box } from '@mui/material';

import { setRoute } from 'app/slices/appSlice';

import { MediaContext } from 'context/MediaContext';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useMediaDevices } from 'hooks/useMediaDevices';
import { useSpeech } from 'hooks/useSpeechToText';

import '../style.css';

export const VerificationContentSubDrawer: React.FC = () => {
  const dispatch = useAppDispatch();
  const context = useContext(MediaContext);
  const { state } = context ?? {};
  const { stream, audioTrack } = state ?? {};
  const { toggleOnMic, toggleOffMic } = useMediaDevices();

  const { speechToText, handleContinuousListeningChange } = useSpeech(() =>
    handleStartCapture(),
  );

  const winkEnabled = useAppSelector((state) => state.app.winkEnabled);
  const [userEnableMicCount, setUserEnableMicCount] = useState(0);
  const userEnableMicCountRef = useRef(userEnableMicCount);
  const counterRef = useRef(0);
  const microphoneEnabled = useAppSelector((state) => state.app.micEnabled);
  const isFraud = useAppSelector((state) => state.app.isFraud);
  const isiOS = useAppSelector((state) => state.app.isiOS);
  const processingPermissions = useAppSelector(
    (state) => state.app.processingPermissions,
  );

  const handleStartCapture = useCallback(async () => {
    dispatch(setRoute('/enroll'));
  }, [dispatch]);

  const handleSpeechToText = useCallback(
    (status: boolean) => {
      speechToText(status);
    },
    [speechToText],
  );

  useEffect(() => {
    if (microphoneEnabled) {
      setUserEnableMicCount((prevCount) => prevCount + 1);
    } else {
      handleSpeechToText(false);
    }
  }, [microphoneEnabled]);

  useEffect(() => {
    userEnableMicCountRef.current = userEnableMicCount;
  }, [userEnableMicCount]);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval> | null = null;
    if (isiOS === false && processingPermissions === false && !timer) {
      handleContinuousListeningChange(true);
      timer = setInterval(function () {
        if (counterRef.current >= 1) {
          clearInterval(timer as unknown as number);
        } else if (
          counterRef.current === 0 &&
          // two times due to useEffect dependencies. 3 times means user has interacted with mic button
          userEnableMicCountRef.current <= 2
        ) {
          toggleOffMic();
          handleSpeechToText(false);
        }
        counterRef.current++;
      }, 7000);
      toggleOnMic();
    } else {
      toggleOffMic();
    }
    return () => {
      handleSpeechToText(false);
      timer && clearInterval(timer);
    };
  }, [isiOS, processingPermissions]);

  useEffect(() => {
    if (!winkEnabled || !stream || isiOS) return;
    if (audioTrack && microphoneEnabled && !isFraud) {
      handleSpeechToText(true);
    }
  }, [winkEnabled, stream, isFraud, isiOS, microphoneEnabled, audioTrack]);

  return (
    <Box
      style={{ maxHeight: 'auto', overflowY: 'scroll' }}
      className="no-style-scrollbar"
      py={2}
    />
  );
};
