import { useState, useEffect, useRef } from 'react';

export const useWStatus = () => {
  const [connectionStatus, setConnectionStatus] = useState('');
  const timerIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleWsMessage = () => {
    // reset the timeout every time a sync message is received
    timerIdRef?.current && clearTimeout(timerIdRef.current);
    timerIdRef.current = setTimeout(() => {
      console.error(
        'No server ws message received in the last 20 seconds, closing WS',
      );
      setConnectionStatus('error');
    }, 20000);
  };

  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    connectionStatus === 'connected' && handleWsMessage();
  }, [connectionStatus]);

  return {
    connectionStatus,
    setConnectionStatus,
    handleWsMessage,
  };
};
