import {
  defineComponents,
  DocumentReaderDetailType,
  DocumentReaderService,
  DocumentReaderWebComponent,
} from '@regulaforensics/vp-frontend-document-components';
import React, { useState, useCallback, useEffect } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  FormHelperText,
  TextField,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { GlobalStyles } from '@mui/system';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { isToday, parse } from 'date-fns';

import { setIsLoading as winkLoading } from 'app/slices/appSlice';
import {
  setIsIdVerified,
  setIdCaptured,
  setIdHasMorePages,
} from 'app/slices/userSlice';

import { useLocalStorage } from 'context/AppContext';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import {
  isFakeEmail,
  checkContactExists,
  checkEmailExists,
  getDocumentData,
} from 'api/user';

import { StyledButton } from 'components/button';
import { Loader } from 'components/loader/Loader';

import { isMobile } from 'const/breakpoints';

import { stylesBtn } from '../../Theme';
import {
  nameRegex,
  emailRegex,
  getAllIndexes,
  isDateOlderThanToday,
  isOlderThan100Years,
} from '../utils';
import { CameraSnapshotStyled } from './styles';

const StyledPhoneInput = styled(PhoneInput)`
  & input[type='tel'] {
    border: 1px solid ${({ theme }) => theme.palette.grey[400]};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    padding: ${({ theme }) => theme.spacing(1)};
    font-size: 1.05rem;
    color: #424347;
    width: 100%;
    background: transparent;

    &::placeholder {
      color: ${({ theme }) => theme.palette.text.primary[900]};
      font-size: ${({ theme }) => theme.typography.body1.fontSize};
      opacity: 1;
    }
  }

  & input[type='tel']:hover {
    border-color: ${({ theme }) => theme.palette.primary.light};
    outline: none;
  }

  & input[type='tel']:focus {
    border-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.primary.main};
    outline: none;
  }

  & .PhoneInputCountry {
    background: transparent;
  }

  &.PhoneInput {
    flex-grow: 1;
    gap: 5px;
    margin-left: 4px;
  }

  .phoneInputError .PhoneInputInput': {
    borderColor: '#f44336', // Color de error de Material UI por defecto
    color: '#f44336',
  },
`;

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 9,
});

const Row = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
});

const Span = styled('span')({
  position: 'absolute',
  right: 35,
  marginTop: 8,
});

function applyStylesToShadowDomElement() {
  const cameraSnapshot = document.querySelector('camera-snapshot');
  if (cameraSnapshot && cameraSnapshot.shadowRoot) {
    const shadowDiv = cameraSnapshot.shadowRoot.querySelector('div');
    if (shadowDiv) {
      shadowDiv.style.width = '100%';
      shadowDiv.style.height = 'auto';
      shadowDiv.style.padding = '40px';
      return true;
    }
  }
  return false;
}

interface IdCapturedInfo {
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
}

interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  contactNo: string;
  dateOfBirth: Date | null;
  email_status: string | null;
}

interface RegulaApiResponse {
  [key: string]: any;
}

const findBase64Images = (
  obj: RegulaApiResponse,
  images: string[] = [],
): string[] => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'object' && value !== null) {
      findBase64Images(value, images);
    } else if (typeof value === 'string') {
      if (value.startsWith('/9j/') && !images.includes(value)) {
        images.push(value);
      }
    }
  });
  return images;
};

type CountryCode = React.ComponentProps<typeof PhoneInput>['defaultCountry'];

const fetchDocumentData = async (
  requestId: string,
  localStorage: Storage,
  dispatch: any,
  images: any,
  sessionToken: string,
): Promise<IdCapturedInfo | undefined> => {
  try {
    dispatch(winkLoading(true));
    const response = await getDocumentData(
      requestId,
      localStorage,
      dispatch,
      images,
      sessionToken,
    );

    if (response.status === 400) {
      dispatch(setIdCaptured(false));
    }
    if (response.firstName) {
      dispatch(setIdCaptured(true));
    }
    return response;
  } catch (error: any) {
    console.error('An error occurred capturing ID', error);
    return undefined;
  } finally {
    dispatch(winkLoading(false));
  }
};

const updateUserInformation = (
  response: any,
  setUserInfoForm: React.Dispatch<React.SetStateAction<UserInfo>>,
  setFirstNameIdCaptured: (captured: boolean) => void,
  setLastNameIdCaptured: (captured: boolean) => void,
  setDobIdCaptured: (captured: boolean) => void,
  setCountryCode: React.Dispatch<React.SetStateAction<CountryCode>>,
): void => {
  if (response?.firstName) {
    setUserInfoForm((prev) => ({
      ...prev,
      firstName: response.firstName,
    }));
    setFirstNameIdCaptured(true);
  }
  if (response?.lastName) {
    setUserInfoForm((prev) => ({
      ...prev,
      lastName: response.lastName,
    }));
    setLastNameIdCaptured(true);
  }
  if (response?.dateOfBirth) {
    const parsedDate = parse(response.dateOfBirth, 'yyyy-MM-dd', new Date());
    setUserInfoForm((prev) => ({
      ...prev,
      dateOfBirth: parsedDate,
    }));
    setDobIdCaptured(true);
  }
  if (response?.countryCode) {
    setCountryCode(response.countryCode);
  }
};

interface Props {
  setErrors: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  errors: Record<string, boolean>;
  userInfo: UserInfo;
  setUserInfoForm: React.Dispatch<React.SetStateAction<UserInfo>>;
  disableButton: boolean;
  handleSave: (e: React.MouseEvent) => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting: boolean;
  checkAllowed: boolean;
  setCheckAllowed: React.Dispatch<React.SetStateAction<boolean>>;
  percentageProgress?: number;
  clientRequiresVerifyId: boolean;
}

export const ProfileForm: React.FC<Props> = ({
  setErrors,
  handleChange,
  errors,
  userInfo,
  setUserInfoForm,
  disableButton,
  handleSave,
  isLoading,
  setIsLoading,
  isSubmitting,
  checkAllowed,
  setCheckAllowed,
  percentageProgress,
  clientRequiresVerifyId,
}) => {
  const [emailFakeValidated, setEmailFakeValidated] = useState({
    status: false,
    text: '',
  });
  const [emailExists, setEmailExists] = useState({
    exists: undefined,
    email: '',
  });
  const [validatedEmail, setValidatedEmail] = useState<string | null>(null);
  const [validatedPhone, setValidatedPhone] = useState({
    exists: undefined,
    contactNo: '',
  });
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [showEmailFlag, setShowEmailFlag] = useState(false);
  const [showPhoneFlag, setShowPhoneFlag] = useState(false);
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const sessionToken = useAppSelector((state) => state.app.sessionToken);

  // Id Capture - Regula
  const [idCaptureOpen, setIdCaptureOpen] = useState(false);
  const [dobIdCaptured, setDobIdCaptured] = useState(false);
  const [firstNameIdCaptured, setFirstNameIdCaptured] = useState(false);
  const [lastNameIdCaptured, setLastNameIdCaptured] = useState(false);
  const [countryCode, setCountryCode] = useState<CountryCode>('US');
  const [isContainerReady, setIsContainerReady] = useState(false);

  const { isIdCaptured } = useAppSelector((state) => state.user.userInfo);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const elementRef = React.useRef<DocumentReaderWebComponent>(null);

  const dispatch = useAppDispatch();

  // LocalStorage
  const localStorage = useLocalStorage();

  const classes = stylesBtn(merchantConfiguration);
  const requestId = useAppSelector((state) => state.app.requestId);

  const listener = (data: CustomEvent<DocumentReaderDetailType>) => {
    (async () => {
      try {
        if (data.detail.action === 'PROCESS_FINISHED') {
          const status = data.detail.data?.status;
          const isFinishStatus = status === 1 || status === 2;

          if (!isFinishStatus || !data.detail.data?.response) return;

          const { response } = data.detail.data;

          console.debug({ IDresponse: response });

          const hasImages = response.images
            ? Object.keys(response.images).length > 0
            : false;
          const morePagesAvailable =
            response.lowLvlResponse?.morePagesAvailable ?? 0;

          if (hasImages && morePagesAvailable > 0) {
            dispatch(setIdHasMorePages(true));
            setIdCaptureOpen(false);
          } else {
            dispatch(setIdHasMorePages(false));
            const base64Images = findBase64Images(response);

            if (requestId && localStorage && base64Images) {
              const response = await fetchDocumentData(
                requestId,
                localStorage,
                dispatch,
                base64Images,
                sessionToken,
              );
              if (response) {
                updateUserInformation(
                  response,
                  setUserInfoForm,
                  setFirstNameIdCaptured,
                  setLastNameIdCaptured,
                  setDobIdCaptured,
                  setCountryCode,
                );
                response?.firstName && dispatch(setIsIdVerified(true));
                setIdCaptureOpen(false);
              }
            }
          }
        }
        if (data.detail?.action === 'CLOSE') {
          setIdCaptureOpen(false);
        }
      } catch (error) {
        console.error(
          'An error occurred during the document reading process',
          error,
        );
      }
    })();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (containerRef.current !== null) {
        setIsContainerReady(true);
        clearInterval(intervalId);
      }
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isContainerReady) return;

    const containerCurrent = containerRef.current;
    window.RegulaDocumentSDK = new DocumentReaderService();
    defineComponents().then(() =>
      window.RegulaDocumentSDK.initialize({
        license:
          'AAEAAJH8naiXXmYR4f5VAvogVJy1bc5mPJJqG+QsoC/NH67wIp07K2WjX21KJIWrbc6//uEeiO89FYUX7Qsp6NJIXDXxFpoKauN4ctUHCI4Ot3fuFCOQPvNpssjaYOLwYw9FGS8JJ597gHj+Sv3BdLcZdMyNcTPquVsUoo2Gn/c9GCRUsgONSH7zOXUmxXWAftyQUOW1+PTfzSKugHjOuDYJpXBscX7ZJurq5k5+QGNMT4Il/1936/BmfjCz1hGDudcwT+M/gVD7e8fWFDhxW++AioY2rdc4qAycYswIAYpy+uoqGskIjI4DHbPRMjG7Q3R4BUNeOgb+9BVUd5dcyjlLbaTkAAAAAAAAEEceYJyCVL9Hi1asjqbb4cfRQqVj+QYmgmSaBboFrIz+o20hoDcX/gXY1BOAw6AhA93tULiSm50VHhFcRqO+IkML2H8ppfgd89GxG7/xDxKaOe2dU0uWc0BhzINAgEDHV9fk+GOJFwVecWl8Zy+8c4zEJxJgiGyjUACzFBIzMnvkZl6jFcxMqZzDDZ8R4lN7L0Hd+t624wCqWrfolGm6fkuWpq3aScHB2LTehogL866W8CFjaNqXcrJFcJ/xyqJRfHJwPo2KO0TdBlh9pvPESHMVd2iGXwoLMWk5XC/pqy9Q',
      }),
    );
    window.RegulaDocumentSDK.recognizerProcessParam = {
      processParam: {
        returnUncroppedImage: true,
        scenario: 'Locate',
        multipageProcessing: true,
        returnPackageForReprocess: true,
        resultTypeOutput: [1],
        timeout: 20000,
        imageQa: {
          expectedPass: ['dpiThreshold', 'glaresCheck', 'focusCheck'],
          dpiThreshold: 130,
          glaresCheck: true,
          glaresCheckParams: {
            imgMarginPart: 0.05,
            maxGlaringPart: 0.01,
          },
        },
      },
    };

    if (!containerCurrent) return;
    containerCurrent.addEventListener('document-reader', listener);

    return () => {
      window.RegulaDocumentSDK.shutdown();
      containerCurrent.removeEventListener('document-reader', listener);
    };
  }, [isContainerReady]);

  useEffect(() => {
    const elementRefCurrent = elementRef.current;
    if (!elementRefCurrent) return;
    elementRefCurrent.settings = {
      startScreen: true,
      regulaLogo: false,
      changeCameraButton: true,
      multipageProcessing: true,
    };

    if (idCaptureOpen && !isMobile) {
      // Set regula component width to be adjusted inside container
      const intervalId = setInterval(() => {
        if (applyStylesToShadowDomElement()) {
          clearInterval(intervalId);
        }
      }, 100);
    }
  }, [idCaptureOpen, isMobile]);

  const validate = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    condition: boolean,
    field: string,
  ) => {
    handleChange(event);
    if (condition) {
      return setError(field, true);
    } else {
      return setError(field, false);
    }
  };

  const validateAndChangePhoneNumber = (
    condition: boolean | (() => boolean),
    value?: string,
  ) => {
    setShowPhoneFlag(false);
    let conditionResult = false;
    if (value) {
      setUserInfoForm((prev) => ({ ...prev, contactNo: value }));
      conditionResult =
        typeof condition === 'function' ? condition() : !condition;
    } else {
      setUserInfoForm((prev) => ({ ...prev, contactNo: '' }));
    }
    setError('contactNo', !conditionResult);
  };

  const setError = (field: string, boolean: boolean) => {
    setErrors((prev) => ({
      ...prev,
      [field]: boolean,
    }));
  };

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const handleBlurContactNo = useCallback(async () => {
    if (errors.contactNo || !userInfo.contactNo) return;
    validateAndChangePhoneNumber(
      await checkContactNoInDB(userInfo.contactNo),
      userInfo.contactNo,
    );
    setShowPhoneFlag(true);
  }, [errors.contactNo, validate, userInfo.contactNo]);

  const helperTextContactNo =
    errors.contactNo &&
    validatedPhone.exists &&
    validatedPhone.contactNo === userInfo.contactNo &&
    'Phone number already exists';

  const handleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setShowEmailFlag(false);
      const { value } = e.target;
      const atIndex = value.indexOf('@');
      const afterAt = atIndex !== -1 ? value.slice(atIndex + 1) : '';

      const hasSpecialCharsAfterAt =
        /[`!@#$%^&*()_+\-=[\]{};':"\\|,<>/?~]/.test(afterAt);
      const hasMultipleAts = getAllIndexes(value, '@').length > 1;
      const hasNumbersOrSpecialCharsInDomain = afterAt
        .split('.')
        .some(
          (element, index) =>
            index !== 0 &&
            (/\d/.test(element) ||
              /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(element)),
        );
      const matchesEmailRegex = String(value).toLowerCase().match(emailRegex);

      setEmailFakeValidated({
        status: false,
        text: '',
      });

      if (
        hasSpecialCharsAfterAt ||
        hasMultipleAts ||
        (value.includes('@') &&
          hasNumbersOrSpecialCharsInDomain &&
          !matchesEmailRegex)
      ) {
        return;
      }

      const isValidEmail =
        value.length &&
        value.includes('@') &&
        afterAt.includes('.') &&
        afterAt.split('.').length >= 2 &&
        matchesEmailRegex;

      if (value.length === 0) {
        validate(e, false, 'email');
      } else {
        validate(e, !isValidEmail, 'email');
      }
    },
    [validate],
  );

  const handleBlurEmail = useCallback(
    async (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;
      if (errors.email || value === '') return;

      if (value === validatedEmail) {
        if (emailExists && emailExists.email === value) {
          setEmailFakeValidated({
            status: true,
            text: '',
          });
          setShowEmailFlag(true);
          return validate(e, await checkEmailInDB(), 'email');
        }
      }

      if (requestId && localStorage) {
        setIsLoading(true);
        // @ts-ignore fix the typings
        // eslint-disable-next-line camelcase
        const { email_status } = await isFakeEmail(
          value,
          requestId,
          localStorage,
          dispatch,
        );
        setUserInfoForm((prev) => ({
          ...prev,
          // eslint-disable-next-line camelcase
          email_status,
        }));
        setValidatedEmail(value);
        setEmailFakeValidated({
          status: true,
          text: '',
        });
        validate(e, await checkEmailInDB(), 'email');
        setIsLoading(false);
      }
      setShowEmailFlag(true);
    },
    [errors.email, validate],
  );

  const emailHelperText =
    emailFakeValidated?.status === false &&
    userInfo.email &&
    emailFakeValidated?.text
      ? 'Invalid email'
      : errors.email &&
        emailExists.exists &&
        emailExists.email === userInfo.email &&
        'Email already exists';

  const checkValueInDB = async (type: 'contactNo' | 'email') => {
    if (userInfo?.[type]) {
      const currentValue = userInfo[type];
      const checkExists =
        type === 'contactNo' ? checkContactExists : checkEmailExists;
      const currentValidated =
        type === 'contactNo' ? validatedPhone : emailExists;

      // @ts-ignore correct typing
      if (currentValue === currentValidated[type]) {
        return currentValidated.exists;
      }

      setIsLoading(true);
      if (requestId && localStorage) {
        const response = await checkExists(
          currentValue,
          requestId,
          localStorage,
          dispatch,
          sessionToken,
        );
        setIsLoading(false);

        const newValidated = {
          exists: response?.data?.exists,
          [type]: currentValue,
        };

        if (type === 'contactNo') {
          // @ts-ignore correct typing
          setValidatedPhone(newValidated);
        } else {
          // @ts-ignore correct typing
          setEmailExists(newValidated);
        }
        return response?.data?.exists;
      }
    }
  };

  const checkContactNoInDB = async (value: string) =>
    await checkValueInDB('contactNo');
  const checkEmailInDB = async () => await checkValueInDB('email');

  const isValidYearFormat = (date: Date): boolean => {
    const yearString = date.getFullYear().toString();
    return yearString.length === 4;
  };

  const hasDateFieldError = (): boolean => {
    if (!userInfo.dateOfBirth) {
      return false;
    }

    if (!isValidYearFormat(userInfo.dateOfBirth)) {
      return true;
    }

    const dateString = userInfo.dateOfBirth.toDateString();
    if (isNaN(Date.parse(dateString))) {
      return true;
    }

    return (
      isToday(userInfo.dateOfBirth) ||
      isDateOlderThanToday(userInfo.dateOfBirth) ||
      isOlderThan100Years(dateString)
    );
  };

  return (
    <Container
      sx={{
        position: !isMobile ? 'relative' : 'initial',
        fontFamily: merchantConfiguration?.font,
      }}
    >
      {clientRequiresVerifyId && (
        <>
          {!idCaptureOpen && !firstNameIdCaptured && (
            <Box
              display="flex"
              flexDirection={isIdCaptured === false ? 'row' : 'column'}
              alignItems="center"
              gap={2}
              mb={2}
            >
              <StyledButton
                onClick={() => {
                  setIdCaptureOpen(true);
                  dispatch(setIdCaptured(undefined));
                }}
              >
                {isIdCaptured === false ? 'Retry' : 'Verify Identity'}
              </StyledButton>
            </Box>
          )}

          <CameraSnapshotStyled
            idCaptureOpen={idCaptureOpen}
            isMobile={isMobile}
            ref={containerRef}
          >
            {idCaptureOpen && (
              <document-reader
                locale="en"
                ref={elementRef}
                style={{ maxHeight: !isMobile ? '500px' : 'auto' }}
              />
            )}
          </CameraSnapshotStyled>
        </>
      )}
      {userInfo?.contactNo !== undefined && (
        <Row>
          <GlobalStyles
            styles={{
              '.phoneInputError .PhoneInputInput': {
                borderColor: '#d3302f !important',
              },
              '.phoneInputError .PhoneInputInput:focus': {
                boxShadow: '0 0 0 1px #d3302f !important',
              },
            }}
          />
          <StyledPhoneInput
            flags={flags}
            //  countrySelectProps={{ unicodeFlags: true }}
            // international
            defaultCountry={countryCode}
            autoComplete="off"
            withCountryCallingCode
            placeholder="Phone number *"
            value={userInfo?.contactNo}
            onChange={(e) =>
              validateAndChangePhoneNumber(() => isValidPhoneNumber(e ?? ''), e)
            }
            className={errors.contactNo ? 'phoneInputError' : ''}
            onBlur={handleBlurContactNo}
            disabled={isSubmitting || isLoading}
            required
          />
          {showPhoneFlag && (
            <Span>
              {validatedPhone.exists !== undefined &&
                (validatedPhone.exists ? (
                  <img src="assets/unverified-field.png"></img>
                ) : (
                  <img src="assets/verified-field.png"></img>
                ))}
            </Span>
          )}
          {helperTextContactNo && (
            <FormHelperText
              error
              sx={{
                display: 'flex',
                marginTop: '4px',
                marginRight: '14px',
                marginBottom: '0',
                marginLeft: '14px',
                alignSelf: 'center',
              }}
            >
              {helperTextContactNo}
            </FormHelperText>
          )}
        </Row>
      )}
      {userInfo?.dateOfBirth !== undefined && (
        <DesktopDatePicker
          disabled={isSubmitting || dobIdCaptured}
          label="DOB"
          inputFormat="MM/dd/yyyy"
          value={userInfo.dateOfBirth}
          disableFuture
          open={openDatePicker}
          onOpen={() => {
            setOpenDatePicker(true);
          }}
          onClose={() => {
            setOpenDatePicker(false);
          }}
          onChange={(value) => {
            setUserInfoForm((prev) => ({
              ...prev,
              dateOfBirth: value,
            }));
          }}
          renderInput={(params) => (
            <TextField
              disabled={isSubmitting || dobIdCaptured}
              autoComplete="off"
              fullWidth
              size="small"
              required
              {...params}
              error={hasDateFieldError()}
              name="dateOfBirth"
            />
          )}
        />
      )}
      {userInfo?.email !== undefined && (
        <TextField
          disabled={isSubmitting || isLoading}
          size="small"
          label="Email"
          onClick={handleClick}
          autoComplete="off"
          onChange={handleChangeEmail}
          onBlur={handleBlurEmail}
          error={errors.email}
          value={userInfo.email || ''}
          fullWidth
          type="email"
          required
          name="email"
          helperText={emailHelperText}
          FormHelperTextProps={{ style: { alignSelf: 'center' } }}
          InputProps={{
            endAdornment: showEmailFlag && (
              <InputAdornment position="end">
                {emailExists.exists !== undefined &&
                  (emailExists.exists ? (
                    <img src="assets/unverified-field.png"></img>
                  ) : (
                    <img src="assets/verified-field.png"></img>
                  ))}
              </InputAdornment>
            ),
            style: { paddingRight: 10 },
          }}
        />
      )}
      {userInfo?.firstName !== undefined && (
        <TextField
          disabled={isSubmitting || firstNameIdCaptured}
          size="small"
          name="firstName"
          label="First Name"
          onClick={(e) => e.stopPropagation()}
          autoComplete="off"
          onChange={(e) => {
            if (e.target.value !== '' && !nameRegex.test(e.target.value)) {
              return;
            }
            handleChange(e);
          }}
          value={userInfo.firstName || ''}
          fullWidth
          required
          id="firstName"
          type="text"
        />
      )}
      {userInfo?.lastName !== undefined && (
        <TextField
          disabled={isSubmitting || lastNameIdCaptured}
          size="small"
          label="Last Name"
          name="lastName"
          onClick={(e) => e.stopPropagation()}
          autoComplete="off"
          error={errors.lastName}
          onChange={(e) => {
            if (e.target.value !== '' && !nameRegex.test(e.target.value)) {
              return;
            }
            handleChange(e);
          }}
          value={userInfo.lastName || ''}
          fullWidth
          required
          type="text"
        />
      )}

      <Box
        flexDirection="row"
        alignContent="start"
        justifyContent="flex-start"
        width="100%"
      >
        <FormGroup>
          <FormControlLabel
            checked={checkAllowed}
            control={
              <Checkbox
                sx={{
                  verticalAlign: 'center',
                  '&.Mui-checked': {
                    color: merchantConfiguration?.buttonColor, // '#1AA7B0',
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: 9,
                  lineHeight: '15px',
                  textAlign: 'left',
                  verticalAlign: 'center',
                }}
                fontFamily={merchantConfiguration?.font}
              >
                By Clicking Save below, Wink has your permission to securely
                store your information. Your information will not be shared with
                anyone without your permission.
              </Typography>
            }
            onChange={() => setCheckAllowed(!checkAllowed)}
          />
        </FormGroup>
      </Box>
      {!disableButton &&
        !hasDateFieldError() &&
        emailFakeValidated?.status === true &&
        validatedPhone.contactNo === userInfo.contactNo &&
        emailExists.email === userInfo.email &&
        !isSubmitting &&
        !isLoading && (
          <Button
            onClick={handleSave}
            fullWidth
            disabled={isSubmitting}
            style={classes.greenButton}
            variant="contained"
            sx={{
              backgroundColor: `${merchantConfiguration?.buttonColor} !important`,
              color: `${merchantConfiguration?.buttonTextColor}!important`,
            }}
          >
            {!!percentageProgress &&
            (percentageProgress === 60 || percentageProgress === 30)
              ? 'Save and Login'
              : 'Save'}
          </Button>
        )}
      {(isLoading || isSubmitting) && <Loader />}
    </Container>
  );
};
