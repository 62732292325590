import React from 'react';

import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#424347',
    },
    common: {
      white: 'white',
    },
    secondary: {
      main: '#6C66C9',
    },
    text: {
      primary: '#424347',
    },
  },
  spacing: 10,
});

// TODO remove, use styled from MUI
export const stylesBtn = (merchantConfiguration: any) => ({
  greenButton: {
    backgroundColor: merchantConfiguration?.buttonColor,
    color: merchantConfiguration?.buttonTextColor,
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#636e72',
      color: '#FFF',
    },
    fontFamily: merchantConfiguration?.font,
  } as React.CSSProperties,
  whiteButton: {
    backgroundColor: '#FFF',
    color: '#1AA7B0',
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#636e72',
      color: '#1AA7B0',
    },
  } as React.CSSProperties,
  disabledButton: {
    backgroundColor: '#F0eeee',
    color: '#a5a5a5',
    '&:hover': {
      backgroundColor: '#F0eeee',
      color: '#a5a5a5',
    },
  } as React.CSSProperties,
});
