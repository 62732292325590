import React from 'react';

import { useWrapperContainer } from '../../hooks/useWrapperContainer';
import { EnrollmentContainer } from '../enrollment/EnrollmentContainer';
import { HomeContainer } from '../home/HomeContainer';

export const WrapperContainer: React.FC = () => {
  const { localStorage, route } = useWrapperContainer();

  const ROUTES = {
    '/enroll': <EnrollmentContainer localStorage={localStorage} />,
    '/': <HomeContainer localStorage={localStorage} />,
  };

  if (localStorage) {
    return ROUTES[route];
  }

  return null;
};
