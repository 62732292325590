import DeviceFingerprint from 'node_js_ipqs_device_tracker';

import { setIsLoading, setRequestId } from 'app/slices/appSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';

import configData from 'config/config.json';

// IPQS token
const { IPQS_TOKEN } = configData;

// To store the requestId from API response
let apiResponse: string;

// Initialize IPQS device fingerprint
const initializeDeviceFingerprint = () =>
  DeviceFingerprint.initializeScriptAsync(IPQS_TOKEN);

// Device fingerprint result callback
const deviceFingerprintCallback = (
  dispatch: ReturnType<typeof useAppDispatch>,
) => {
  return new Promise((resolve, reject) => {
    // 20s Timeout for ipqs call
    setTimeout(() => {
      if (!apiResponse) {
        dispatch(setRequestId('WINKLOGIN'));
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(null);
      }
    }, 20000);
    // @ts-ignore TODO fix typing for this, https://www.npmjs.com/package/node_js_ipqs_device_tracker, maybe we can use Asynchronously (recommended) approach?
    DeviceFingerprint.AfterResult((result) => {
      apiResponse = result;
      if (result?.request_id) {
        const requestId = result.request_id;
        dispatch(setRequestId(requestId));
        resolve(requestId);
      } else {
        // if IPQS fails
        dispatch(setRequestId('WINKLOGIN'));
        resolve('');
        dispatch(setIsLoading(false));
      }
    });
    DeviceFingerprint.Init();
  });
};

export const useRequestId = () => {
  const dispatch = useAppDispatch();

  const getRequestId = async () => {
    try {
      await initializeDeviceFingerprint();
      return await deviceFingerprintCallback(dispatch);
    } catch (e) {
      // console.error({ ipqsError: e });
      // dispatch(setIsFraud(true));
      // if IPQS fails
      dispatch(setRequestId('WINKLOGIN'));
      dispatch(setIsLoading(false));
    }
  };

  return { getRequestId };
};
