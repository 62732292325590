import React, { useState, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import Grow from '@mui/material/Grow';

import { useAppSelector } from 'hooks/useAppSelector';

import '../../style.css';

interface Props {
  percentage: number;
  localStorage: Storage | null;
}

export const ResumePanelMerchant: React.FC<Props> = ({
  percentage,
  localStorage,
}) => {
  const [userSnapshot, setUserSnapshot] = useState<string | undefined>(
    undefined,
  );
  const voiceRegistered = useAppSelector((state) => state.user.voice);
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );

  useEffect(() => {
    if (localStorage) {
      const image = localStorage.getItem('profileImage');

      image && setUserSnapshot(image);
    }
  }, [localStorage]);

  return (
    <Box p={2} pt={0} pb={0} position="relative">
      <Box
        mb={2}
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        fontFamily={merchantConfiguration?.font}
      >
        <Box>
          <Typography
            variant="h5"
            fontWeight="bold"
            fontFamily={merchantConfiguration?.font}
          >
            Your Wink ID
          </Typography>
        </Box>
        {percentage && (
          <Typography
            sx={{ color: '#EB7777' }}
            fontWeight="bold"
            variant="body1"
            fontFamily={merchantConfiguration?.font}
          >
            {percentage}% Complete
          </Typography>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <div>
          <div style={{ textAlign: 'center' }}>
            <Typography fontFamily={merchantConfiguration?.font}>
              Face
            </Typography>
            <Box position="relative" className="thumbs">
              <Grow
                in={true}
                style={{ transformOrigin: '0 0 0', display: 'flex' }}
              >
                <Box className="checkMark">
                  <img src="assets/Checkmark.png" alt="check face" />
                </Box>
              </Grow>
              <img className="thumbs" src={userSnapshot} alt="Face" />
            </Box>
          </div>
        </div>

        <Box>
          <div style={{ textAlign: 'center' }}>
            <Typography fontFamily={merchantConfiguration?.font}>
              Profile
            </Typography>
            <Box position="relative" className="thumbs">
              <Grow
                in={true}
                style={{ transformOrigin: '0 0 0', display: 'flex' }}
              >
                <Box className="checkMark">
                  {percentage === 100 && (
                    <img src="assets/Checkmark.png" alt="check profile" />
                  )}
                </Box>
              </Grow>
              <img className="thumbs" src="assets/profile.png" alt="Profile" />
            </Box>
          </div>
        </Box>
        <Box>
          <div style={{ textAlign: 'center' }}>
            <Typography fontFamily={merchantConfiguration?.font}>
              Voice
            </Typography>
            <Box position="relative" className="thumbs">
              {voiceRegistered !== null && (
                <Grow
                  in={true}
                  style={{
                    transformOrigin: '0 0 0',
                    display: 'flex',
                  }}
                >
                  <Box className="checkMark">
                    {voiceRegistered === true ? (
                      <img src="assets/Checkmark.png" alt="checkmark" />
                    ) : (
                      voiceRegistered === false && (
                        <img src="assets/warning.png" alt="warning" />
                      )
                    )}
                  </Box>
                </Grow>
              )}
              <img className="thumbs" src="assets/voice.png" alt="Voice" />
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
};
