import { createSlice } from '@reduxjs/toolkit';

import { defaultTheme } from 'const/appDefaultTheme';

const initialState: {
  requestId: string | null;
  micEnabled: boolean;
  cameraEnabled: boolean;
  loginType: string | null;
  winkEnabled: boolean;
  recognized: boolean;
  isFraud: boolean | null;
  isLoading: boolean;
  winkToken: string | null;
  deviceFlag: string | null;
  isiOS: boolean | undefined;
  route: '/enroll' | '/';
  processingMediaData: boolean;
  processingPermissions: boolean | null;
  enrollErrorCounter: {
    attempt: number;
    status: 'resting' | 'analyzing';
  };
  livenessError: boolean;
  livenessTipsAccepted: boolean;
  shouldShowTipsImage: boolean;
  deniedPermissions: boolean;
  isFirstInstruction: boolean;
  axiosError: string;
  isClientPermissionOpen: boolean;
  initKeycloak: boolean;
  merchantConfiguration: {
    font: string;
    buttonColor: string;
    buttonTextColor: string;
    backgroundColor: string;
    color: string;
    logoFileUrl: string;
  };
  isMerchantProps: boolean;
  sessionToken: string;
  isSessionTimeOut: boolean;
} = {
  requestId: null,
  micEnabled: false,
  cameraEnabled: false,
  loginType: null,
  winkEnabled: false,
  recognized: false,
  isFraud: null,
  isLoading: false,
  winkToken: null,
  deviceFlag: null,
  isiOS: undefined,
  route: '/',
  processingMediaData: false,
  processingPermissions: null,
  enrollErrorCounter: {
    attempt: 0,
    status: 'resting',
  },
  isFirstInstruction: true,
  deniedPermissions: false,
  axiosError: '',
  isClientPermissionOpen: false,
  initKeycloak: false,
  livenessError: false,
  livenessTipsAccepted: false,
  shouldShowTipsImage: false,
  merchantConfiguration: {
    font: defaultTheme.font,
    color: defaultTheme.color,
    backgroundColor: defaultTheme.backgroundColor,
    buttonColor: defaultTheme.buttonColor,
    buttonTextColor: defaultTheme.buttonTextColor,
    logoFileUrl: '',
  },
  isMerchantProps: false,
  sessionToken: '',
  isSessionTimeOut: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMicActive: (state, action) => {
      state.micEnabled = action.payload;
    },
    setCameraActive: (state, action) => {
      state.cameraEnabled = action.payload;
    },
    setIsFraud: (state, action) => {
      state.isFraud = action.payload;
    },
    setIsiOS: (state, action) => {
      state.isiOS = action.payload;
    },
    setLoginType: (state, action) => {
      state.loginType = action.payload;
    },
    setWinkEnabled: (state, action) => {
      state.winkEnabled = action.payload;
    },
    setRecognized: (state, action) => {
      state.recognized = action.payload;
    },
    setWinkToken: (state, action) => {
      state.winkToken = action.payload;
    },
    setRoute: (state, action) => {
      state.route = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = Boolean(action.payload);
    },
    setRequestId: (state, action) => {
      state.requestId = action.payload;
    },
    setDeviceFlag: (state, action) => {
      state.deviceFlag = action.payload;
    },
    setProcessingMediaData: (state, action) => {
      state.processingMediaData = action.payload;
    },
    setProcessingPermissions: (state, action) => {
      state.processingPermissions = action.payload;
    },
    setDeniedPermissions: (state, action) => {
      state.deniedPermissions = action.payload;
    },
    setAxiosError: (state, action) => {
      state.axiosError = action.payload;
    },
    setIsClientPermissionOpen: (state, action) => {
      state.isClientPermissionOpen = action.payload;
    },
    setInitKeycloak: (state, action) => {
      state.initKeycloak = action.payload;
    },
    setLivenessError: (state, action) => {
      state.livenessError = action.payload;
    },
    setLivenessTipsAccepted: (state, action) => {
      state.livenessTipsAccepted = action.payload;
    },
    setShouldShowTipsImage: (state, action) => {
      state.shouldShowTipsImage = action.payload;
    },
    setEnrollErrorCounter: (state, action) => {
      state.enrollErrorCounter = action.payload;
    },
    setIsFirstInstruction: (state, action) => {
      state.isFirstInstruction = action.payload;
    },
    setMerchantConfiguration: (state, action) => {
      state.merchantConfiguration = action.payload;
    },
    setIsMerchantProps: (state, action) => {
      state.isMerchantProps = action.payload;
    },
    setSessionToken: (state, action) => {
      state.sessionToken = action.payload;
    },
    setIsSessionTimeOut: (state, action) => {
      state.isSessionTimeOut = action.payload;
    },
  },
});

export const {
  setMicActive,
  setCameraActive,
  setLoginType,
  setWinkEnabled,
  setRecognized,
  setIsFraud,
  setWinkToken,
  setRoute,
  setIsLoading,
  setRequestId,
  setDeviceFlag,
  setIsiOS,
  setProcessingMediaData,
  setProcessingPermissions,
  setDeniedPermissions,
  setAxiosError,
  setIsClientPermissionOpen,
  setInitKeycloak,
  setLivenessError,
  setLivenessTipsAccepted,
  setShouldShowTipsImage,
  setEnrollErrorCounter,
  setIsFirstInstruction,
  setMerchantConfiguration,
  setIsMerchantProps,
  setSessionToken,
  setIsSessionTimeOut,
} = appSlice.actions;
export default appSlice.reducer;
