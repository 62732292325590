import React, { useState } from 'react';

import { Box } from '@mui/material';

import { ButtonLink } from 'components/button';
import { Loader } from 'components/loader/Loader';

import { useSuccessSaveDataUser } from '../../hooks/useSuccessSaveDataUser';
import '../../style.css';

export const SuccessSaveDataUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleActionTo } = useSuccessSaveDataUser({ setIsLoading });

  return (
    <>
      {isLoading ? (
        <Box display="flex">
          <Loader />
        </Box>
      ) : (
        <div
          className="no-style-scrollbar"
          style={{
            textAlign: 'center',
            overflowY: 'scroll',
            width: '100%',
            boxSizing: 'border-box',
            padding: 40,
          }}
        >
          <ButtonLink
            fullWidth
            linkTo="/"
            title="Login Now"
            actionTo={handleActionTo}
          />
        </div>
      )}
    </>
  );
};
