import React from 'react';

import { Stack, Box } from '@mui/material';

import { ButtonLink } from 'components/button';

import { Title, ErrorImg, Text } from './styles';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

interface ErrorContainerProps extends FallbackProps {
  message?: string | null;
}

export const ErrorContainer: React.FC<ErrorContainerProps> = (props) => {
  const handleClick = () => {
    window.location.href = '/' + window.location.search;
  };

  return (
    <Stack
      height="100vh"
      textAlign="center"
      justifyContent="center"
      padding="50px"
      boxSizing="border-box"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="16px"
        sx={{
          maxWidth: { xs: '100%', md: '50%' },
          mx: { xs: 0, md: 'auto' },
        }}
      >
        <ErrorImg src="assets/error.png" alt="error" />
        <Title variant="h1">Uh Oh!</Title>
        <Text variant="h2">
          {props.message ??
            'An error occurred while processing this request. Try using the button below to go back home.'}
        </Text>{' '}
      </Box>
      <Box mt="75px" px="12px">
        <ButtonLink
          fullWidth
          sx={{
            maxWidth: { xs: '100%', md: '50%', lg: '500px' },
            mx: { xs: 0, md: 'auto' },
            fontSize: '16px',
          }}
          title="Return Home"
          actionTo={handleClick}
        />
      </Box>
    </Stack>
  );
};
