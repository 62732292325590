import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface MyStyledBoxProps {
  drawerOpen: boolean;
}

export const DrawerContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'drawerOpen',
})<MyStyledBoxProps>`
  display: ${({ drawerOpen }) => (drawerOpen ? 'contents' : 'none')};
`;
