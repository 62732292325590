import React from 'react';

import { isMobileOnly } from 'wink-lib';

export const useDeviceOrientation = () => {
  const [isLandscape, setIsLandscape] = React.useState(
    isMobileOnly && window.innerWidth > window.innerHeight,
  );

  React.useEffect(() => {
    const handleOrientationChange = () => {
      if (isMobileOnly && window.innerWidth > window.innerHeight) {
        setIsLandscape(true);
      } else {
        setIsLandscape(false);
      }
    };

    window.addEventListener('resize', handleOrientationChange);
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  return { isLandscape };
};
