import * as React from 'react';

import { Button, SxProps, Theme } from '@mui/material';

import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigateWithQuery } from 'hooks/useNavigateWithQuery';

import { initSynthesizer, restartSynthesizer } from 'api/speech';

import '../../style.css';
import { stylesBtn } from '../../Theme';

interface Props {
  title: string;
  actionTo?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disableButton?: boolean;
  fullWidth?: boolean;
  linkTo?: string;
  style?: React.CSSProperties;
  href?: string;
  sx?: SxProps<Theme>;
}
export const ButtonLink: React.FC<Props> = ({
  title,
  actionTo,
  disableButton,
  fullWidth,
  linkTo,
  style,
  href,
  sx,
}) => {
  const addQueryAndNavigate = useNavigateWithQuery();
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const classes = stylesBtn(merchantConfiguration);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    restartSynthesizer();
    initSynthesizer();
    actionTo && e && actionTo(e);
    linkTo && addQueryAndNavigate(linkTo);
  };

  return (
    <Button
      disabled={disableButton}
      onClick={handleOnClick}
      fullWidth={fullWidth}
      style={style || (classes.greenButton as React.CSSProperties)}
      sx={{
        ...sx,
        backgroundColor: `${merchantConfiguration?.buttonColor} !important`,
        color: `${merchantConfiguration?.buttonTextColor} !important`,
      }}
      href={href}
    >
      {title}
    </Button>
  );
};
