import axiosInstance from '../axiosInstance';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { getVerifyUserQuestionsUrl } from './VerifyUserApiService';

type DispatchType = ReturnType<typeof useAppDispatch>;

export const verifyUserQuestions = async (
  date: string,
  winkToken: string,
  clientId: string,
  questionId: string,
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) => {
  const url = getVerifyUserQuestionsUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const response = await instance.post(
    url,
    { userSecurityQuestionId: questionId, answer: date },
    {
      headers: {
        Handle401Locally: 'true',
        RequestId: requestId,
        winkToken,
        client_id: clientId,
        SessionId: sessionToken,
      },
    },
  );
  return response;
};
