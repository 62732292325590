import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { ProfileFormContainer } from 'components/profileForm/ProfileFormContainer';

interface Props {
  onSave(): void;
}

export const Profile: React.FC<Props> = ({ onSave }) => (
  <Box
    display="flex"
    flexDirection="column"
    gap="25px"
    padding="0px 25px 25px 25px"
    onClick={(e) => e.stopPropagation()}
  >
    <Box>
      <Typography variant="h5" fontWeight="bold" color="primary">
        Profile
      </Typography>
    </Box>
    <ProfileFormContainer onSave={onSave} />
  </Box>
);
