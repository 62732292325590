import React from 'react';

import { Button, SxProps, Theme } from '@mui/material';

import { useAppSelector } from 'hooks/useAppSelector';

import '../../style.css';

interface Props {
  disabled?: boolean;
  onClick: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  size?: 'small' | 'medium' | 'large';
  id?: string;
}

export const StyledButton: React.FC<Props> = ({
  disabled,
  onClick,
  sx,
  children,
  size,
  id,
}) => {
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      sx={{
        textTransform: 'none',
        fontWeight: 'bold',
        paddingLeft: 3,
        paddingRight: 3,
        backgroundColor: merchantConfiguration?.buttonColor,
        '&:hover': {
          backgroundColor: '#17949c',
        },
        ...sx,
        color: merchantConfiguration?.buttonTextColor,
        fontFamily: merchantConfiguration?.font,
      }}
      variant="contained"
      size={size}
      id={id}
    >
      {children}
    </Button>
  );
};
