import React from 'react';

import { styled } from '@mui/material/styles';

interface LoaderProps {
  width?: number;
}

const StyledLoaderImage = styled('img')<{ width?: LoaderProps }>`
  width: ${(props) => (props.width ? `${props.width}px` : `101px`)};
  margin: auto;
`;

export const Loader: React.FC<LoaderProps> = ({ width }) => {
  return (
    <StyledLoaderImage
      width={width}
      src="assets/winkLoading.gif"
      alt="loading"
    />
  );
};
