import { useCallback, useEffect, ErrorInfo } from 'react';

import init, { apm } from '@elastic/apm-rum';

import { useAppSelector } from 'hooks/useAppSelector';
import { useDeviceOrientation } from 'hooks/useDeviceOrientation';

import configData from 'config/config.json';

interface HookResult {
  isLandscape: boolean;
  isLoading: boolean;
  handleError?: (error: Error, info: ErrorInfo) => void;
}

export const useApp = (): HookResult => {
  const { isLandscape } = useDeviceOrientation();
  const isLoading = useAppSelector((state: any) => state.app.isLoading);
  const productionModeEnabled = process.env.NODE_ENV === 'production';

  useEffect(() => {
    if (productionModeEnabled) {
      const { APM_SERVICE_NAME, APM_SERVICE_URL, APM_ENV } = configData;
      init({
        serviceName: APM_SERVICE_NAME,
        serverUrl: APM_SERVICE_URL,
        environment: APM_ENV,
        active: true,
        instrument: true,
      });
    }
  }, []);

  const handleError = useCallback((error: Error, info: ErrorInfo) => {
    apm.captureError(error);
  }, []);

  return {
    isLandscape,
    isLoading,
    handleError,
  };
};
