import React, { useRef, useState, useEffect } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  Link,
  Typography,
  Box,
} from '@mui/material';

import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';

import Bowser from 'bowser';

import { useLocalStorage } from 'context/AppContext';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import { setGrantedPermissions } from 'const/localStorage';

import {
  setDeniedPermissions,
  setProcessingPermissions,
} from '../app/slices/appSlice';

const browser = Bowser.getParser(window.navigator.userAgent);

const DIALOGS_TYPE = {
  systemDenied: 'systemDenied',
  userDenied: 'userDenied',
  trackError: 'trackError',
};

export const Permissions = () => {
  const [showDialog, setShowDialog] = useState<string | null>(null);
  const [errorDetails, setErrorDetails] = useState<{
    name?: string;
    message?: string;
  }>({});
  const [greyScreen, setGreyScreen] = useState(false);
  const showDialogRef = useRef(showDialog);
  showDialogRef.current = showDialog;

  const processingMediaData = useAppSelector(
    (state) => state.app.processingMediaData,
  );

  const deniedPermissions = useAppSelector(
    (state) => state.app.deniedPermissions,
  );

  const localStorage = useLocalStorage();
  const dispatch = useAppDispatch();

  const onChangePermissions = async (): Promise<(() => void) | undefined> => {
    try {
      // If the Permissions API is supported, proceed with permission queries
      const microphonePermissions = await navigator.permissions.query({
        // eslint-disable-next-line no-undef
        name: 'microphone' as PermissionName,
      });

      const onMicrophonePermissionChange = () => {
        const denied = microphonePermissions.state === 'denied';
        // If the microphone permission is denied, update the application state accordingly
        dispatch(setDeniedPermissions(denied));
      };

      microphonePermissions.addEventListener(
        'change',
        onMicrophonePermissionChange,
      );

      const cameraPermissions = await navigator.permissions.query({
        // eslint-disable-next-line no-undef
        name: 'camera' as PermissionName,
      });

      const onCameraPermissionChange = () => {
        const denied = cameraPermissions.state === 'denied';
        // If the camera permission is denied, update the application state accordingly
        dispatch(setDeniedPermissions(denied));
      };

      cameraPermissions.addEventListener('change', onCameraPermissionChange);

      // Return a cleanup function to remove event listeners
      // Return the cleanup function
      return () => {
        microphonePermissions.removeEventListener(
          'change',
          onMicrophonePermissionChange,
        );
        cameraPermissions.removeEventListener(
          'change',
          onCameraPermissionChange,
        );
      };
    } catch (error) {
      console.error('Error occurred while querying permissions:', error);
      return undefined;
    }
  };

  const checkMediaPermissions = () => {
    if (localStorage) {
      dispatch(setProcessingPermissions(true));
      setGreyScreen(true);
      requestMediaPermissions()
        .then(() => {
          setGrantedPermissions(true, localStorage);
          dispatch(setDeniedPermissions(false));
          setShowDialog(null);
        })
        .catch((error) => {
          dispatch(setDeniedPermissions(true));
          setGrantedPermissions(false, localStorage);
          if (error.type === MediaPermissionsErrorType.SystemPermissionDenied) {
            // browser doesn't have access to devices
            setShowDialog(DIALOGS_TYPE.systemDenied);
          } else if (
            error.type === MediaPermissionsErrorType.UserPermissionDenied
          ) {
            // user denied permission
            setShowDialog(DIALOGS_TYPE.userDenied);
          } else if (
            error.type === MediaPermissionsErrorType.CouldNotStartVideoSource
          ) {
            // most likely when other apps or tabs are using the cam/mic (mostly windows)
            setShowDialog(DIALOGS_TYPE.trackError);
          }
          setErrorDetails(error);
        })
        .finally(() => {
          setGreyScreen(false);
          dispatch(setProcessingPermissions(false));
        });
    }
  };

  useEffect(() => {
    let cleanupFn: (() => void) | undefined;

    if (localStorage) {
      // eslint-disable-next-line no-unused-vars
      const initializePermissions = async () => {
        const cleanup = await onChangePermissions();
        cleanupFn = cleanup;
      };
      const checkPermissions = async () => {
        checkMediaPermissions();
        initializePermissions();
      };

      checkPermissions();
      // Call the cleanup function when the component is unmounted
    }
    return () => {
      if (cleanupFn) {
        cleanupFn();
      }
    };
  }, [localStorage, deniedPermissions]);

  useEffect(() => {
    if (deniedPermissions && !processingMediaData && localStorage) {
      // We dont want to show a popup if BE is processing data because that mean at that point we dont need the camera n mic.
      setShowDialog(DIALOGS_TYPE.userDenied);
      setGrantedPermissions(false, localStorage);
    }
  }, [processingMediaData, deniedPermissions, localStorage]);

  const _renderTryAgain = () => {
    return (
      <div style={{ width: '100%', marginTop: 20 }}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            window.location.reload(); //  rechecking permissions results in glitches so just refresh the page
          }}
          color="primary"
          style={{ float: 'right' }}
        >
          Retry
        </Button>
      </div>
    );
  };

  const _renderErrorMessage = () => {
    if (!errorDetails) return null;
    return (
      <div style={{ marginTop: 10 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRoundedIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="caption" style={{ color: 'red' }}>
              Error Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              {errorDetails.name}: {errorDetails.message}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const _renderUserDeniedDialog = () => {
    return (
      <Box p={2}>
        <Typography variant="h5">
          Camera and/or microphone are blocked
        </Typography>
        <Box>
          App requires access to your camera and microphone.{' '}
          {browser.getBrowserName() !== 'Safari' && (
            <Typography>
              Click the camera blocked icon{' '}
              <img
                alt="icon"
                src="https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg"
                style={{ display: 'inline' }}
              />{' '}
              in your browser's address bar.
            </Typography>
          )}
        </Box>
        {_renderErrorMessage()}
        {_renderTryAgain()}
      </Box>
    );
  };

  const _renderSystemDeniedDialog = () => {
    const settingsDataByOS = {
      macOS: {
        name: 'System Preferences',
        link: 'x-apple.systempreferences:com.apple.preference.security?Privacy_Camera',
      },
    };

    const isMacOs = browser.getOSName() === 'macOS';

    return (
      <div>
        <Typography variant="h5">
          Can't use your camera or microphone
        </Typography>
        <Typography>
          Your browser might not have access to your camera or microphone. To
          fix this problem, open{' '}
          {isMacOs ? (
            <Link
              onClick={() => {
                window.open(settingsDataByOS.macOS.link, '_blank');
              }}
            >
              {settingsDataByOS.macOS.name}
            </Link>
          ) : (
            'Settings'
          )}
          .
        </Typography>
        {_renderErrorMessage()}
        {_renderTryAgain()}
      </div>
    );
  };

  const _renderTrackErrorDialog = () => {
    return (
      <div>
        <Typography variant="h5">
          Can't start your camera or microphone
        </Typography>
        <Typography>
          Another application (Zoom, Webex) or browser tab (Google Meet,
          Messenger Video) might already be using your webcam. Please turn off
          other cameras before proceeding.
        </Typography>
        {_renderErrorMessage()}
        {_renderTryAgain()}
      </div>
    );
  };

  const _renderDialogContent = () => {
    switch (showDialog) {
      case DIALOGS_TYPE.systemDenied:
        return _renderSystemDeniedDialog();
      case DIALOGS_TYPE.userDenied:
        return _renderUserDeniedDialog();
      case DIALOGS_TYPE.trackError:
        return _renderTrackErrorDialog();
      default:
        return null;
    }
  };

  if (greyScreen) return <Dialog open={true}></Dialog>;
  return (
    <Dialog open={!!showDialog}>{showDialog && _renderDialogContent()}</Dialog>
  );
};
