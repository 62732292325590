export const setGrantedPermissions = (
  value: boolean,
  localStorage?: Storage,
): void => {
  localStorage?.setItem('permissionsGranted', value.toString());
};

export const getGrantedPermissions = (
  localStorage: Storage | null,
): string | null | undefined => {
  return localStorage?.getItem('permissionsGranted');
};

const params = new URLSearchParams(window.location.search);
const clientId = params.get('client_id') || '';

export const getToken = (localStorage?: Storage): string | null | undefined => {
  return localStorage?.getItem(`wssToken-${clientId}`);
};

export const getAnyClientToken = (
  localStorage?: Storage,
): string | null | undefined => {
  const key = Object.keys(localStorage || {}).find((key) =>
    key.startsWith('wssToken'),
  );
  if (key) {
    return localStorage?.getItem(key);
  } else {
    return null;
  }
};
