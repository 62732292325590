const nameRegex = /^[A-zÀ-ú-' ]+$/i;
const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z](?:[a-zA-Z-]{0,61}[a-zA-Z])?)*$/;

const differentObjects = (obj1: unknown, obj2: unknown): boolean =>
  JSON.stringify(obj1) !== JSON.stringify(obj2);

const allQuestionsFilled = (
  array: { answer: any[]; question: [] }[],
): boolean =>
  array.every((item) => {
    return item.answer?.length > 0 && item.question?.length > 0;
  });

const objectValuesNotFilled = (obj: any): boolean => {
  const values = Object.values(obj);
  return !values.every((item) => {
    return item !== null && item !== undefined && item !== '';
  });
};

const getAllIndexes = (str: string, val: string): number[] => {
  const indexes = [];
  let i;
  for (i = 0; i < str.length; i++) if (str[i] === val) indexes.push(i);
  return indexes;
};

// TODO use build-in function from wink-lib
// Function to get the browser name
const getBrowser = (): 'Chrome' | 'Firefox' | 'Edge' | 'Safari' | undefined => {
  // Get the browser name
  const browser = window.navigator.userAgent;
  if (browser.indexOf('Chrome') >= 0) {
    return 'Chrome';
  }
  if (browser.indexOf('Firefox') >= 0) {
    return 'Firefox';
  }
  if (browser.indexOf('Edge') >= 0) {
    return 'Edge';
  }
  if (browser.indexOf('Safari') >= 0) {
    return 'Safari';
  }

  return undefined;
};

const dateFormat = 'yyyy-MM-dd';

// TODO use build-in date-fns API
const isDateOlderThanToday = (date: Date): boolean => {
  return new Date() < date;
};

// TODO use build-in date-fns API
const isValidDate = (dateString: string) => {
  const date = new Date(dateString);
  return !isNaN(date.getDate()) && date.toString() !== 'Invalid Date';
};

// TODO use build-in date-fns API
const isOlderThan100Years = (dateString: string): boolean => {
  // Get the current date
  const currentDate = new Date();

  // Create a date object from the date string
  const date = new Date(dateString);

  // Subtract 100 years from the current date
  currentDate.setFullYear(currentDate.getFullYear() - 100);

  // Return true if the date is older than 100 years ago, and false otherwise
  return date < currentDate;
};

const getSupportedMimeTypes = (
  media: string,
  typeCodecMap: Record<string, string[]>,
): string[] => {
  const isSupported = MediaRecorder.isTypeSupported;
  const supported = [];

  for (const [type, codecs] of Object.entries(typeCodecMap)) {
    const mimeType = `${media}/${type}`;

    for (const codec of codecs) {
      const variations = [
        `${mimeType};codecs=${codec}`,
        `${mimeType};codecs=${codec.toUpperCase()}`,
      ];

      for (const variation of variations) {
        if (isSupported(variation)) {
          supported.push(variation);
        }
      }
    }

    if (isSupported(mimeType)) {
      supported.push(mimeType);
    }
  }

  return supported;
};

const getSupportedAudioMimeTypes = (): string[] => {
  const typeCodecMap = {
    webm: ['opus', 'vorbis'],
    ogg: ['opus', 'vorbis'],
    mp3: ['mp3'],
    'x-matroska': ['mp4a.40.2'],
    aac: ['mp4a.40.2'],
  };

  return getSupportedMimeTypes('audio', typeCodecMap);
};

const scrollToElement = (id: string, delay?: number) => {
  if (window.innerHeight < 600) {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, delay ?? 0);
  }
};
export {
  getSupportedAudioMimeTypes,
  nameRegex,
  emailRegex,
  getAllIndexes,
  differentObjects,
  allQuestionsFilled,
  objectValuesNotFilled,
  getBrowser,
  dateFormat,
  isValidDate,
  isDateOlderThanToday,
  isOlderThan100Years,
  scrollToElement,
};
