import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { format } from 'date-fns';

import { setIsSessionTimeOut } from 'app/slices/appSlice';
import { setUserInfo } from 'app/slices/userSlice';

import { useLocalStorage } from 'context/AppContext';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import {
  getClientConfiguration,
  getClientSecret,
} from 'api/client-configuration';
import { isSessionActive } from 'api/device/device';
import { initSynthesizer, restartSynthesizer } from 'api/speech';
import { putUserInfo } from 'api/user';

import '../../style.css';
import {
  dateFormat,
  differentObjects,
  isDateOlderThanToday,
  isOlderThan100Years,
  isValidDate,
  objectValuesNotFilled,
} from '../utils';
import { ProfileForm } from './ProfileForm';

interface Props {
  propsUserInfo?: any;
  onSave: () => void;
  getProfilePercentage?: (localStorage: any) => void;
  percentage?: number;
  onButtonEnabled?: () => void;
}

interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  contactNo: string;
  dateOfBirth: Date | null;
  email_status: string | null;
}

export const ProfileFormContainer: React.FC<Props> = ({
  propsUserInfo,
  getProfilePercentage,
  onButtonEnabled,
  onSave,
  percentage,
}) => {
  /** states */
  const [isLoading, setIsLoading] = useState(false);
  const [clientRequiresVerifyId, setClientRequiresVerifyId] = useState(false);
  const [checkAllowed, setCheckAllowed] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userInfo, setUserInfoForm] = useState<UserInfo>({
    firstName: '',
    lastName: '',
    email: '',
    contactNo: '',
    dateOfBirth: null,
    email_status: null,
  });
  const [errors, setErrors] = useState<Record<string, boolean>>({
    firstName: false,
    lastName: false,
    email: false,
    contactNo: false,
    dateOfBirth: false,
  });
  const [disableButton, setDisableButton] = useState(true);

  /** derivate state */
  const fullName = `${userInfo.firstName} ${userInfo.lastName}`;

  /** redux */
  const userStoredData = useAppSelector((state) => state.user.userInfo);
  const { isIdVerified } = useAppSelector((state) => state.user.userInfo);
  const requestId = useAppSelector((state) => state.app.requestId);
  const sessionToken = useAppSelector((state) => state.app.sessionToken);

  const dispatch = useAppDispatch();

  /** Params */
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('client_id') || '';

  // LocalStorage
  const localStorage = useLocalStorage();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setUserInfoForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [userInfo, setUserInfoForm],
  );

  const checkFormErrors = useCallback(() => {
    return !!(
      errors.email ||
      errors.contactNo ||
      errors.firstName ||
      errors.lastName ||
      errors.dateOfBirth ||
      !checkAllowed
    );
  }, [errors, checkAllowed]);

  const handleSave = async (e: React.MouseEvent) => {
    restartSynthesizer();
    initSynthesizer();
    setIsSubmitting(true);
    e.stopPropagation();
    if (localStorage) {
      const isSessionValid = await isSessionActive({
        localStorage,
        dispatch,
        sessionToken,
      });
      if (isSessionValid) {
        if (userInfo.dateOfBirth) {
          const formattedDate = format(userInfo.dateOfBirth, dateFormat);

          const putData = {
            ...userInfo,
            fullName,
            dateOfBirth: formattedDate,
          };

          if (requestId && localStorage) {
            const { data } = await putUserInfo(
              putData,
              requestId,
              isIdVerified,
              localStorage,
              dispatch,
              sessionToken,
            );
            data?.winkSeed &&
              localStorage.setItem(`wssToken-${clientId}`, data.winkSeed);
            data?.oAuthRequestId &&
              localStorage.setItem(`oAuthRequestId`, data.oAuthRequestId);
            getProfilePercentage && (await getProfilePercentage(localStorage));
            onSave?.();
            setIsSubmitting(false);
            dispatch(
              setUserInfo({
                ...userInfo,
                dateOfBirth: formattedDate,
                fullName,
              }),
            );
          }
        }
      } else {
        dispatch(setIsSessionTimeOut(true));
      }
    }
  };

  /** useEffects */

  useEffect(() => {
    propsUserInfo && setUserInfoForm(propsUserInfo);
  }, [propsUserInfo]);

  useEffect(() => {
    if (userInfo.dateOfBirth) {
      const dateString = userInfo.dateOfBirth.toDateString();
      setDisableButton(
        checkFormErrors() ||
          isDateOlderThanToday(userInfo.dateOfBirth) ||
          isOlderThan100Years(dateString) ||
          !isValidDate(dateString) ||
          objectValuesNotFilled(userInfo) ||
          !differentObjects(userInfo, userStoredData),
      );
    } else {
      setDisableButton(true);
    }
  }, [userInfo, errors, checkFormErrors, userStoredData]);

  useEffect(() => {
    if (!disableButton) {
      onButtonEnabled?.();
    }
  }, [disableButton]);

  useEffect(() => {
    const fetchClientConfiguration = async () => {
      try {
        if (localStorage && clientId) {
          const clientSecret = await getClientSecret({
            clientId,
            localStorage,
            dispatch,
            sessionToken,
          });
          const clientConfig = await getClientConfiguration({
            clientSecret,
            clientId,
            localStorage,
            dispatch,
            sessionToken,
          });

          setClientRequiresVerifyId(clientConfig.verifyIDEnabled === 'true');
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (localStorage && clientId) {
      fetchClientConfiguration();
    }
  }, [localStorage, clientId]);

  return (
    <ProfileForm
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setErrors={setErrors}
      handleChange={handleChange}
      errors={errors}
      userInfo={userInfo}
      setUserInfoForm={setUserInfoForm}
      disableButton={disableButton}
      handleSave={handleSave}
      percentageProgress={percentage}
      isSubmitting={isSubmitting}
      setCheckAllowed={setCheckAllowed}
      checkAllowed={checkAllowed}
      clientRequiresVerifyId={clientRequiresVerifyId}
    />
  );
};
